import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"500px"},on:{"click:outside":_vm.close},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"accent white--text"},[_c(VIcon,{attrs:{"left":"","color":"white"}},[_vm._v("mdi-key")]),_c('span',[_vm._v("Token")])],1),_c(VCardText,{staticClass:"pa-4 pt-10 text-center"},[_c(VRow,[_c(VCol,[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v("Plain Token")]),_c(VListItemTitle,{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(_vm.token)+" "),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.clipboard(_vm.token)}}},[_c(VIcon,[_vm._v("mdi-content-copy")])],1)],1)],1)],1)],1)],1),_c(VRow,[_c(VCol,[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v("Encoded Token")]),_c(VListItemTitle,{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(_vm.encodedToken)+" "),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.clipboard(_vm.encodedToken)}}},[_c(VIcon,[_vm._v("mdi-content-copy")])],1)],1)],1)],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"depressed":""},on:{"click":_vm.close}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }