<template>
    <v-dialog v-model="show" persistent max-width="1000px" content-class="custom-scollbar" @click:outside="close">
        <v-card>
            <v-card-title class="accent white--text">
                <v-icon large left color="white">mdi-car-multiple</v-icon> 
                <span class="headline">Evse</span>
            </v-card-title>

            <v-spacer></v-spacer>

            <v-divider />

            <v-card-text class="pa-4">
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{evse.id}}</v-list-item-title>
                                <v-list-item-subtitle>Id.</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{evse.status}}</v-list-item-title>
                                <v-list-item-subtitle>Status</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-btn class="v-button" text @click="clipboard(evse.evse_id)">
                                        {{evse.evse_id}}
                                        <v-icon small right>mdi-content-copy</v-icon>
                                    </v-btn>
                                </v-list-item-title>
                                <v-list-item-subtitle>Evse Id.</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-btn class="v-button" text @click="clipboard(evse.uid)">
                                        {{evse.uid}}
                                        <v-icon small right>mdi-content-copy</v-icon>
                                    </v-btn>
                                </v-list-item-title>
                                <v-list-item-subtitle>UID</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col cols="12" md="6">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ evse.floor_level }}
                                </v-list-item-title>
                                <v-list-item-subtitle>Floor Level</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6" v-if="evse.coordinates">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-btn class="v-button" @click="openMaps(evse.coordinates)">
                                        {{evse.coordinates.latitude}}, {{evse.coordinates.longitude}}
                                        <v-icon small>mdi-map-marker</v-icon>
                                    </v-btn>
                                </v-list-item-title>
                                <v-list-item-subtitle>Coordinates</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-list disabled>
                            <v-subheader>Capabilities</v-subheader>
                            <v-list-item-group
                                color="primary"
                            >
                                <v-list-item
                                v-for="(item, i) in evse.capabilities"
                                :key="i"
                                >
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-chip color="accent" small>
                                            {{ item }}
                                        </v-chip>
                                    </v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-list disabled>
                            <v-subheader>Parking Restrictions</v-subheader>
                            <v-list-item-group
                                color="primary"
                            >
                                <v-list-item
                                v-for="(item, i) in evse.parking_restrictions"
                                :key="i"
                                >
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-chip color="accent" small>
                                            {{ item }}
                                        </v-chip>
                                    </v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <json-viewer
                                        v-if="evse.directions"
                                        class="pa-2"
                                        :value="evse.directions"
                                        :copyable="{
                                        copyText: 'Copiar',
                                        copiedText: 'Copiado!',
                                        timeout: 2000,
                                        }"
                                        :expand-depth="3"
                                    >
                                    </json-viewer>
                                </v-list-item-title>
                                <v-list-item-subtitle>Directions</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <json-viewer
                                        v-if="evse.status_schedule"
                                        class="pa-2"
                                        :value="evse.status_schedule"
                                        :copyable="{
                                        copyText: 'Copiar',
                                        copiedText: 'Copiado!',
                                        timeout: 2000,
                                        }"
                                        :expand-depth="3"
                                    >
                                    </json-viewer>
                                </v-list-item-title>
                                <v-list-item-subtitle>Status Schedule</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>
                <v-divider class="mt-5"></v-divider>
                <v-row>
                    <v-col cols="12" sm="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{evse.created_at | adonisDateFullFormat }}</v-list-item-title>
                                <v-list-item-subtitle>Created</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{evse.updated_at | adonisDateFullFormat }}</v-list-item-title>
                                <v-list-item-subtitle>Updated</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{evse.last_updated | dateFullFormat }}</v-list-item-title>
                                <v-list-item-subtitle>Last Updated</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{evse.deleted_at | dateFullFormat }}</v-list-item-title>
                                <v-list-item-subtitle>Deleted</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>
                <v-row>
                    <h3 class="mt-6 mb-3">
                        <v-icon class="ml-5" color="accent">mdi-power-plug</v-icon> Connectors
                    </h3>
                    
                    <v-data-table 
                    class="rounded-table"
                    :headers="[
                        { text: '', value: 'data-table-expand'},
                        { text: 'Id.', value: 'id' },
                        { text: 'Standard', value: 'standard'},
                        { text: 'Format', value: 'format'},
                        { text: 'Power type', value: 'power_type'},
                        { text: 'Label', value: 'label' },
                        { text: 'Core Id.', value: 'backend_id' },
                        { text: 'OCPI Id.', value: 'ocpi_id' },
                        { text: 'Last Updated', value: 'last_updated' },
                        { text: 'Deleted', value: 'deleted_at' }
                    ]" 
                    :items="connectors"
                    :expanded="connectors"
                    disable-sort
                    disable-pagination 
                    hide-default-footer
                    show-expand
                    dense
                    >
                        <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
                            <v-row class="d-flex">
                                <v-btn @click="expand(!isExpanded)" icon>
                                    <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
                                    <v-icon v-else>mdi-chevron-down</v-icon>
                                </v-btn>
                            </v-row>
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                            <td class="pa-0 connector-table-container" :colspan="headers.length">
                            <v-row class="pt-5 pb-2">
                                <v-chip class="ml-15" outlined color="accent" small>
                                    Max power: {{ parseFloat(item.max_electric_power / 1000).toFixed(1) }}kW
                                </v-chip>

                                <v-chip class="ml-2" outlined color="accent" small>
                                    Max voltage: {{ item.max_voltage }}
                                </v-chip>

                                <v-chip class="ml-2" outlined color="accent" small>
                                    Max amperage: {{ item.max_amperage }}
                                </v-chip>
                            </v-row>
                            <v-row class="pb-5">
                                <v-chip v-for="tariff_id in item.tariff_ids" :key="tariff_id" class="ml-15" outlined color="accent" small
                                    @click="goTo(`/tariffs?search=${tariff_id}`)"
                                >
                                   Tariff: {{ tariff_id }}
                                </v-chip>
                            </v-row>
                            </td>

                        </template>
                        <template v-slot:item.last_updated="{ item }">
                            {{ item.last_updated | dateFullFormat }}
                        </template>
                        <template v-slot:item.deleted_at="{ item }">
                            {{ item.deleted_at | dateFullFormat }}
                        </template>
                    </v-data-table>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="show = false" depressed>Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { EventBus } from "@/event-bus"

export default {
    data() {
        return {
            show: false,
            loading: false,
            evse: {},
            connectors: {},
        }
    },
    watch: {
        show( show ) {
            if ( !show ) {
                this.loading = false
                EventBus.$emit("dialog-evses:close")
            }
        }
    },
    created() {
        var vm = this;
        EventBus.$on("dialog-evses:open", async function( args ) {
            vm.evse = args.evse
            vm.connectors = args.connectors
            vm.show = true
        })
    },
    methods: {
        close() {
            this.show = false
        },
    }
}
</script>

<style scoped>
    >>> .v-button {
    height: 0 !important;
    padding: 0 !important;
    }
    >>> .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    box-shadow: none;
    }
    >>> .v-list-item > .v-list-item__content {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }
    >>> .v-list-item {
        min-height: 0 !important;
    }
    >>> .v-data-table__wrapper{
        overflow: hidden !important;
    }
</style>