import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VToolbar,{attrs:{"flat":""}},[_c(VSpacer),_c('div',{staticClass:"pt-4"},[_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","hide-details":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Date from","prepend-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.selectedDateFrom),callback:function ($$v) {_vm.selectedDateFrom=$$v},expression:"selectedDateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDateFromPicker),callback:function ($$v) {_vm.showDateFromPicker=$$v},expression:"showDateFromPicker"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"input":function($event){_vm.showDateFromPicker = false}},model:{value:(_vm.selectedDateFrom),callback:function ($$v) {_vm.selectedDateFrom=$$v},expression:"selectedDateFrom"}})],1)],1),_c('div',{staticClass:"ml-4 pt-4"},[_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","hide-details":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Date to","prepend-icon":"mdi-calendar","readonly":"","clearable":""},model:{value:(_vm.selectedDateTo),callback:function ($$v) {_vm.selectedDateTo=$$v},expression:"selectedDateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDateToPicker),callback:function ($$v) {_vm.showDateToPicker=$$v},expression:"showDateToPicker"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"input":function($event){_vm.showDateToPicker = false}},model:{value:(_vm.selectedDateTo),callback:function ($$v) {_vm.selectedDateTo=$$v},expression:"selectedDateTo"}})],1)],1),_c(VBtn,{staticClass:"mr-2 ml-4",attrs:{"color":"accent","loading":_vm.loading},on:{"click":_vm.onFilter}},[_c(VIcon,[_vm._v("mdi-filter")])],1),_c('div',{staticClass:"ml-4"},[_c(VBtn,{staticClass:"mr-2",attrs:{"color":"accent","loading":_vm.loadingCSV,"outlined":""},on:{"click":_vm.createCSV}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-file-download-outline")]),_vm._v(" CSV ")],1)],1)],1),_c(VDivider),_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"headers":[
          { text: 'Id.', value: 'id' },
          { text: 'Ocpi id', value: 'ocpi_id' },
          { text: 'Start Date', value: 'start_date_time' },
          { text: 'End Date', value: 'end_date_time' },
          { text: 'Total cost', value: 'total_cost' },
          { text: 'Total energy', value: 'total_energy' },
          { text: 'Time', value: 'total_time' },
          { text: 'Last updated', value: 'last_updated' } ],"items":_vm.cdrs,"disable-sort":""},on:{"update:options":this.updateTableOptions},scopedSlots:_vm._u([{key:"item.start_date_time",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFullFormat")(item.start_date_time))+" ")]}},{key:"item.end_date_time",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFullFormat")(item.end_date_time))+" ")]}},{key:"item.total_time",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("hoursToTimeTextFormat")(item.total_time))+" ")]}},{key:"item.total_cost",fn:function(ref){
      var item = ref.item;
return [(item.total_cost)?[_vm._v(_vm._s(item.total_cost.excl_vat)+" "+_vm._s(item.currency))]:[_vm._v("-")]]}},{key:"item.last_updated",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFullFormat")(item.last_updated))+" ")]}}])}),(_vm.hasRole('CPO'))?_c('ImportCdrsFromProvider',{staticClass:"mt-2",attrs:{"provider-id":_vm.providerId},on:{"data-imported":function($event){return _vm.getCdrs()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }