<template>
    <v-container fluid>

        <AppBar title="Export Locations to Core" icon="mdi-database-export" />

        <div class="d-flex my-4">
            <v-icon color="accent">mdi-database-export-outline</v-icon>
            <h3 class="ml-2">Select Locations to export to Core</h3>
        </div>

        <v-textarea
            class="mb-4 text-overline" style="font-size: 1rem!important"
            v-model="sqlquery"
            auto-grow
            outlined
            hide-details
            background-color="grey lighten-4"
        >
            <template v-slot:prepend-inner>
                SELECT id FROM (
            </template>
            <template v-slot:append>
                ) AS a
            </template>
        </v-textarea>

        <v-btn @click="exportLocations" :loading="loading" color="accent">Export</v-btn>

        <v-card v-if="response" 
            class="mt-4"
            outlined
        >
            <v-data-table
                :headers="[
                    { text: 'Location id', value: 'id' },
                    { text: 'Success?', value: 'success' },
                    { text: 'Message', value: 'message' },
                ]"
                dense
                :items="response"              
            >
                <template v-slot:item.id="{ item }">
                    <v-btn class="px-1" style="min-width: 16px;" plain small :to="`/location/${item.id}`">
                        <v-icon small>mdi-arrow-top-right</v-icon>
                    </v-btn>
                    {{ item.id }}
                </template>
                <template v-slot:item.success="{ item }">
                    <v-icon :color="item.success ? 'success' : 'error'">
                        {{ item.success ? 'mdi-check-circle' : 'mdi-close-circle' }}
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"

export default {
    components: {
        AppBar
    },
    data() {
        return {
            sqlquery: `SELECT DISTINCT lo.id FROM locations lo
LEFT JOIN charge_points cp ON cp.location_id = lo.id
LEFT JOIN evses e ON e.chargepoint_id = cp.id
LEFT JOIN connectors c ON c.evse_id = e.id
WHERE lo.provider_id = 52 
    AND c.max_electric_power >= 150000 
    AND lo.deleted_at is null 
    AND e.deleted_at is null 
    AND lo.publish = true
LIMIT 0`,
            loading: false,
            response: null
        }
    },
    methods: {
        async exportLocations(){

            this.loading = true

            // Removed previous answer to update table
            this.response = null

            this.response = await this.$api.post('settings/export-locations-to-core', { sqlquery: this.sqlquery })
                .then(resp => resp.data)
                .catch(this.showError)

            this.loading = false

        }
    }
}
</script>

<style scoped>
    >>> .v-text-field__slot textarea {
        margin: 0!important;
        padding-top: 32px;
        padding-bottom: 32px;
        padding-left: 18px;
    }
    >>> .v-input__prepend-inner {
        position: absolute;
        padding: 0;
        margin: 0;
        top: 12px;
        left: 12px;
    }
    >>> .v-input__append-inner {
        position: absolute;
        bottom: 0;
        left: 0;
        padding-bottom: 12px;
        padding-left: 12px!important;
    }
</style>
