<template>
    <v-dialog v-model="show" persistent max-width="500px" content-class="custom-scollbar" @click:outside="close">
        <v-card>
            <v-card-title class="accent white--text">
                <v-icon large left color="white">mdi-credit-card-multiple-outline</v-icon> 
                <span class="headline">Invoice Cdrs</span>
            </v-card-title>

            <v-spacer></v-spacer>

            <v-divider />

            <v-card-text class="pa-4">
                <h4 class="mb-2">  
                   Do you want to set downloaded CDRs as invoiced?
                </h4>

                This action will set <v-chip small>CDR Invoiced At</v-chip> to current date.
                
            </v-card-text>
            <v-card-actions>
                <v-btn @click="invoiceCdrs" depressed color="accent" :loading="loading">Agree</v-btn>
                <v-spacer />
                <v-btn @click="show = false" depressed>Disagree</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { EventBus } from "@/event-bus"

export default {
    data() {
        return {
            show: false,
            loading: false,
            cdrs: []
        }
    },
    watch: {
        show( show ) {
            if ( !show ) {
                this.loading = false
                this.cdrs = []
                EventBus.$emit("dialog-invoice-cdr:close")
            }
        }
    },
    created() {
        var vm = this
        EventBus.$on("dialog-invoice-cdr:open", async function( args ) {
            vm.cdrs = args.cdrs
            vm.show = true
        })
    },
    methods: {
        close() {
            this.show = false
        },
        async invoiceCdrs() {
            this.loading = true

            this.$api
                .patch(`cdrs/invoice`, { cdrs: this.cdrs })
                .then(() =>{
                    this.showSuccess("CDRs invoiced date set successfully")
                    this.show = false
                })
                .catch(this.showError)

            this.loading = false
        }
    }
}
</script>
