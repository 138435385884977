<template>
    <div>
        <template v-if="item.childs">

            <v-menu open-on-hover open-delay="200">
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item link v-bind="attrs" v-on="on" :class="isActiveNavigationItem(item, routerPath) ? 'v-list-item--active' : ''">
                        <v-list-item-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon class="mt-3">
                            <v-icon>mdi-menu-right</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </template>
                <v-list dense class="accent-navigation-drawer py-0">
                    <navigation-item :key="`${cIndex}`" :item="child" v-for="(child, cIndex) of item.childs">
                        <template v-for="(index, name) in $slots" v-slot:[name]>
                            <slot :name="name" />
                        </template>
                    </navigation-item>
                </v-list>
            </v-menu>

            <template v-if="$slots[`item.${item.id}.append`]">
                <slot :name="`item.${item.id}.append`"></slot>
            </template>

        </template>

        <template v-else>

            <v-list-item link :to="`${item.external ? '' : item.link}`" :href="`${item.link}`" :class="isActiveNavigationItem(item, routerPath) ? 'v-list-item--active' : '' + item.customClass ? ` ${item.customClass}` : ''">
                <v-list-item-action>
                    <template v-if="$slots[`item.${item.id}.icon`]">
                        <slot :name="`item.${item.id}.icon`"></slot>
                    </template>
                    <v-icon v-else>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="$slots[`item.${item.id}.action`]">
                    <slot :name="`item.${item.id}.action`"></slot>
                </v-list-item-action>
            </v-list-item>

            <template v-if="$slots[`item.${item.id}.append`]">
                <slot :name="`item.${item.id}.append`"></slot>
            </template>

        </template>
    </div>
</template>

<script>
export default {
    name: 'navigation-item',
    /**
     * @slot item.{item.id}.icon - Template with navigation item icon
     */
    /**
     * @slot item.{item.id}.action - Actions that will be displayed to right of navigation item text
     */
    /**
     * @slot item.{item.id}.append - Content to be displayed after navigation item
     */
    props: ['item'],
    computed: {
        routerPath(){ return this.$route.path },
    },
    methods: {
        isActiveNavigationItem(item, routerPath){
            if (item.childs){
                return item.childs.some(child => child.link == routerPath || (child.activeRegex && new RegExp(child.activeRegex).test(routerPath)))
            }
            return item.activeRegex && new RegExp(item.activeRegex).test(routerPath)
        }
    }
}
</script>