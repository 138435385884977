<template>
  <div>
    <v-toolbar flat>
      <v-spacer></v-spacer>
      <div>
        <v-text-field
          v-model="selectedSearch"
          clearable
          hide-details
          append-icon="mdi-magnify"
          placeholder="Buscar"
          class="mr-8"
        >
        </v-text-field>
      </div>
      <v-btn @click="checkTokens()" outlined color="accent" small>
        Check tokens
        <v-icon right>mdi-arrow-down-bold</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider />

    <v-data-table
      :headers="[
        {text: 'UID', value: 'uid'},
        {text: 'Valid', value: 'valid' },
        {text: 'Type', value: 'type'},
        {text: 'Contract Id.', value: 'contract_id' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]"
      class="accent-table rounded-table"
      :items="sentTokens"
      :search="selectedSearch"
      :loading="loading"     
      disable-sort
      single-expand
      show-expand
      dense
    >
      <template v-slot:item.actions="{ item }">
        <v-btn
          class="mr-2"
          color="accent"
          depressed
          small
          @click="updateToken(item)"
        >Update</v-btn>

        <v-btn
          class="mr-2"
          color="accent"
          depressed
          small
          :disabled="!item.valid"
          @click="deleteToken(item)"
        >Delete</v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <json-viewer
            class="pa-2"
            :value="item"
            :copyable="{
              copyText: 'Copiar',
              copiedText: 'Copiado!',
              timeout: 2000,
            }"
            :expand-depth="3"
          >
          </json-viewer>
        </td>
      </template> 
      <template v-slot:footer v-if="notSentTokens.length > 0">
        <v-btn
          style="position: absolute" class="ma-4"
          text          
          small
          @click="sendTokens(notSentTokens)"
        ><v-icon left>mdi-magnify</v-icon> Not sent Tokens</v-btn>
      </template>
      </v-data-table>

      <NotSentTokensDialog />
  </div>
</template>
<script>
import { EventBus } from "@/event-bus"
import NotSentTokensDialog from "@/components/provider/NotSentTokensDialog";

export default {
  name: "tokens-into-provider",
  components: {
    NotSentTokensDialog
  },
  props: ["providerId"],
  data() {
    return {
      sentTokens: [],
      notSentTokens: [],
      selectedSearch: null, 
      loading: false,
    };
  },
  mounted() {
    this.checkTokens()
  },
  watch: {
    selectedSearch: _.debounce(function () {
            this.urlPush('search', this.selectedSearch)
    }, 500),
    //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
    '$route.query':{ 
        handler(params) {
            this.routeFilters(params)
        },
        immediate: true
    },
  },
  methods: {
    checkTokens() {
      if (!this.providerId) { return }

      this.loading = true

      this.$api
        .get(`cpo/providers/${this.providerId}/tokens`)
        .then((resp) => {
          if (Array.isArray(resp.data)) {
            this.sentTokens = resp.data.filter(token => token.sent).map(token => token.data)            
            this.notSentTokens = resp.data.filter(token => !token.sent).map(token => token.data)                
          }
        })
        .catch(this.showError)
        .then((_) => { this.loading = false })
    },
    updateToken(token){
      this.loading = true

      this.$api
          .put(`cpo/providers/${this.providerId}/tokens/${token.uid}`)
          .then(resp => {
              this.sentTokens = this.sentTokens.map(token => { return token.id === resp.data.id ? resp.data : token })
          })
          .catch(this.showError)
          .then((_) => { this.loading = false })
    },
    deleteToken(token){
      this.loading = true

      this.$api
        .delete(`cpo/providers/${this.providerId}/tokens/${item.uid}`, { data: item })
        .then((resp) => {
          if (resp.data) {
            this.sentTokens = this.sentTokens.filter(input => input.id !== token.id)
          }
        })
        .catch(this.showError)
        .then((_) => { this.loading = false })
    },
    sendTokens(tokensToSend) {
      EventBus.$emit(
          "dialog-not-sent-locations:open",
          { tokens: tokensToSend, providerId: this.providerId }
      )
    }
  },
};
</script>