<template>
    <v-container fluid>

        <AppBar :breadcrumbs="breadcrumbs" />

        <CompanyForm v-if="!loading" :previousCompany="company" />

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import { EventBus } from "@/event-bus"
import CompanyForm from "@/components/company/CompanyForm"

export default {
    name: "company.edit",
    components: { 
        AppBar, 
        CompanyForm 
    },
    data() {
        return {
            company: {
                id: null,
                technical_contacts: [],
                channels: [],
                user_id: null,
            },  
            loading: false,
        }
    },
    computed: {
        companyName: function() {
            return this.company?.id ? 'Edit company': 'New company'
        },
        breadcrumbs: function() {
            if(this.company.user_id){
                return [
                    { text: 'Connection', to: `/connection/${this.company.user_id}` },
                    { text: this.companyName, disabled: true },
                ]
            }

            if(this.company.provider_id){
                return [
                    { text: 'Provider', to: `/provider/${this.company.provider_id}` },
                    { text: this.companyName, disabled: true },
                ]
            }

            return [
                { text: this.companyName, disabled: true },
            ]
        }
        
    },
    beforeDestroy(){
        EventBus.$off('company-created')
    },
    async mounted(){
        this.loading = true 

        EventBus.$on("company-created", () => {
            if(this.company.user_id){
                this.$router.push({ path: `/connection/${this.company.user_id}` })
            }

            if(this.company.provider_id){
                this.$router.push({ path: `/provider/${this.company.provider_id}` })
            }
            
            setTimeout(() => { this.showSuccess("New company created") }, 500)
        })

        if( !this.$router.currentRoute.params.id ){
            this.company.user_id = this.$router.currentRoute.query.user_id
        }else{
            try {
                let response = await this.$api.get(`/company/${this.$router.currentRoute.params.id}`)
                this.company = response.data

            } catch(e) {
                this.showError(e)
            }
        }

        this.loading = false

    },
};
</script>

<style scoped>
    >>> .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
        max-height: 32px !important;
        min-height: 32px !important;
    }
</style>
