<template>
    <v-dialog v-model="show" :max-width="maxWidth">
      <v-card class="pa-4">
        <TariffForm 
          v-model="tariff" 
          @cancel="show = false"
          @updated="(tariff) => tariffCreated(tariff)"
        />
      </v-card>
    </v-dialog>
</template>
<script>
import TariffForm from "@/components/tariff/Tariff.edit"
import { EventBus } from "@/event-bus";

export default {
    name: "tariff-edit-dialog",
    components: {
      TariffForm
    },
    data(){
      return {
        show: false,
        tariff: null
      }
    },
    created() {
        var vm = this
        EventBus.$on("tariff-edit-dialog:open", async(tariff) => {
          vm.show = true
          vm.tariff = tariff
        });
    },
    watch: {
      async show(val) {
        if (!val) { this.tariff = null }
      }
    },
    computed:{
      maxWidth(){
          if (this.$vuetify.breakpoint.name == 'xl') { return 1200 }
          return 1000
      }
    },
    methods:{
      tariffCreated(tariff){
        this.show = false
        this.$router.push({ path: `/tariffs/${tariff.id}`, replace: true })
      }
    }
}
</script>