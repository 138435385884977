<template>
    <v-container fluid>

        <AppBar title="Sessions by Provider" icon="mdi-account-details" />

        <v-row>
            <v-col cols=12 md=8>
                <v-card height="100%" :loading="loading">
                    <template slot="progress"><v-progress-linear color="accent" indeterminate></v-progress-linear></template>
                    
                    <v-card-title>
                        <v-icon color="accent" left>mdi-chart-timeline-variant</v-icon>
                        <h3 class="black--text">
                            Sessions per provider
                        </h3>
                    </v-card-title>
                    
                    <v-row class="pl-6 py-2" align='center'>
                        <v-btn text color="accent" @click="setFilters">
                            <v-icon left>mdi-filter</v-icon>Filters
                        </v-btn>
                        <span class="pl-2">
                            {{ filterText }}
                        </span>
                    </v-row>

                    <SessionsPerProviderSplineGraph class="ma-2" :sessions="sessionsPerDay" :colorPerProvider="colorPerProvider" />
                </v-card>
            </v-col>
            <v-col cols=12 md=4>
                <v-card height="100%" :loading="loading">
                    <template slot="progress"><v-progress-linear color="accent" indeterminate></v-progress-linear></template>
                    <v-card-title>
                        <v-icon color="accent" left>mdi-chart-pie</v-icon>
                        <h3 class="black--text">
                            Origin
                        </h3>
                    </v-card-title>

                    <SessionsPerProviderPieGraph :sessions="sessions" :colorPerProvider="colorPerProvider" />
                </v-card>
            </v-col>
        </v-row>

        <FiltersDialog />

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import { EventBus } from "@/event-bus"
import FiltersDialog from "@/components/reports/FiltersDialog"
import SessionsPerProviderPieGraph from "@/components/reports/SessionsPerProviderPieGraph"
import SessionsPerProviderSplineGraph from "@/components/reports/SessionsPerProviderSplineGraph"
import Colors from "@/plugins/Colors.js"

export default {
    name: "wenea",
    components: {
        AppBar,
        FiltersDialog,
        SessionsPerProviderPieGraph,
        SessionsPerProviderSplineGraph
    },
    data() {
        return {
            loading: false,
            sessions: [],
            sessionsPerDay: [],
            colorPerProvider: new Map(),
            providersNumberToShow: 6,
            // filters
            selectedYear: null,
            selectedMonth: null,
            selectedDateFrom: null,
            selectedDateTo: null,
            selectedFilterBy: null,
        }
    },
    mounted(){
        var vm = this

        EventBus.$on("dialog-filters:filters-applied", async function (args) {
            vm.selectedYear = args.selectedYear
            vm.selectedMonth = args.selectedMonth
            vm.selectedDateFrom = args.selectedDateFrom
            vm.selectedDateTo = args.selectedDateTo
            vm.selectedFilterBy = args.selectedFilterBy, 
            
            vm.load()
        })

        this.selectedMonth = new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            1
        )
        .toISOString()
        .substr(0, 7)

        this.selectedFilterBy = "month"

        this.load()
    },
    computed: {
        filterText() {
            if (this.selectedYear) {
                return `Sessions made in ${this.selectedYear}`
            } else if (this.selectedMonth) {
                return `Sessions made in ${this.$options.filters.monthFormat(this.selectedMonth)}`
            } else {
                return `Sessions made between ${this.$options.filters.dateFormat(this.selectedDateFrom)} and ${this.$options.filters.dateFormat(this.selectedDateTo)}`
            }
        }
    },
    methods: {
        async load() {   
            this.loading = true

            const filter = {}
            if (this.selectedDateFrom) {
                filter.date_from = this.dateFormat(this.selectedDateFrom)
            }
            if (this.selectedDateTo) {
                filter.date_to = this.dateFormat(this.selectedDateTo)
            }

            if (this.selectedMonth) {
                filter.date_from = this.dateFormat(this.selectedMonth)
                const lastDayOfMonth = new Date(new Date(this.selectedMonth).getFullYear(), new Date(this.selectedMonth).getMonth() + 1, 0)
                filter.date_to = `${lastDayOfMonth.getFullYear()}-${String(lastDayOfMonth.getMonth() + 1).padStart(2, "0")}-${lastDayOfMonth.getDate()}`
            }

            if (this.selectedYear) {
                filter.date_from = `${this.selectedYear}-01-01`
                filter.date_to = `${this.selectedYear}-12-31`
            }

            this.sessions = await this.$api
                .get('reports/sessions-per-provider', { params: filter })
                .then((resp) => resp.data)
                .catch(this.showError)

            this.sessionsPerDay = await this.$api
                .get('reports/providers-evolutionary-statistics', { params: { group_by: 'day', ...filter } })
                .then((resp) => resp.data)
                .catch(this.showError)
                
            this.sessionsPerDay = this.fillDatesWithoutData(this.sessionsPerDay, filter.date_from, filter.date_to)
            
            let providers = this.sessions.map(i => i.provider)
            if (providers.length > this.providersNumberToShow) {

                providers = providers.slice(0, this.providersNumberToShow)
    
                // Group PieGraph Sessions
                const otherSessions = {
                    provider: "OTHERS",
                    sessions: this.sessions
                            .filter(s => !providers.includes(s.provider))
                            .map(s => s.sessions)
                            .reduce((partialSum, a) => partialSum + Number(a), 0)
                        }
                   
                this.sessions = this.sessions.filter(t => providers.includes(t.provider))
                this.sessions.push(otherSessions)
                 
                // Group SplineGraph Sessions
                this.sessionsPerDay = this.groupOtherProviders(this.sessionsPerDay, providers, filter.date_from, filter.date_to)

                providers.push("OTHERS")

            }

            this.colorPerProvider = this.generateColorPerProvider(providers)
            
            this.loading = false
        },
        generateColorPerProvider(providers){
            const colorList = Colors.generateColorList(providers.length)
            const colorByProviderMap = new Map()
            for (let i = 0; i < providers.length; i++) {
                colorByProviderMap.set(providers[i], colorList[i])
            }
            
            return colorByProviderMap
        },
        fillDatesWithoutData(sessionsPerDay, date_from, date_to){
            const providers = new Set(sessionsPerDay.map(t => t.provider))
            const days = (new Date(date_to).getTime() - new Date(date_from).getTime()) / (1000 * 3600 * 24)
            
            for (const provider of providers){
                let providerSessions = sessionsPerDay.filter(t => t.provider == provider)
                for (let i = 0; i < days; i++) {
                    let date = new Date(date_from)
                    date.setDate(date.getDate() + i)
                    if ( !providerSessions.some(ot => ot.period_date.substring(0, 10) == date.toISOString().substring(0, 10)) && date < new Date() ){
                        sessionsPerDay.push({
                            period_date: date.toISOString().substring(0, 10),
                            provider,
                            sessions: '0'
                        })
                    }
                }
            }

            return sessionsPerDay.map(tp => { return {...tp, period_date: tp.period_date.substring(0, 10)}}).sort((p1,p2) => new Date(p1.period_date) - new Date(p2.period_date))
        },
        groupOtherProviders(sessionsPerDay, filteredProviders, date_from, date_to){            
            
            // If date_to is greater than today's date, today's date is used
            if (new Date(date_to) > new Date()) { date_to = new Date() }  

            const days = (new Date(date_to).getTime() - new Date(date_from).getTime()) / (1000 * 3600 * 24)
            const groupedSessionsPerDay = sessionsPerDay.filter(t => filteredProviders.includes(t.provider))
            
            let othersProviderSessions = sessionsPerDay.filter(t => !filteredProviders.includes(t.provider))
            for (let i = 0; i < days; i++) {
                let date = new Date(date_from)
                date.setDate(date.getDate() + i)
                
                let sessions = othersProviderSessions
                    .filter(ot => ot.period_date.substring(0, 10) == date.toISOString().substring(0, 10))
                    .map(ds => ds.sessions)
                    .reduce((partialSum, a) => partialSum + Number(a), 0) + ""

                groupedSessionsPerDay.push({
                    period_date: date.toISOString().substring(0, 10),
                    provider: 'OTHERS',
                    sessions
                })
            }
            
            return groupedSessionsPerDay
        },
        setFilters() {           
            EventBus.$emit("dialog-filters:open", { 
                selectedYear: this.selectedYear,         
                selectedMonth: this.selectedMonth,
                selectedDateFrom: this.selectedDateFrom,
                selectedDateTo: this.selectedDateTo,
                selectedFilterBy: this.selectedFilterBy, 
            })
        },
    }
};
</script>
