import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"headers":[
          {value: 'go_to', sortable:false, cellClass: 'td-linkto' },
          {text: 'Id.', value: 'id' },
          {text: 'Start', value: 'start_date_time'},
          {text: 'End', value: 'end_date_time'},
          {text: 'Sender / Receiver', value: 'sender_receiver' },
          {text: 'Ocpi Id.', value: 'ocpi_id' },
          {text: 'Energy (kWh)', value: 'kwh'},
          {text: 'Id. DTM', value: 'dtm_session_id'},
          {text: 'Backend uuid', value: 'backend_uuid'},
          {text: 'Actions', value: 'actions'} ],"items":_vm.sessionsWithoutCdrs,"loading":_vm.loading,"sort-by":"id","dense":"","no-data-text":"No sessions found without CDRs"},scopedSlots:_vm._u([{key:"item.go_to",fn:function(ref){
      var item = ref.item;
return [_c(VBtn,{attrs:{"plain":"","small":"","to":("/session/" + (item.id))}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1)]}},{key:"item.sender_receiver",fn:function(ref){
      var item = ref.item;
return [(item.sender_id === 1)?_c(VChip,{attrs:{"color":"secondary","small":""}},[_vm._v(_vm._s(item.sender_name))]):_c('ProviderName',{attrs:{"provider":{ id: item.sender_id, country_code: item.sender_name.split('-')[0] , party_id: item.sender_name.split('-')[1] }}}),_c(VIcon,{attrs:{"color":"secondary"}},[_vm._v("mdi-arrow-right")]),(item.receiver_id === 1)?_c(VChip,{attrs:{"color":"secondary","small":""}},[_vm._v(_vm._s(item.receiver_name))]):_c('ProviderName',{attrs:{"provider":{ id: item.receiver_id, country_code: item.receiver_name.split('-')[0] , party_id: item.receiver_name.split('-')[1] }}})]}},{key:"item.start_date_time",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFullFormat")(item.start_date_time))+" ")]}},{key:"item.end_date_time",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFullFormat")(item.end_date_time))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c(VBtn,{attrs:{"small":"","outlined":"","color":"accent","disabled":!!item.backend_uuid && _vm.processed_backend_uuids.includes(item.backend_uuid),"loading":!!item.backend_uuid && _vm.processed_backend_uuids.includes(item.backend_uuid)},on:{"click":function () { return _vm.generateCdr(item.backend_uuid); }}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-credit-card-multiple-outline")]),_vm._v(" Generate CDR ")],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }