<template>
    <v-dialog v-model="show" persistent max-width="1180px">
        <v-card>
            <v-card-title class="accent white--text">
                <v-icon left color="white">mdi-vector-polyline</v-icon><span>Create new connection</span>
            </v-card-title>

            <ConnectionForm :user="{}">
                <template v-slot:actions>
                    <v-btn @click="show = false" text>Close</v-btn>
                </template>
            </ConnectionForm>
        </v-card>
    </v-dialog>
</template>
<script>
import { EventBus } from "@/event-bus"
import ConnectionForm from "@/components/connection/ConnectionForm"

export default {
    components: {
        ConnectionForm 
    },
    data() {
        return {
            show: false
        }
    },
    created() {
        var vm = this
        EventBus.$on("dialog-create-connection:open", () => vm.show = true)
        EventBus.$on("dialog-create-connection:close", () => vm.show = false)
    }
}
</script>