<template>
    <v-chip outlined color="accent" :small="small">
        <template v-if="priceComponent.type=='ENERGY'">{{ priceComponent.price }} {{ currency }} x {{ priceComponent.step_size }} kWh {{ priceComponent.vat ? `(VAT ${priceComponent.vat}%)` : ''}}</template>
        <template v-else-if="priceComponent.type=='FLAT'">{{ priceComponent.price }} {{ currency }} {{ priceComponent.vat ? `(VAT ${priceComponent.vat}%)` : ''}}</template>
        <template v-else-if="priceComponent.type=='PARKING_TIME'">{{ priceComponent.price }} {{ currency }} x {{ priceComponent.step_size }} parking seconds {{ priceComponent.vat ? `(VAT ${priceComponent.vat}%)` : ''}}</template>
        <template v-else-if="priceComponent.type=='TIME'">{{ priceComponent.price }} {{ currency }} x {{ priceComponent.step_size }} parking seconds {{ priceComponent.vat ? `(VAT ${priceComponent.vat}%)` : ''}}</template>
    </v-chip>
</template>

<script>
export default {
    name: "price-component-chip",
    props: {
        priceComponent: {
            required: true
        },
        currency: {
            required: true
        },
        small: {
            default: false
        }
    }
}
</script>