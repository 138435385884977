import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"title":"Wenea"}}),_c('h2',[_vm._v("Wenea")]),(_vm.info)?_c(VRow,{staticClass:"ma-2"},[_c(VCol,[_c('div',{staticClass:"title"},[_vm._v("Host")]),_c('div',[_vm._v(_vm._s(_vm.info.host))])]),_c(VCol,[_c('div',{staticClass:"title"},[_vm._v("Roles")]),(_vm.info.roles.cpo)?_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("CPO")]),_vm._v(": "+_vm._s(_vm.info.roles.cpo.country_code)+"-"+_vm._s(_vm.info.roles.cpo.party_id))]):_vm._e(),(_vm.info.roles.emsp)?_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("EMSP")]),_vm._v(": "+_vm._s(_vm.info.roles.emsp.country_code)+"-"+_vm._s(_vm.info.roles.emsp.party_id))]):_vm._e()])],1):_vm._e(),_c('h2',{staticClass:"pl-1 mt-6"},[_vm._v("Provider credentials")]),(_vm.credentials)?_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"headers":[
            {text: 'Id.', value: 'id'},
            {text: 'Token', value: 'token'},
            {text: 'Updated', value: 'updated_at'},
            {text: 'User', value: 'user.username'},
            {text: 'Type', value: 'user.type'},
            {text: 'Company', value: 'connection'} ],"items":_vm.credentials,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.connection",fn:function(ref){
        var item = ref.item;
return [_c('ProviderName',{attrs:{"provider":{ applicable_user: item.user.id, username: _vm.provider_name(item) },"extra":"applicable_user"}})]}}],null,false,1926359813)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }