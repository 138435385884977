<template>
    <v-tooltip bottom :disabled="info == '' || !info">
        <template v-slot:activator="{ on, attrs }">
            <v-simple-checkbox 
                key="tokens" 
                :ripple="false"
                :class="value != '' ? value : 'indeterminate-checkbox'"
                :value="value == 'success-checkbox' ? true : false"
                :indeterminate="value == 'success-checkbox' ? false : true"
                v-on="on"
                v-bind="attrs"
                @click="$emit('click')"
            ></v-simple-checkbox>
        </template>
        {{info}}
    </v-tooltip>
</template>

<script>

export default ({
    props: ["value", "info"],
})
</script>

<style scoped>
    .v-simple-checkbox.success-checkbox i {
        color: #4caf50 !important;
    }

    .v-simple-checkbox.indeterminate-checkbox i {
        color: #ffa72d;
    }

    .v-simple-checkbox.error-checkbox i {
        color: #ff3535;
    }
</style>
