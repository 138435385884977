import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"title":"Locations Amount by Provider","icon":"mdi-map-marker"}}),_c(VRow,{staticClass:"mb-2 px-2",attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VTextField,{attrs:{"clearable":"","color":"accent","append-icon":"mdi-magnify","placeholder":"Search","hide-details":"","autofocus":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onFilter.apply(null, arguments)}},model:{value:(_vm.selectedSearch),callback:function ($$v) {_vm.selectedSearch=$$v},expression:"selectedSearch"}})],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VAutocomplete,{attrs:{"items":_vm.providers,"label":"Provider","color":"accent","clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('ProviderSelectorTemplate',{attrs:{"provider":data.item}})]}}]),model:{value:(_vm.selectedProvider),callback:function ($$v) {_vm.selectedProvider=$$v},expression:"selectedProvider"}})],1),_c(VBtn,{staticClass:"mt-5 mr-2",attrs:{"color":"accent","loading":_vm.tableLoading},on:{"click":_vm.onFilter}},[_c(VIcon,[_vm._v("mdi-filter")])],1),_c(VSpacer),_c(VBtn,{staticClass:"mt-5 mr-2",attrs:{"color":"accent","loading":_vm.loadingCSV,"outlined":""},on:{"click":_vm.createCSV}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-file-download-outline")]),_vm._v(" CSV ")],1)],1),_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"headers":[
            { text: 'Provider', value: 'provider' },
            { text: 'Locations', value: 'locations' },
            { text: 'Locations with DC +50kW', value: 'locations_with_dc50' },
            { text: 'Locations with DC +100kW', value: 'locations_with_dc100' },
            { text: 'Locations with DC +150kW', value: 'locations_with_dc150' },
            { text: 'Locations with DC +300kW', value: 'locations_with_dc300' },
            { text: 'Most Common Countries', value: 'countries_list'}
        ],"items":_vm.locationsAmount,"loading":_vm.tableLoading,"disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.provider",fn:function(ref){
        var item = ref.item;
return [_c('ProviderName',{attrs:{"provider":{ id: item.provider_id, country_code: item.country_code, party_id: item.party_id }}})]}},{key:"item.countries_list",fn:function(ref){
        var item = ref.item;
return _vm._l((item.countries_list),function(country,index){return _c(VChip,{key:index,staticClass:"mr-1",attrs:{"color":"accent","outlined":"","small":""}},[_vm._v(" "+_vm._s(country)+" ")])})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }