<template>
    <v-form ref="form">
        <v-row class="ml-3 mt-6">
            <v-col cols="12" lg="3" xl="2">
                 <v-select
                    class="pr-4"
                    v-model="selectedApplicableOptions"
                    :items="applicableOptions"
                    clearable
                    dense
                    label="Applicable to"
                    @change="() => { value.applicable_providers = null; value.applicable_user = null }"
                >
                    <template v-slot:selection="{ item }">
                        <v-chip small color="accent" outlined>{{ item.toUpperCase() }}</v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                        <v-chip small color="accent" outlined>{{ item.toUpperCase() }}</v-chip>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" lg="5" xl="3" v-if="selectedApplicableOptions && selectedApplicableOptions != 'default'">
                <v-autocomplete v-if="selectedApplicableOptions == 'provider'"
                    class="pr-4"
                    v-model="value.applicable_providers"
                    :items="externalProviders"
                    label="Applicable providers"
                    :rules="[v => (!!v && !!v.length) || 'Applicable provider is required']"
                    multiple
                    dense
                >
                    <template v-slot:selection="{ item }">
                        <v-chip small color="accent" outlined>{{ item.country_code }}-{{ item.party_id }}</v-chip>
                    </template>
                    <template v-slot:item="{ item, attrs, on }">
                        <ProviderSelectorTemplate 
                            v-on="on" v-bind="attrs"
                            multiple
                            :provider="item" 
                        />
                    </template>
                </v-autocomplete>
                <v-autocomplete v-if="selectedApplicableOptions == 'hub'"
                    class="pr-4"
                    v-model="value.applicable_user"
                    :items="hubs"
                    label="Applicable user"
                    :rules="[v => !!v || 'Applicable user is required']"
                    dense
                >
                    <template v-slot:selection="{ item }">
                        <v-chip small color="accent" outlined>{{ item.text.toUpperCase() }}</v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                        <v-chip small color="accent" outlined>{{ item.text.toUpperCase() }}</v-chip>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="4" xl="3">
                <v-subheader class="pl-0" style="height: 0px;">
                    Priority
                </v-subheader>
                <v-row class="mx-0 pt-2 pr-4" justify="center">
                    <PriorityIcon :priority="value.priority || 1" :max="9" :min="1"></PriorityIcon>
                    <v-slider
                        class="pt-4"
                        max="9"
                        min="1"
                        v-model="value.priority"
                        thumb-label
                        dense
                        color="accent"
                    >
                    </v-slider>
                </v-row>
            </v-col>
            <v-col cols="12" md="12">
                <v-text-field class="pr-6"
                    v-model="value.applicable_query"
                    label="Applicable query"
                    dense
                ></v-text-field>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import PriorityIcon from "@/components/icons/PriorityIcon"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"

export default {
    name: "tariff-configurations-edit",
    props: {
        value: {
            required: true
        }
    },
    components: {
        PriorityIcon,
        ProviderSelectorTemplate
    },
    data() {
      return {
        applicableOptions: ['hub', 'provider', 'default'],
        selectedApplicableOptions: 'default',
        externalProviders: [],
        hubs: []
      }
    },
    async mounted(){
      this.loadProviders()
      this.loadHubs()
      this.loadSelectedApplicableOptions(this.value)
    },
    watch:{
        value(input) {
           this.loadSelectedApplicableOptions(input)
        }
    },
    methods: {
        validate(){
            return this.$refs.form.validate()
        },
        loadProviders(){
            this.$api.get("/providers-external")
            .then(res => {
                this.externalProviders = res.data
                    .filter(p => p.role && (p.role.indexOf('EMSP') > -1 || p.role.indexOf('NSP') > -1))
                    .map(p => { 
                        return {
                            ...p,
                            text: p.role ? `${p.country_code}-${p.party_id} ${p.user.username} (${p.role})` : `${p.country_code}-${p.party_id} ${p.user.username}`, 
                            value: p.id
                        }
                    })
            })
            .catch(this.showError)
        },
        loadHubs(){
            this.$api.get("/hubs")
            .then(res => {
                return this.hubs = res.data.map(hub => { 
                        return {
                            value: hub.id,
                            text: hub.username
                        }
                    })
            })
            .catch(this.showError)
        },
        loadSelectedApplicableOptions(input){
            if (input.applicable_providers) {
                this.selectedApplicableOptions = 'provider'
            }
            
            if (input.applicable_user) {
                this.selectedApplicableOptions = 'hub'
            }
        }
    }
}
</script>