<template>
    <div>

        <v-row>
            <v-col md="3">
                <v-text-field v-model="dtm_session_id" label="DTM Session ID"></v-text-field>
            </v-col>
            <v-col md="1">
                <v-btn 
                    color="accent"
                    large
                    :loading="loading"
                    :disabled="loading || !dtm_session_id"
                    @click="send">Import
                </v-btn>
            </v-col>
            <v-col md="1">
                <v-btn
                v-if="json"
                outlined
                large
                color="accent"
                :loading="loading_cdr"
                :disabled="loading_cdr || processed_backend_uuids.includes(json.backend_uuid)"
                @click="() => generateCdr(json.backend_uuid)"
                >
                <v-icon left>mdi-credit-card-multiple-outline</v-icon> Generate CDR
                </v-btn>
            </v-col>
        </v-row>


        <json-viewer v-if="json"
            class="pt-6 px-4 text-left"
            :value="json"
            :expand-depth="10"
        >
        </json-viewer>

    </div>
</template>

<script>
export default {
    data() {
        return {
            dtm_session_id: null,
            json: null,
            processed_backend_uuids: [],
            loading: false,
            loading_cdr: false
        }
    },
    async mounted() {
    },
    methods: {
        async send() {
            this.loading = true
            this.json = await this.$api.post(`/tools/import-dtm-session`, { dtm_session_id: this.dtm_session_id })
                .then(this.showSuccess(`Session has been imported successfully`))
                .then(resp => resp.data)
                .catch(this.showError)
                .finally(() => this.loading = false)

        },
        generateCdr(backend_uuid){
            if(this.processed_backend_uuids.includes(backend_uuid)) return

            this.processed_backend_uuids.push(backend_uuid)
            if (!backend_uuid) {
            this.showError('Session has no backend_uuid')
            return 
            }

            this.loading_cdr = true
            this.$api.post(`generate-cdr/${backend_uuid}`)
            .then(() => this.showCustomAlert('success', 'Process has been executed.', { to:`/cdr-processes?search=${backend_uuid}`, text:'Check CDR process', icon:'mdi-arrow-top-right' }))
            .catch(this.showError)
            .finally(() => this.loading_cdr = false)
            
        }
    }
}
</script>