<template>
    <!-- Envía un START_SESSION a backend -->
    <v-card>
        <v-card-text>
            <p>Try a START_SESSION from this charge point.</p>

            <v-autocomplete class="mb-2"
                v-model="selectedProvider"
                :items="providers"
                :rules="[v => !!v]"
                :item-text="(item) => `(${item.country_code}-${item.party_id}) ${item.user ? item.user.username : ''}`"
                item-value="id"
                label="Send from"
                outlined
                hide-details
            ></v-autocomplete>

            <v-combobox class="mb-2"
                v-model="selectedToken"
                :disabled="loadingOcpiTokens"
                :loading="loadingOcpiTokens"
                :items="ocpiTokens"
                single-line
                label="Enter OCPI Token"
                return-object
                outlined
                hide-details
                :append-icon="null"
                dense
            >
            </v-combobox>

            <p class="mb-0">Choose an EVSE with which to start charging:</p>
            <v-radio-group class="my-0" 
                v-model="evseStartSession" 
                row 
                mandatory
                hide-details
            >
                <v-radio
                    v-if="e.connectors.length"
                    v-for="e of evses.filter(e => !e.deleted_at)"
                    class="mb-1"
                    :key="e.id"
                    :value="e"
                    color="accent"
                >
                    <template v-slot:label>
                        <div><v-chip small outlined><v-icon left x-small color="accent">mdi-power-plug</v-icon>{{ connectorId(e) }}</v-chip> {{ evseStandards(e) }}</div>
                    </template>
                </v-radio>
            </v-radio-group>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="evseCommand('START_SESSION')" text :loading="loadingStartSession">
                <v-icon left>mdi-play</v-icon>
                START
            </v-btn>
            <v-flex></v-flex>
            <v-btn @click="evseCommand('STOP_SESSION')" text :loading="loadingStopSession">
                <v-icon left>mdi-stop</v-icon>
                STOP
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import _ from "lodash"

export default {
    props: { 
        evses: {
            type: Array,
            required: true
        },
        providers: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            ocpiTokens: [],
            loadingOcpiTokens: false,
            loadingStartSession: false,
            loadingStopSession: false,
            evseStartSession: null,
            selectedProvider: null,
            selectedToken: null
        }
    },
    watch: {
        selectedProvider: _.debounce(function(newVal) {
            this.loadingOcpiTokens = true
            
            return this.$api.get(`provider/${newVal}/paginated-ocpi-tokens?offset=0&limit=100`)
                .then(resp => this.ocpiTokens = resp.data.map(ocpiToken => ocpiToken.uid))
                .then(() => this.loadingOcpiTokens = false)
        }, 500)
    },
    methods: {
        evseCommand(cmd){
            this[`loading${cmd}`] = true

            const data = {
                provider: this.selectedProvider,
                ocpiToken: this.selectedToken
            }

            this.$api.post(`evse/${this.evseStartSession.id}/${cmd}`, data)
                .then(resp => {
                    this.showSuccess(resp.data)
                })
                .catch(this.showError)
                .then(_ => { this[`loading${cmd}`] = false })
        },
        evseStandards(evse){
            return evse?.connectors?.map(o => o.standard).join(',')
        },
        connectorId(evse){
            return evse?.connectors[0]?.id
        }
    }
}
</script>