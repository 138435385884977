<template>
  <div>
      <!-- Table filters -->
      <v-row class="mb-2 px-2" align="center">
        <v-col cols="12" md="2">
          <v-text-field
                v-model="selectedSearch"
                clearable
                hide-details
                append-icon="mdi-magnify"
                placeholder="Search"
                color="accent"
                autofocus
                @keyup.enter="onFilter"
            >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="2" v-if="!providerId">
          <v-autocomplete
            v-model="selectedOwner"
            :items="owners"
            label="Owner"
            color="accent"
            clearable
            hide-details
          >
            <template v-slot:item="data">
                <ProviderSelectorTemplate :provider="data.item" />
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="2" v-if="!providerId">
          <v-autocomplete
            v-model="selectedApplicableProvider"
            :items="applicableProviders"
            label="Applicable Provider"
            color="accent"
            clearable
            hide-details
          >
            <template v-slot:item="data">
                <ProviderSelectorTemplate :provider="data.item" />
            </template>
          </v-autocomplete>
        </v-col>

        <!-- Checkbox to show tariffs with deleted_at -->
        <v-col cols="12" md="1">
          <v-checkbox
            v-model="showDeleted"
            label="Show deleted"
            color="accent"
            hide-details
          >
          </v-checkbox>
        </v-col>

        <v-btn 
            class="mt-5 mr-2"
            color="accent"
            :loading="loading"
            @click="onFilter"
        >
            <v-icon>mdi-filter</v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
            class="mt-5 mr-2" 
            :to="'/tariffs/create'"
            color="accent"
            outlined
        >
            <v-icon left>mdi-plus</v-icon>
            Create
        </v-btn>

        <div class="mt-5 mr-2" v-if="providerId">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on"
                :loading="sendAllToProvider.loading"
                outlined
                color="accent"
                @click="sendAllTariffs()"
              >
                <div><span class="text-overline mr-2">Send all</span><v-icon small>mdi-send</v-icon></div>
              </v-btn>
            </template>
            <span>Send all tariffs to provider</span>
          </v-tooltip>
        </div>

        <v-btn 
            class="mt-5 mr-2"
            color="accent"
            :loading="loadingCSV"
            outlined 
            @click="createCSV"
        >
            <v-icon left>mdi-file-download-outline</v-icon> CSV
        </v-btn>
      </v-row>

      <v-data-table
        class="accent-table rounded-table"
        :headers="[
          {value: 'action', sortable:false, cellClass: 'td-linkto', width: '5'},
          {text: 'Id.', value: 'id'},
          {text: 'Owner / Applicable to', value: 'owner_applicable_to'},
          {text: 'OCPI Id.', value: 'ocpi_id'},
          {text: 'Description', value: 'description'},
          {text: 'Prices', value: 'price_components'},
          {text: 'Updated at', value: 'updated_at'},
          {text: 'Deleted at', value: 'deleted_at'},
          {text: '', value: 'actions'}
        ]"
        :items="tariffs"
        :custom-filter="dataTableDeepSearch"
        :loading="loading"
        sort-by="id"
        sort-desc
        :footer-props="{ 'items-per-page-options': pagination.itemsPerPageOptions }"
        :items-per-page="25"
        dense
      >

        <template v-slot:item="{ item }">
          <tr>
            <!-- action -->
            <td class="pl-2 pr-0">
              <v-btn plain x-small :to="`/tariffs/${item.id}`">
                <v-icon small>mdi-arrow-top-right</v-icon>
              </v-btn>
            </td>
            <td>{{ item.id }}</td>
            <!-- owner_applicable_to -->
            <td>
              <!-- Owner -->
              <v-chip v-if="item.provider_id === 1" color="secondary" small><strong>wenea</strong></v-chip>
              <ProviderName
                v-else
                :provider="item.provider"
              />
              <!-- Arrow -->
              <v-icon color="secondary">mdi-arrow-right</v-icon>
              <!-- Apply to -->
              <v-chip v-if="item.provider_id !== 1" color="secondary" small><strong>wenea</strong></v-chip>
              <template v-else>
                <template v-if="item.applicable_providers">
                  <ProviderName
                    :key="applicable_provider" v-for="applicable_provider of item.applicable_providers"
                    class="ml-1"
                    :provider="providers.find(p => p.id == applicable_provider)"
                    extra="applicable_provider"
                  />
                </template>
                <template v-else-if="item.applicable_user">
                    <ProviderName
                      :provider="item"
                      extra="applicable_user"
                    />
                </template>
                <template v-else>
                    <span class="default-tariff text-uppercase">default<v-icon small right color="black">mdi-tag-outline</v-icon></span>
                </template>
              </template>
            </td>
            <td>{{ item.ocpi_id }}</td>
            <!-- description -->
            <td>
              <template v-if="item.tariff_alt_text && item.tariff_alt_text.a && item.tariff_alt_text.a.length > 0">
                {{ item.tariff_alt_text.a.find(text => text.language == 'EN') ? item.tariff_alt_text.a.find(text => text.language == 'EN').text : item.tariff_alt_text.a[0].text }}
              </template>
            </td>
            <!-- price_components -->
            <td>
                <v-row class="my-1" v-for="(element, index) in item.elements.a" :key="`price_component-${index}`">
                  <PriceComponentChip v-for="(price_component, index) in element.price_components" 
                    :key="index" 
                    class="ml-2" 
                    :priceComponent="price_component" 
                    :currency="item.currency" 
                    small 
                  />
                </v-row>
            </td>
            <!-- last_updated -->
            <td>{{ item.updated_at | dateFullFormat }}</td>
            <td class="ml-4 red--text font-weight-medium">
               {{ item.deleted_at | dateFormat }}
            </td>
            <!-- actions -->
            <td>
              <v-row>
                <v-spacer></v-spacer>
                <v-tooltip bottom v-if="item.provider.role != 'CPO'">
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on"
                        class="mr-2"
                        label="Provider"
                        color="accent"
                        text
                        depressed
                        small
                        @click.stop="sendTariff(item.id, item.applicable_providers, item.applicable_user)"
                      >
                        <v-icon small>mdi-send</v-icon>
                      </v-btn>
                  </template>
                  <span>Send tariff to provider</span>
                </v-tooltip>
              </v-row>
            </td>
          </tr>
        </template>
      </v-data-table>

      <ImportTariffsFromProvider :providerId="providerId" v-if="hasRole('CPO')" @data-imported="loadTariffs()" class="mt-2"></ImportTariffsFromProvider>
  </div>
</template>

<script>
import ImportTariffsFromProvider from "@/components/provider/ImportTariffsFromProvider"
import ProviderName from "@/components/provider/ProviderName"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"
import PriceComponentChip from "@/components/tariff/PriceComponentChip"

export default {
  name: "provider-tariffs",
  props: ["providerId","providerRole"],
  components: {
    ImportTariffsFromProvider,
    ProviderName,
    ProviderSelectorTemplate,
    PriceComponentChip
  },
  data() {
    return {
      tariffs: [],
      selectedSearch: null,
      selectedOwner: 1,
      selectedApplicableProvider: null,
      showDeleted: false,
      loading: false,
      pagination: {
          itemsPerPageOptions: [25, 50, 100, -1],
      },
      providers: [],
      externalProviders: [],
      sendAllToProvider: {
        loading: false,
        selectedProvider: null
      },
      loadingCSV: false
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    owners() {
      return [{ company: { name: 'Wenea' }, role:'CPO,EMSP', value: 1, text: 'Wenea (CPO,EMSP)' }]
        .concat(this.externalProviders)
    },
    applicableProviders() {
      return [{ company: { name: 'Default' }, value: 'default', text: 'Default' }, { company: { name: 'Wenea' }, role:'CPO,EMSP', value: 'wenea', text: 'Wenea (CPO,EMSP)' }]
        .concat(this.externalProviders)
    }
  },
  watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },
    },
  methods: {
    load() {
        this.$api.get("/providers")
          .then(res => this.providers = res.data)
          .catch(this.showError)

        this.$api.get("/providers-external")
          .then(res => {
            this.externalProviders = res.data
              .map(p => { 
                  return {
                        ...p, 
                        text: p.role ? `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username} (${p.role})` : `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username}`, 
                        value: p.id 
                  }
              })
          })
          .catch(this.showError)
      },
      async loadTariffs(){
        this.loading = true

          let apiURL = `/tariffs${this.providerId ? `/applicable_provider/${this.providerId}` : ''}`
              apiURL += `?role=${this.providerRole}`
              this.selectedOwner ? apiURL += `&owner=${this.selectedOwner}` : null

          await this.$api.get(apiURL)
              .then(res => {
                  if(Array.isArray(res.data)) {
                      this.tariffs = res.data
                  }
              })
              .catch(this.showError)
              .finally(() => { this.loading = false })
      },
      async onFilter(){
        if(this.loading) return
        this.urlPush('search', this.selectedSearch)
        this.urlPush('owner', this.selectedOwner)

        await this.loadTariffs()

        if(this.selectedSearch){
            this.tariffs = this.tariffs.filter(item => {
                return item.ocpi_id.toLowerCase().includes(this.selectedSearch.toLowerCase())
            })
        }

        if(this.selectedApplicableProvider) {
            switch(this.selectedApplicableProvider){
                case 'wenea':
                    this.tariffs = this.tariffs.filter(tariff => tariff.provider_id != 1)
                    break;
                case 'default':
                    this.tariffs = this.tariffs.filter(tariff => tariff.applicable_providers == null && tariff.applicable_user == null && tariff.provider_id == 1)
                    break;
                default:
                    this.tariffs = this.tariffs.filter(tariff => tariff.applicable_providers?.includes(this.selectedApplicableProvider))
                    break;
            }
        }

        if(!this.showDeleted) {
            this.tariffs = this.tariffs.filter(tariff => tariff.deleted_at == null)
        }
        
      },
      sendTariff(tariffId){
        const data = {}
        if (this.providerId) {
          data.provider_id = this.providerId
        }

        this.$api.put(`send-tariffs/${tariffId}`, data)
          .then(() => this.showSuccess('Tariff has been sent successfully'))
          .catch(this.showError)
      },
      sendAllTariffs(){
          this.sendAllToProvider.loading = true

          this.$api.put(`emsp/providers/${this.providerId}/send-tariffs`)
            .then(() => this.showSuccess('Tariffs has been sent successfully'))
            .catch(this.showError)
            .then(() => this.sendAllToProvider.loading = false )
      },
      hasRole(role){
        return this.providerRole && this.providerRole.includes(role)
      },
      async createCSV(){
          this.loadingCSV = true

          let content = '"Id";"OCPI Id";"Owner";"Applicable to";"Currency";"Description";"Price components";"Updated at";"Deleted at";\r\n'
          
          for( const row of this.tariffs ){

            const owner = row.provider_id === 1 ? 'Wenea' :  `${ row.provider.country_code }-${ row.provider.party_id }`

            let applicable_to = row.provider_id === 1 ? 'DEFAULT' : 'Wenea'
            if (row.applicable_providers) { 
              applicable_to = this.providers
                .filter( p => row.applicable_providers.includes(p.id) )
                .map(p => p.country_code + '-' + p.party_id)
            }
            if (row.applicable_user) { applicable_to = row.username }

            let description = ""
            if (row.tariff_alt_text && row.tariff_alt_text.a && row.tariff_alt_text.a.length > 0) {
              description = row.tariff_alt_text.a?.find(text => text.language == 'EN') ? row.tariff_alt_text.a?.find(text => text.language == 'EN').text : row.tariff_alt_text?.a[0].text
            }

            let price_components = ""
            for (const element of row?.elements?.a) {
              for (const price_component of element?.price_components) {
                if (price_components != "") { price_components += " | " }
                
                if(price_component.type=='ENERGY') {
                  price_components += `${price_component.price } ${ row.currency } x ${ price_component.step_size } kWh ${ price_component.vat ? `(VAT ${price_component.vat}%)` : ''}`
                }
                
                if(price_component.type=='FLAT'){
                  price_components += `${ price_component.price } ${ row.currency } ${ price_component.vat ? `(VAT ${price_component.vat}%)` : ''}`
                }
                
                if(price_component.type=='PARKING_TIME') {
                  price_components += `${ price_component.price } ${ row.currency } x ${ price_component.step_size } parking seconds ${ price_component.vat ? `(VAT ${price_component.vat}%)` : ''}`
                }

                if(price_component.type=='TIME') {
                  price_components += `${ price_component.price } ${ row.currency } x ${ price_component.step_size } parking seconds ${ price_component.vat ? `(VAT ${price_component.vat}%)` : ''}`
                }
              }
            }

            content += `"${row.id}";"${row.ocpi_id}";"${owner}";"${applicable_to}";"${row.currency}";"${description}";"${price_components}";"${row.updated_at}";"${row.deleted_at || ''}";\r\n`
          }

          this.downloadBlob(content, `tariffs.csv`, "text/csv")
          this.loadingCSV = false
      }
  },
};
</script>

<style scoped>
  >>> .v-data-table > .v-data-table__wrapper tbody tr {
    box-shadow: none;
    background-color: transparent !important;
  }

  >>> .default-tariff {
    font-size: 0.85rem; 
    font-weight: 500;
  }
</style>