<template>
  <div>
    <v-toolbar flat>
      <v-spacer></v-spacer>
      <div class="pt-4">
        <v-menu
          ref="menu"
          v-model="showDateFromPicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          hide-details
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="selectedDateFrom"
                    label="Date from"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="selectedDateFrom"
                @input="showDateFromPicker = false"
                no-title
            ></v-date-picker>
        </v-menu>
      </div>

      <div class="ml-4 pt-4">
        <v-menu
            ref="menu"
            v-model="showDateToPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            hide-details
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="selectedDateTo"
                    label="Date to"
                    prepend-icon="mdi-calendar"
                    readonly
                    clearable
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="selectedDateTo"
                @input="showDateToPicker = false"
                no-title
            ></v-date-picker>
        </v-menu>
        </div>

        <v-btn 
            class="mr-2 ml-4"
            color="accent"
            :loading="loading"
            @click="onFilter"
        >
            <v-icon>mdi-filter</v-icon>
        </v-btn>

        <div class="ml-4">
            <v-btn 
                class="mr-2"
                color="accent"
                :loading="loadingCSV"
                outlined 
                @click="createCSV"
            >
                <v-icon left>mdi-file-download-outline</v-icon> CSV
            </v-btn>
        </div>
      
    </v-toolbar>

    <v-divider />

    <v-data-table
        :headers="[
            { text: 'Id.', value: 'id' },
            { text: 'Ocpi id', value: 'ocpi_id' },
            { text: 'Start Date', value: 'start_date_time' },
            { text: 'End Date', value: 'end_date_time' },
            { text: 'Total cost', value: 'total_cost' },
            { text: 'Total energy', value: 'total_energy' },
            { text: 'Time', value: 'total_time' },
            { text: 'Last updated', value: 'last_updated' },
        ]"
        :items="cdrs"
        disable-sort
        class="accent-table rounded-table"
        @update:options="this.updateTableOptions"    
    >
        <template v-slot:item.start_date_time="{ item }">
          {{ item.start_date_time | dateFullFormat }}
        </template>
        <template v-slot:item.end_date_time="{ item }">
          {{ item.end_date_time | dateFullFormat }}
        </template>
        <template v-slot:item.total_time="{ item }">
          {{ item.total_time | hoursToTimeTextFormat }}
        </template>
        <template v-slot:item.total_cost="{ item }">
          <template v-if="item.total_cost">{{  item.total_cost.excl_vat }} {{ item.currency }}</template>
          <template v-else>-</template>
        </template>
        <template v-slot:item.last_updated="{ item }">
          {{ item.last_updated | dateFullFormat }}
        </template>
    </v-data-table>

    <ImportCdrsFromProvider :provider-id="providerId" v-if="hasRole('CPO')" @data-imported="getCdrs()" class="mt-2"></ImportCdrsFromProvider>
  </div>
</template>
<script>
import ImportCdrsFromProvider from "@/components/provider/ImportCdrsFromProvider";

export default {
  name: "provider-cdrs",
  props: ["providerId","providerRole"],
  components: {
    ImportCdrsFromProvider
  },
  data() {
    return {
      cdrs: [],
      selectedDateFrom: null,
      showDateFromPicker: false,
      selectedDateTo: null,
      showDateToPicker: false,

      loadingCSV: false,
        loading: false,
        pagination: {
            itemsPerPageOptions: [10, 20, 30, 50, 100],
            serverItemsLength: 0
        },
        tableOptions: {
            itemsPerPage: 25,
            page: 1,
        },      
        initialLoad: true,
    };
  },
  watch: {
    //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
    '$route.query':{ 
        handler(params) {
            this.routeFilters(params)
            this.onFilter()
        },
        immediate: true
    },
  },
  methods: {
    async loadCdrs() {
      this.loading = true
            await this.getCdrs(true)
                .then(resp => {
                    this.pagination.serverItemsLength = Number(resp.headers['x-total-count'])
                    this.cdrs = resp.data
                })
                .catch(this.showError)
                .finally(() => this.loading = false)
    },
    hasRole(role){
        return this.providerRole && this.providerRole.includes(role)
    },
    async getCdrs(paginatedResponse){
            let queryParams = ''
            if(paginatedResponse) { 
                queryParams += `offset=${(this.tableOptions.itemsPerPage * this.tableOptions.page) - this.tableOptions.itemsPerPage}` 
                if (this.tableOptions.itemsPerPage > 0) { queryParams += `&limit=${this.tableOptions.itemsPerPage}` }
            }

            return await this.$api
                .get(`provider/${this.providerId}/cdrs${this.hasRole('CPO') ? `?role=CPO`:'?role=EMSP'}&${queryParams}`)
                .then( resp => {return resp} )
                .catch(this.showError)
    },
    async onFilter(){
        if(this.loading) return
        this.urlPush('dateFrom', this.selectedDateFrom)
        this.urlPush('dateTo', this.selectedDateTo)

        await this.loadCdrs()

        const dateFormater = this.$options.filters.dateFormat
        if (this.selectedDateFrom) {
            this.cdrs = this.cdrs.filter(cdr => dateFormater(cdr.last_updated) >= dateFormater(this.selectedDateFrom))
        }

        if (this.selectedDateTo) {
            this.cdrs = this.cdrs.filter(cdr => dateFormater(cdr.last_updated) <= dateFormater(this.selectedDateTo))
        }
    },
    updateTableOptions(args){
        this.tableOptions = args
        if(!this.initialLoad){
            this.loadCdrs()
        }
    },  
    async createCSV(){
            this.loadingCSV = true
            const cdrs = await this.getCdrs(false)
                .then(resp => resp.data)
                .catch(this.showError)
                .finally(() => this.loadingCSV = false)
            
            
            let content = '"Id";"OCPI Id";"Start Date";"End Date";"Total cost";"Total energy";"Time";"Last updated"\r\n'
            
            cdrs.forEach(cdr => {
                content += `"${cdr.id}";"${cdr.ocpi_id}";"${cdr.start_date_time}";"${cdr.end_date_time}";"${cdr.total_cost}";"${cdr.total_energy}";"${cdr.total_time}";"${cdr.last_updated}"\r\n`
            })

            this.downloadBlob(content, `cdrs.csv`, "text/csv")
            this.tableLoading = false
    }
  }
};
</script>