<template>
    <div>
        <v-data-table :headers="[
            { text: '', value: 'icon' },
            { text: 'Id.', value: 'id' },
            { text: 'OCPI Id.', value: 'ocpi_id' },
            { text: 'Standard', value: 'standard', width: '200' },
            { text: 'Format', value: 'format', width: '150' },
            { text: 'Power type', value: 'power_type', width: '150' },
            { text: 'Max power', value: 'max_electric_power' },
            { text: 'Max voltage', value: 'max_voltage' },
            { text: 'Max amperage', value: 'max_amperage' },
            { text: 'Num.', value: 'number' },
            { text: 'Core Id.', value: 'backend_id' },
            { text: 'Updated', value: 'last_updated' },
            { text: 'Deleted', value: 'deleted_at' }
        ]" :items="connectors" disable-sort
            disable-pagination hide-default-footer hide-default-header dense>
            <template v-slot:item.icon="{ item }">
                <v-row class="ml-7">
                    <v-icon color="accent">mdi-power-plug</v-icon>
                </v-row>
            </template>
            <template v-slot:item.id="{ item }">
                <div class="text-caption">Id: </div>
                <div>{{ item.id }}</div>
            </template>
            <template v-slot:item.ocpi_id="{ item }">
                <div class="text-caption">OCPI Id: </div>
                <div>{{ item.ocpi_id }}</div>
            </template>
            <template v-slot:item.number="{ item }">
                <div class="text-caption">Label:</div>
                <div>{{ item.label }}</div>
            </template>
            <template v-slot:item.standard="{ item }">
                <div class="text-caption">Standard: </div>
                <div>{{ item.standard }}</div>
            </template>
            <template v-slot:item.format="{ item }">
                <div class="text-caption">Format: </div>
                <div>{{ item.format }}</div>
            </template>
            <template v-slot:item.power_type="{ item }">
                <div class="text-caption">Power type: </div>
                <div>{{ item.power_type }}</div>
            </template>
            <template v-slot:item.max_electric_power="{ item }">
                <div class="text-caption">Max power: </div>
                <div>{{ parseFloat(item.max_electric_power / 1000).toFixed(1) }}kW</div>
            </template>
            <template v-slot:item.max_voltage="{ item }">
                <div class="text-caption">Max voltage: </div>
                <div>{{ item.max_voltage }}</div>
            </template>
            <template v-slot:item.max_amperage="{ item }">
                <div class="text-caption">Max amperage: </div>
                <div>{{ item.max_amperage }}</div>
            </template>
            <template v-slot:item.backend_id="{ item }">
                <div class="text-caption">Core Id: </div>
                <div>{{ item.backend_id }}</div>
            </template>
            <template v-slot:item.last_updated="{ item }">
                <div class="text-caption">Last updated: </div>
                <div>{{ item.last_updated | dateFullFormat }}</div>
            </template>
            <template v-slot:item.deleted_at="{ item }">
                <v-col  v-if="item.deleted_at">
                    <v-row>
                        <div class="text-caption">Deleted: </div>
                    </v-row>
                    <v-row>
                        <div>{{ item.deleted_at | dateFullFormat }}</div>
                        <v-chip class="ml-4" outlined color="error" x-small>REMOVED</v-chip>
                    </v-row>
                </v-col>
            </template>
        </v-data-table>
    </div>
</template>
<script>

export default {
    props: {
        connectors: {
            type: Array,
            required: true
        }
    }
}
</script>