import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"title":"Alerts","icon":"mdi-bell-outline"}}),_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"headers":[
            {text: 'Message', value: 'message'},
            {text: 'Provider', value: 'provider_id'},
            {text: 'Content', value: 'content'},
            {text: 'Created at', value: 'created_at'},
            { value: 'actions' }
        ],"items":_vm.alerts,"loading":_vm.loading,"sort-by":"created_at","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.provider_id",fn:function(ref){
        var item = ref.item;
return [(item.provider_id)?_c('ProviderName',{attrs:{"provider":_vm.providers.find(function (p) { return p.id == item.provider_id; })}}):_vm._e()]}},{key:"item.content",fn:function(ref){
        var item = ref.item;
return [(item.content)?_c('json-viewer',{staticClass:"pa-2 text-left",attrs:{"value":item.content,"expand-depth":0}}):_vm._e()]}},{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("adonisDateFullFormat")(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c(VBtn,{attrs:{"icon":"","color":"red","small":""},on:{"click":function($event){return _vm.deleteAlert(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }