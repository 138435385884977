import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"scrollable":"","max-width":_vm.maxWidth},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"pa-0"},[_c(VToolbar,{attrs:{"color":"accent lighten-1 white--text elevation-0"}},[_c(VToolbarTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))])],1)],1),_c(VCardText,[_c(VRow,[(_vm.json)?_c(VCol,[_c('json-viewer',{staticClass:"pt-6 px-4 text-left",attrs:{"value":_vm.json,"expand-depth":_vm.expand}})],1):_vm._e(),(_vm.json2)?_c(VCol,[(_vm.json2.error)?_c(VChip,{staticClass:"mt-5",attrs:{"label":""}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-information-outline")]),_vm._v(" "+_vm._s(_vm.json2.error)+" ")],1):_c('json-viewer',{staticClass:"pt-6 px-4 text-left",attrs:{"value":_vm.json2,"expand-depth":_vm.expand}})],1):_vm._e()],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"depressed":"","text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }