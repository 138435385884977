import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"outlined":""}},[_c(VToolbar,{attrs:{"dark":"","dense":"","color":"accent","elevation":"0"}},[_c(VAppBarNavIcon,[_c(VIcon,[_vm._v(_vm._s(_vm.incoming ? 'mdi-phone-incoming' : 'mdi-phone-outgoing'))])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.title))])],1),_c(VList,{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((_vm.http_items),function(item,index){return _c(VListGroup,{key:item.timestamp,attrs:{"no-action":"","active-class":"grey lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,{attrs:{"color":"grey"}},[_c('span',[_vm._v(_vm._s(_vm._f("dateTimeFormat")(item.timestamp)))]),_c('span',{staticClass:"pl-4"},[_vm._v(_vm._s(((item.request.method) + " " + (_vm.truncateUrl(item.request.url, 32)))))])])],1)]},proxy:true}],null,true)},[_c('HttpCallInfo',{attrs:{"http_info":item}}),(index != (_vm.http_items.length -1))?_c(VDivider):_vm._e()],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }