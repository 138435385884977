import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"title":"Sessions by Provider","icon":"mdi-account-details"}}),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c(VCard,{attrs:{"height":"100%","loading":_vm.loading}},[_c('template',{slot:"progress"},[_c(VProgressLinear,{attrs:{"color":"accent","indeterminate":""}})],1),_c(VCardTitle,[_c(VIcon,{attrs:{"color":"accent","left":""}},[_vm._v("mdi-chart-timeline-variant")]),_c('h3',{staticClass:"black--text"},[_vm._v(" Sessions per provider ")])],1),_c(VRow,{staticClass:"pl-6 py-2",attrs:{"align":"center"}},[_c(VBtn,{attrs:{"text":"","color":"accent"},on:{"click":_vm.setFilters}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-filter")]),_vm._v("Filters ")],1),_c('span',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(_vm.filterText)+" ")])],1),_c('SessionsPerProviderSplineGraph',{staticClass:"ma-2",attrs:{"sessions":_vm.sessionsPerDay,"colorPerProvider":_vm.colorPerProvider}})],2)],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VCard,{attrs:{"height":"100%","loading":_vm.loading}},[_c('template',{slot:"progress"},[_c(VProgressLinear,{attrs:{"color":"accent","indeterminate":""}})],1),_c(VCardTitle,[_c(VIcon,{attrs:{"color":"accent","left":""}},[_vm._v("mdi-chart-pie")]),_c('h3',{staticClass:"black--text"},[_vm._v(" Origin ")])],1),_c('SessionsPerProviderPieGraph',{attrs:{"sessions":_vm.sessions,"colorPerProvider":_vm.colorPerProvider}})],2)],1)],1),_c('FiltersDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }