import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"group-by":_vm.groupBy,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"sort-by":['charge_point_id', 'connector_id'],"hide-default-footer":_vm.hideDefaultFooter,"hide-default-header":"","no-data-text":"No collided tariffs found","dense":""},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [(!!group)?[_c('td',{class:("" + (_vm.lightTheme ? 'light-theme' : '')),attrs:{"colspan":headers.length},on:{"click":function($event){$event.preventDefault();return _vm.goCtrlClick($event,group,"chargepoint")}}},[_c(VBtn,{ref:group,attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return toggle.apply(null, arguments)}}},[(isOpen)?_c(VIcon,[_vm._v("mdi-minus")]):_c(VIcon,[_vm._v("mdi-plus")])],1),_c(VIcon,{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("mdi-ev-station")]),_c('span',[_vm._v(_vm._s(_vm.items.find(function (item) { return item.charge_point_id == group; }).charge_point_name))])],1)]:_vm._e()]}},{key:"item.charge_point_id",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"small":"","text":"","min-width":"80","to":("/chargepoint/" + (item.charge_point_id))}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-ev-station")]),_vm._v(_vm._s(_vm.items.find(function (it) { return it.charge_point_id == item.charge_point_id; }).charge_point_name)+" ")],1)]}},{key:"item.connector_id",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"connector-chip",attrs:{"outlined":"","small":"","to":("/chargepoint/" + (item.charge_point_id))}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-power-plug")]),_vm._v(_vm._s(item.connector_id)+" ")],1)]}},{key:"item.provider_name",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"small":"","text":"","min-width":"80","to":("/provider/" + (item.applicable_provider))}},[_vm._v(" "+_vm._s(item.provider_name)+" ")])]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row"},[_c('span',{staticClass:"text-caption mr-2",staticStyle:{"line-height":"24px","vertical-align":"middle"}},[_vm._v("Priority: ")]),_c('span',{staticStyle:{"line-height":"24px","vertical-align":"middle"}},[_vm._v(_vm._s(item.priority))]),_c('PriorityIcon',{attrs:{"priority":item.priority,"max":9,"min":1}})],1)]}},{key:"item.collided_tariffs",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-caption"},[_vm._v("Collided tariffs:")]),_vm._l((item.collided_tariffs),function(tariff_id){return _c(VChip,{key:tariff_id,staticClass:"tariff-chip ml-2",attrs:{"color":"accent","small":"","outlined":"","to":("/tariffs/" + tariff_id)}},[_vm._v(" "+_vm._s(tariff_id)+" ")])})]}}])},[_c('template',{slot:"progress"},[_c(VProgressLinear,{attrs:{"color":"accent","indeterminate":""}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }