import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"breadcrumbs":[
              { text: 'Billing tools', to: '/tools', icon: 'mdi-tools' },
              { text: 'Provider connectors without tariffs' } ]}}),_c(VDataIterator,{attrs:{"items":_vm.connGroupedByLocFiltered,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"hide-default-footer":""},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c(VToolbar,{staticClass:"mb-2",attrs:{"tile":false,"color":"accent","dark":"","flat":"","rounded":"","extension-height":"0"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [(_vm.loading)?_c(VProgressLinear,{staticClass:"ma-0",attrs:{"color":"accent","height":"5","indeterminate":""}}):_vm._e()]},proxy:true}])},[_c(VTextField,{attrs:{"clearable":"","flat":"","solo-inverted":"","hide-details":"","prepend-inner-icon":"mdi-magnify","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"default",fn:function(props){return [_c(VRow,_vm._l((props.items),function(row){return _c(VCol,{key:row[0].location_id,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c(VCard,[_c(VCardTitle,{staticClass:"subheading font-weight-bold pointer",on:{"click":function($event){$event.preventDefault();return _vm.goCtrlClick($event,row[0].location_id,"location")}}},[_vm._v(" "+_vm._s(row[0].location_name)+" ")]),_c(VDivider),_c(VDataTable,{attrs:{"headers":[
                {text: 'connector', value: 'connector' }
              ],"items":row,"group-by":"charge_point_id","show-group-by":"","hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
              var group = ref.group;
              var headers = ref.headers;
return [_c('td',{staticClass:"pointer",attrs:{"colspan":headers.length},on:{"click":function($event){$event.preventDefault();return _vm.goCtrlClick($event,group,"chargepoint")}}},[_c(VIcon,[_vm._v("mdi-ev-station")]),_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v(_vm._s(row.find(function (conn) { return conn.charge_point_id==group; }).charge_point_name))])],1)]}},{key:"item.connector",fn:function(ref){
              var item = ref.item;
return [_c(VRow,{staticClass:"ml-0"},[_c(VIcon,[_vm._v("mdi-power-plug")]),_c(VChip,{attrs:{"small":""}},[_vm._v(_vm._s(item.connector_id))]),(item.connector_power_type)?_c('span',{staticClass:"text-caption ml-2 pt-1"},[_vm._v(_vm._s(item.connector_power_type))]):_vm._e()],1)]}}],null,true)})],1)],1)}),1)]}},{key:"footer",fn:function(){return [_c(VRow,{staticClass:"mt-2 mx-0",attrs:{"align":"center","justify":"center"}},[_c(VSpacer),_c('span',{staticClass:"grey--text"},[_vm._v("Items per page")]),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-1",attrs:{"dark":"","text":"","color":"accent"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.itemsPerPage)+" "),_c(VIcon,[_vm._v("mdi-chevron-down")])],1)]}}])},[_c(VList,_vm._l((_vm.itemsPerPageArray),function(number,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.updateItemsPerPage(number)}}},[_c(VListItemTitle,[_vm._v(_vm._s(number))])],1)}),1)],1),_c('span',{staticClass:"mx-4 grey--text"},[_vm._v(" "+_vm._s(_vm.offset)+" - "+_vm._s(_vm.limit)+" of "+_vm._s(_vm.connGroupedByLocFiltered.length)+" ")]),_c(VBtn,{staticClass:"mr-1",attrs:{"icon":"","large":"","color":"accent","disabled":!_vm.hasPreviousPage},on:{"click":_vm.formerPage}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VBtn,{staticClass:"ml-1",attrs:{"icon":"","large":"","color":"accent","disabled":!_vm.hasNextPage},on:{"click":_vm.nextPage}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }