<template>
    <v-dialog v-model="show" persistent max-width="1180px">
        <v-card>
            <v-card-title class="accent white--text">
                <span class="headline">Not sent Tokens</span>
            </v-card-title>
            <v-toolbar flat>
            <v-spacer></v-spacer>
            <div>
                <v-text-field
                v-model="tableSearch"
                clearable
                hide-details
                append-icon="mdi-magnify"
                placeholder="Search"
                class="mr-8"
                >
                </v-text-field>
            </div>
            <v-btn 
                text
                small
                @click="sendAllTokens()">
                Send all
                <v-icon right>mdi-send</v-icon>
            </v-btn>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pa-4">
                    <v-data-table
                        :headers="[
                            {text: 'Id.', value: 'id'},
                            {text: 'UID', value: 'uid'},
                            {text: 'Valid', value: 'valid' },
                            {text: 'Type', value: 'type'},
                            {text: 'Whitelist', value: 'whitelist' },
                            {text: 'Country', value: 'country_code'},
                            {text: 'Party', value: 'party_id'},
                            {text: 'Contract Id.', value: 'contract_id' },
                            {text: 'Actions', value: 'actions' }
                        ]"
                        :items="tokens"
                        :search="tableSearch"
                        :loading="loading"                      
                        disable-sort
                        dense
                        >
                        <template v-slot:item.actions="{ item }">
                            <v-btn
                            class="mr-2"
                            color="accent"
                            depressed
                            small
                            @click="sendToken(item)"
                            >Send</v-btn>
                        </template>
                        </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="show = false" depressed>Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { EventBus } from "@/event-bus"

export default {
    data() {
        return {
            show: false,
            loading: false,
            tokens: [],
            providerId: null,
            tableSearch: ""
        }
    },
    watch: {
        show( show ) {
            if ( !show ) {
                this.tokens = []
                this.providerId = null
                this.loading = false
                EventBus.$emit("dialog-not-sent-locations:close")
            }
        }
    },
    created() {
        var vm = this
        EventBus.$on("dialog-not-sent-locations:open", async function( args ) {
            
            if( !args.tokens ){
                vm.showError("Data error, no locations")
                return false
            }

            if( !args.providerId ){
                vm.showError("Data error, no providerId")
                return false
            }

            vm.providerId = args.providerId
            vm.tokens = args.tokens
            vm.show = true

        })
    },
    methods: {
        sendToken(token){
        
            this.loading = true

            this.$api
                .put(`cpo/providers/${this.providerId}/tokens/${token.uid}`)
                .then(resp => {
                    this.tokens = this.tokens.filter(input => input.id !== token.id)
                    this.showSuccess(`Token has been sent`)
                })
                .catch(this.showError)
                .then((_) => { this.loading = false })
        },
        sendAllTokens(){

            this.loading = true

            const data = {
                token_ids: this.tokens.map(token => token.id)
            }

            this.$api
                .put(`cpo/providers/${this.providerId}/tokens`, data)
                .then(resp => {
                    this.tokens = this.tokens.filter(input => !resp.data.sent.includes(input.id))
                    if (resp.data.allSuccess){
                        this.showSuccess('Tokens have been sent')
                    } else {
                        this.showError('There was an error sending any of Tokens')
                    }
                })
                .catch(this.showError)
                .then((_) => { this.loading = false })
        }
    }
}
</script>