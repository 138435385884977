<template>
    <v-container fluid>

        <AppBar icon="mdi-map-marker" :breadcrumbs="[
            { text: 'Locations', to: '/locations'},
            { text: locationInfo.name, to: `/location/${locationInfo.id}`, icon: 'mdi-map-marker' },
            { text: cpName, icon: 'mdi-ev-station'}
        ]"/>

        <v-card v-if="chargepoint" :loading="loading" outlined>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>
                            <ChargepointName
                                :chargepoint="{ name: chargepoint.reference_name }"
                                extra="no-arrow"
                            />    
                            </v-list-item-title>
                            <v-list-item-subtitle>Name</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{ chargepoint.backend_id || '-' }}</v-list-item-title>
                            <v-list-item-subtitle>Core Id.</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{chargepoint.number}}</v-list-item-title>
                            <v-list-item-subtitle>Number</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" sm="2">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{chargepoint.id}}</v-list-item-title>
                            <v-list-item-subtitle>Id.</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
            <v-divider />

            <v-row v-if="location">
                <v-col cols="12" sm="5">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>
                                <LocationName
                                    v-if="location"
                                    :location="{ id: location.id, name: location.name }"
                                />
                            </v-list-item-title>
                            <v-list-item-subtitle>Location</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>
                                <ProviderName
                                    :provider="provider"
                                />
                            </v-list-item-title>
                            <v-list-item-subtitle>Provider</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" sm="3">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{ `${this.provider.role}` }}</v-list-item-title>
                            <v-list-item-subtitle>Roles</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>

        </v-card>

        <h2 class="mt-4 mb-4"><v-icon left color="accent">mdi-wrench</v-icon> Operations</h2>
        <v-row>
            <!-- Envía un START_SESSION a backend -->
            <v-col cols="4" v-if="provider && provider.id === 1">
                <WeneaCpSessionTool :evses="evses" :providers="emspProviders"/>
            </v-col>
            <!-- Envía un START_SESSION al Provider externo -->
            <v-col cols="4" v-if="provider && provider.id !== 1">
                <v-card class="fill-height">
                    <v-card-text>
                        <p>Send a START_SESSION or STOP_SESSION command to the Provider</p>

                        <v-autocomplete
                            v-model="evseStartSession"
                            v-if="evses.length"
                            :items="evses"
                            label="Evse"
                            clearable
                            outlined
                            dense
                            auto-select-first
                            return-object
                        >
                            <template v-slot:item="data">
                                <template>
                                    <v-chip color="primary" small>{{ data.item.id }}</v-chip>
                                    <span v-if="data.item.evse_id" class="px-2">evse_id: {{ data.item.evse_id }}</span>
                                    <span v-if="data.item.uid" class="px-2">UID: {{ data.item.uid }}</span>
                                    <v-chip small v-if="data.item.connectors && data.item.connectors.length > 1">{{ data.item.connectors.length }} connectors</v-chip>
                                </template>
                            </template>
                        </v-autocomplete>

                        <v-autocomplete
                            v-model="connectorStartSession"
                            v-if="connectors.length"
                            :items="connectors"
                            label="Connector"
                            clearable
                            outlined
                            dense
                            auto-select-first
                            return-object
                        >
                            <template v-slot:item="data">
                                <template>
                                    <v-chip color="primary" small>{{ data.item.id }}</v-chip>
                                    <span v-if="data.item.standard" class="px-2">{{ data.item.standard }} / {{ data.item.power_type }}</span>
                                </template>
                            </template>
                        </v-autocomplete>


                        <v-combobox class="mb-2"
                            v-model="selectedToken"
                            :disabled="loadingOcpiTokens"
                            :loading="loadingOcpiTokens"
                            :items="ocpiTokens"
                            single-line
                            label="Enter OCPI Token"
                            return-object
                            outlined
                            hide-details
                            :append-icon="null"
                            dense
                        >
                        </v-combobox>

             
                    </v-card-text>  
                    <v-card-actions>
                        <v-btn @click="externalStartSession" text block :loading="loadingStartSession" color="accent">
                            <v-icon left>mdi-play</v-icon>
                            START SESSION
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <!-- Envíar CP del Provider a Backend -->
            <v-col cols="4" v-if="provider && provider.id != 1">
                <v-card class="fill-height">
                    <v-card-text>
                        <p>Send Chargepoint information (Evses and its Connectors) to Backend. Evses with 'REMOVED' status will be set as 'isRemoved' in Backend</p>
                    </v-card-text>
                    
                    <v-card-actions>
                        <v-btn @click="sendChargepointToBackend" text block :loading="loadingSendChargepointToBackend" color="accent">
                            <v-icon left>mdi-upload</v-icon>
                            Sync with Backend
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <!-- Soft Delete CP -->
            <v-col cols="4" v-if="chargepoint && provider.id !== 1 && !chargepoint.deleted_at">
                <v-card class="fill-height">
                    <v-card-text>
                        <p>Delete all Evses and Connectors from this Chargepoint. This will NOT perform actions to Backend</p>
                    </v-card-text>
                    
                    <v-card-actions>
                        <v-btn @click="deleteChargepoint" text block :loading="loadingDeleteChargepoint" color="accent">
                            <v-icon left>mdi-upload</v-icon>
                            Delete Chargepoint
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <!-- UnDelete CP-->
            <v-col cols="4" v-if="chargepoint && provider.id !== 1 && chargepoint.deleted_at">
                <v-card class="fill-height">
                    <v-card-text>
                        <p>UnDelete all Evses and Connectors from this Chargepoint where status is NOT 'REMOVED'. This will not perform actions to Backend.</p>
                    </v-card-text>
                    
                    <v-card-actions>
                        <v-btn @click="unDeleteChargepoint" text block :loading="loadingUnDeleteChargepoint" color="accent">
                            <v-icon left>mdi-upload</v-icon>
                            UnDelete Chargepoint
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        
        <h2 class="mt-6 mb-3">
            <v-icon large left color="accent">mdi-car-multiple</v-icon> Evses
        </h2>
        <v-divider />
        <v-data-table
            class="evse-table accent-table rounded-table"
            :headers="[
                { text: '', value: 'data-table-expand', width: '82px'},
                { text: 'Id.', value: 'id'},
                { text: 'Status', value: 'status'},
                { text: 'Evse Id.', value: 'evse_id' },
                { text: 'UID', value: 'uid' },
                { text: 'Updated', value: 'last_updated'},
                { text: 'Deleted', value: 'deleted_at'},
                { text: 'Actions', value: 'actions'}
            ]"
            :items="evses"
            :expanded="evses"
            :loading="loading"
            disable-sort
            disable-pagination
            hide-default-footer
            show-expand
        >
            <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
                <v-row class="d-flex">
                    <v-btn @click="expand(!isExpanded)" icon>
                        <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
                        <v-icon v-else>mdi-chevron-down</v-icon>
                    </v-btn>

                    <v-btn @click="showDialog(item)" icon>
                        <v-icon small>mdi-magnify</v-icon>
                    </v-btn>
                </v-row>
            </template>
            <template v-slot:item.last_updated="{ item }">
                {{ item.last_updated | dateFullFormat }}
            </template>
            <template v-slot:item.deleted_at="{ item }">
                {{ item.deleted_at | dateFullFormat }}
                <v-chip v-if="item.deleted_at" class="ml-4" outlined color="error" small>REMOVED</v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
                <DropDownButton  v-if="provider && provider.id === 1"
                    v-model="sendToProvider.selectedProviders[item.id]"
                    :items="sendToProvider.providers"
                    :filter="(item, queryText) => (item.menuText || '').toLowerCase().indexOf((queryText || '').toLowerCase()) > -1"
                    label="Provider"
                    :loading="sendToProvider.loading"
                    tooltip-label="Send evse status"
                    color="accent"
                    @click="sendEvseStatusToProvider(item.id, sendToProvider.selectedProviders[item.id])"
                >
                    <v-icon small>mdi-send</v-icon> 
                </DropDownButton>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td class="pa-0 connector-table-container" :colspan="headers.length">
                    <EvseConnectorsTable :connectors="connectors.filter(connector => connector.evse_id == item.id)"/>
                </td>
            </template>
        </v-data-table>     

        <EvsesDialog/>
        
    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import DropDownButton from "@/components/ui-components/DropDownButton"
import WeneaCpSessionTool from "@/components/chargepoint/WeneaCpSessionTool"
import EvsesDialog from "@/components/chargepoint/EvsesDialog"
import EvseConnectorsTable from "@/components/evse/EvseConnectorsTable.vue"
import { EventBus } from "@/event-bus"
import ProviderName from "@/components/provider/ProviderName"
import LocationName from "@/components/location/LocationName"
import ChargepointName from "@/components/chargepoint/ChargepointName"

export default {
    name: "chargepoint",
    components: { 
        AppBar,
        DropDownButton, 
        WeneaCpSessionTool,
        EvsesDialog,
        EvseConnectorsTable,
        ProviderName,
        LocationName,
        ChargepointName
    },
    data() {
        return {
            loading: false,
            chargepoint: null,
            evses: [],
            connectors: [],
            location: null,
            provider: null,
            emspProviders: [],
            chargepointToBackend: null,
            evseStartSession: null,
            connectorStartSession: null,
            loadingStartSession: false,
            loadingStopSession: false,
            loadingSendChargepointToBackend: false,
            loadingDeleteChargepoint: false,
            loadingUnDeleteChargepoint: false,
            sendToProvider: {
                loading: false,
                providers: [],
                selectedProviders: {} // Object with id as key and provider selected as value. It has been raised like this to have a value per row. Example: { "evse_id_1": "provider_id_5", "evse_id_2": "provider_id_3" }
            },
            selectedToken: null,
            loadingOcpiTokens: false,
            ocpiTokens: [],
        }
    },
    watch: {
        evseStartSession: async function(item){

            this.connectors = item.connectors
            this.connectorStartSession = null

            if( this.connectors.length === 1 ){
                this.connectorStartSession = this.connectors[0]
            }

            return this.$api.get(`provider/1/paginated-ocpi-tokens?offset=0&limit=10`)
                .then(resp => this.ocpiTokens = resp.data.map(ocpiToken => ocpiToken.uid))
                .then(() => this.loadingOcpiTokens = false)


        },

    },
    computed: {
        locationInfo: function(){
            return this.location ? { name: this.location.name, id: this.location.id } : { name: '-', id: null }
        },
        providerInfo: function(){
            return this.provider ? { name: `${this.provider.country_code}-${this.provider.party_id} (${this.provider.role})`, id: this.provider.id } : { name: '-', id: null }
        },
        cpName: function(){
            return this.chargepoint ? this.chargepoint.reference_name : '-'
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load(){
            
            let id = this.$router.currentRoute.params.id
            if( !id ){ this.showError('ID is required!'); return; }

            this.loading = true
            this.$api.get(`chargepoint/${id}`)
                .then(resp => {

                    this.chargepoint = resp.data
                    this.evses = resp.data.evses
                    this.provider = resp.data.location.provider
                    this.location = resp.data.location
                    
                    let connectors = []
                    for( let e of this.evses ){
                        connectors.push(...e.connectors)
                    }
                    this.connectors = connectors
                    

                    this.evses?.map(evse => {
                        evse.text = `${evse.id} / ${evse.uid} / ${evse.evse_id}`
                    })

                    this.connectors?.map(c => {
                        c.text = `${c.id} / ${c.standard} / ${c.power_type}`
                    })

                })
                .catch(this.showError)
                .then(_ => { this.loading = false })

            this.$api.get("/providers-external")
                .then(res => {
                    this.emspProviders = res.data.filter( (provider) => provider.id == 1 || provider.role?.includes("EMSP"))

                    this.sendToProvider.providers = res.data
                        .filter(p => p.role && p.role.indexOf('EMSP') > -1)
                        .map(p => { 
                            return {
                                text: `${p.country_code}-${p.party_id}`,
                                menuText: `${p.country_code}-${p.party_id} ${p.user.username} (${p.user.type})`,
                                value: p.id
                            }
                        })
                })
                .catch(this.showError)
                .then(_ => { this.tableLoading = false })
        },
        openMaps(item){
            window.open(`https://www.google.com/maps/search/?api=1&query=${item.coordinates.latitude},${item.coordinates.longitude}`)
        },
        evseStandards(evse){
            return evse?.connectors?.map(o => o.standard).join(',')
        },
        externalStartSession(){
            this.loadingStartSession = true

            const data = {
                ocpiToken: this.selectedToken,
                connector: this.connectorStartSession
            }

            this.$api.post(`evse/${this.evseStartSession.id}/provider-START_SESSION`, data)
                .then(resp => {
                    this.showSuccess(resp.data)
                })
                .catch(this.showError)
                .then(_ => { this.loadingStartSession = false })
        },
        sendChargepointToBackend(){
            this.loadingSendChargepointToBackend = true
            this.$api.get(`chargepoint/${this.chargepoint.id}/send-chargepoint-to-backend`)
                .then(resp => {
                    this.showSuccess(resp.data)
                })
                .catch(this.showError)
                .then(_ => { this.loadingSendChargepointToBackend = false })
        },
        deleteChargepoint(){
            const confirmed = confirm('Are you sure you want to mark this Chargepoint as DELETED?')
            if(!confirmed){ return }

            this.loadingDeleteChargepoint = true
            this.$api.get(`chargepoint/${this.chargepoint.id}/delete-chargepoint`)
                .then(resp => {
                    this.showSuccess(resp.data)
                })
                .catch(this.showError)
                .then(_ => { this.loadingDeleteChargepoint = false })
        },
        unDeleteChargepoint(){
            const confirmed = confirm('Are you sure you want to mark this Chargepoint as UNDELETED?')
            if(!confirmed){ return }

            this.loadingUnDeleteChargepoint = true
            this.$api.get(`chargepoint/${this.chargepoint.id}/un-delete-chargepoint`)
                .then(resp => {
                    this.showSuccess(resp.data)
                })
                .catch(this.showError)
                .then(_ => { this.loadingUnDeleteChargepoint = false })
        },
        sendEvseStatusToProvider(evseId, selectedProvider){
            if (!selectedProvider) {
                return this.showError('You must select a provider to execute this action.')
            }

            this.$api.get(`evse/${evseId}/send-status-to/provider/${selectedProvider}`)
                .then(() => this.showSuccess('Evse status has been sent successfully.'))
                .catch(this.showError)
                .then(_ => { this.loadingSendChargepointToBackend = false })
        },
        showDialog(evse){
            EventBus.$emit(
                "dialog-evses:open",
                { evse, connectors: this.connectors.filter(connector => connector.evse_id == evse.id)}
            )
        },
    }
}
</script>

<style scoped>
    >>> .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    box-shadow: none;
    }
    >>> .evse-table > .v-data-table__wrapper > table > tbody {
        background-color: #f8f7f7;
    }
    >>> .connector-table-container {
        height: 32px!important;
    }
</style>