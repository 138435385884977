<template>

    <v-card outlined>
        <v-card-title class="grey lighten-3 d-flex justify-center pa-1"
            style="font-size: 1rem; font-weight: 500;">
            <span class="ml-1">{{ evse.evse_id }}</span>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pa-0">

            <!-- Status -->
            <div v-if="evse.deleted_at" class="error d-flex justify-center text-overline">
                <span class="font-weight-bold">Removed - {{ evse.deleted_at | dateFullFormat }}</span>
            </div>

            <span v-else class="d-flex justify-center text-overline">
                <span class="font-weight-bold ">Status: {{ evse.status }}</span>
            </span>

            <!-- Connectors -->
            <v-row v-if="evse.connectors.length > 0" :class="connectorsRowClass">
                <v-col 
                    v-for="connector in evse.connectors" :key="connector.id" 
                    :cols="connectorsCols"
                    class="py-2 align-center"
                >
                    <div class="text-center">
                        <div :class="mode == 'advanced' ? 'font-weight-bold' : ''">
                            <v-icon color="accent">mdi-power-plug</v-icon>
                            {{ connector.number }}
                            <span class="pl-3">{{ parseFloat(parseInt(connector.max_electric_power || 0) / 1000).toFixed(2) }} kW</span>
                            <span class="pl-2">{{ connector.standard }}</span>
                        </div>
                        <div v-if="mode == 'advanced'">
                            <span class="d-flex justify-center text-overline">
                                <span class="font-weight-medium">Connector Tariffs</span>
                            </span>

                            <div v-if="connector.applicable_tariffs"
                            v-for="tariff in connector.applicable_tariffs" :key="tariff.id"
                            outlined color="accent" small>
                           
                                <div class="my-1" v-for="(element, index) in tariff.elements.a" :key="`price_component-${index}`">
                        
                                    <v-chip outlined color="accent" small v-for="(price_component, index) in element.price_components" :key="index">
                                        <div class="pr-1" style="border-right: ridge">
                                            <v-icon small>mdi-tag</v-icon>
                                            {{ tariff.id }}
                                        </div>
                                        <div class="pl-1">
                                            {{ price_component.price }} {{ tariff.currency }} x kWh {{ price_component.vat ? `(VAT ${price_component.vat}%)` : ''}}
                                        </div>
                                    </v-chip>

                                    <v-btn plain small :to="`/tariffs/${tariff.id}`">
                                        <v-icon small>mdi-arrow-top-right</v-icon>
                                    </v-btn>

                                </div>
                            </div>
                        </div>
                    </div>
                        
                </v-col>
            </v-row>

            <span v-else class="d-flex justify-center text-overline">No connectors found</span>
        </v-card-text>
    </v-card>

</template>

<script>
import PriceComponentChip from "@/components/tariff/PriceComponentChip"

    export default {
        name: "evseStructureCard",
        props: ["evse", "parentColumnSize", "mode"],
        components: {
            PriceComponentChip
        },
        computed: {
            connectorsRowClass() {
                return `py-2 ${this.evse.connectors.length > 2 ? '' : 'd-flex justify-space-around'}`
            },
            connectorsCols() {
                const { parent, grandParent } = this.parentColumnSize

                if ((parent === 6 && grandParent === 12) || (parent === 12 && grandParent === 6)) {
                    return 6
                } else if (parent === 12 && grandParent === 12) {
                    return 4
                } else {
                    return 12
                }
            },
        },
    }
</script>

<style scoped>
  .v-btn:not(.v-btn--round).v-size--small {
    height: 20px;
    min-width: 20px !important;
    padding: 0 0 0px 0px !important;
  }


</style>