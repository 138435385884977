<template>
  <v-container fluid>
    <AppBar title="Tools" icon="mdi-tools" />

    <v-expansion-panels
      v-model="expanded"
    >

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="d-flex">
            <v-icon color="accent">mdi-tag-remove-outline</v-icon>
            <h3 class="ml-2">Providers without assigned tariffs</h3>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-4">
          <p class="mb-6">
              List all <v-chip small>EMSP</v-chip> providers that have a Wenea connector without an associated tariff. It also shows as not associated if they have a tariff in REMOVED status associated.
          </p>
          <ProvidersWithoutTariffs></ProvidersWithoutTariffs>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="d-flex">
            <v-icon color="accent">mdi-tag-multiple-outline</v-icon>
            <h3 class="ml-2">Collided tariffs</h3>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-4">
          <p class="mb-2">
            List tariffs that match in provider, connector and priority.
          </p>
          <CollidedTariffs></CollidedTariffs>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="d-flex">
            <v-icon color="accent">mdi-calculator-variant-outline</v-icon>
            <h3 class="ml-2">Charge session price calculator</h3>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-4">
          <p class="mb-8">
            This tool calculates what pertocarry tariff would be for this connector and provider, and estimates price of charge.
          </p>
          <ChargeSessionPriceCalculator></ChargeSessionPriceCalculator>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="d-flex">
            <v-icon color="accent">mdi-credit-card-remove-outline</v-icon>
            <h3 class="ml-2">Charge sessions without CDR</h3>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-4">
          <p class="mb-6">
            List all sessions with more than 0.2kWh that do not have an associated CDR.
          </p>
          <SessionsWithoutCdrs></SessionsWithoutCdrs>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="d-flex">
            <v-icon color="accent">mdi-alphabetical-variant</v-icon>
            <h3 class="ml-2">evse_id parser</h3>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-4">
          <EvseIdReferencesTool></EvseIdReferencesTool>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="d-flex">
            <v-icon color="accent">mdi-database-import-outline</v-icon>
            <h3 class="ml-2">Import Sessions from DTM</h3>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-4">
            <p class="mb-6">
                Tool to import DTM transactions and transform them to OCPI Sessions.<br>
                Currently you can import transactions with <v-chip small>Start Method: TagAuth</v-chip> and <v-chip small>Start Method: Remote</v-chip>  (CommandStart must exist in OCPI DB)<br>
                RealtimeAuth_Allowed will not be related to TagAuth sessions, for this to be possible we need to update the DTM code.<br>
            </p>
          <DTMSessionsImporter></DTMSessionsImporter>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="d-flex">
            <v-icon color="accent">mdi-key-wireless</v-icon>
            <h3 class="ml-2">Real Time RFID Authorization Test</h3>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pa-4">
            <p class="mb-6">
                Send a POST Token request to EMSP Providers to check if indicated token has permissions.
            </p>
            <RealTimeAuthTool></RealTimeAuthTool>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>

  </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import ProvidersWithoutTariffs from "@/components/tools/ProvidersWithoutTariffs.vue"
import CollidedTariffs from "@/components/tools/CollidedTariffs.vue"
import ChargeSessionPriceCalculator from "@/components/tools/ChargeSessionPriceCalculator.vue"
import SessionsWithoutCdrs from "@/components/tools/SessionsWithoutCdrs.vue"
import EvseIdReferencesTool from "@/components/tools/EvseIdReferencesTool.vue"
import DTMSessionsImporter from "../../components/tools/DTMSessionsImporter.vue"
import RealTimeAuthTool from "../../components/tools/RealTimeAuthTool.vue"

export default {
    components: {
    AppBar,
    ProvidersWithoutTariffs,
    CollidedTariffs,
    ChargeSessionPriceCalculator,
    SessionsWithoutCdrs,
    EvseIdReferencesTool,
    DTMSessionsImporter,
    RealTimeAuthTool
},
    data () {
      return {
        expanded: 0
      }
    }
}
</script>

<style scoped>
  >>> .v-expansion-panel-header {
    line-height: 1.5!important;
  }
</style>