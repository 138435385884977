import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"outlined":""}},[_c(VCardText,[_c('p',{staticClass:"text-subtitle-2"},[_c(VChip,{staticClass:"mr-2",attrs:{"color":"indigo","outlined":"","small":""}},[_vm._v("PUT")]),_vm._v("Location")],1),_c('p',[_vm._v("Send information of this Location to Provider. Location, evses and connectors that belong to it will be sent.")]),_c(VAutocomplete,{staticClass:"mb-4",attrs:{"items":_vm.showAllProviders ? _vm.providers : _vm.locationGroupProviders,"rules":[function (v) { return !!v; }],"item-text":"id","filter":_vm.autocompleteDeepSearch,"item-value":"id","label":"Send to","multiple":"","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"small":"","outlined":""}},[_c(VIcon,{attrs:{"small":"","left":"","color":"black"}},[_vm._v("mdi-earth")]),_vm._v(_vm._s(((item.country_code) + "-" + (item.party_id)))+" ")],1)]}},{key:"prepend-item",fn:function(){return [_c(VRow,{staticClass:"ma-0 pa-0 px-4",attrs:{"align":"center"}},[_c(VSimpleCheckbox,{attrs:{"value":_vm.allSelected,"indeterminate":!_vm.allSelected && _vm.providersToSend.length > 0,"color":_vm.providersToSend.length > 0 ? 'accent' : ''},on:{"input":_vm.selectAll}}),_c('span',[_vm._v("Select All")]),_c(VSpacer),_c('span',[_vm._v("Show all providers")]),_c(VSwitch,{staticClass:"ma-0 pb-2 pl-2",attrs:{"color":"accent","hide-details":""},model:{value:(_vm.showAllProviders),callback:function ($$v) {_vm.showAllProviders=$$v},expression:"showAllProviders"}})],1),_c(VDivider)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c(VSimpleCheckbox,_vm._g({attrs:{"value":_vm.providersToSend.includes(item.id),"color":"accent"}},on)),_c(VIcon,{attrs:{"small":"","color":"black"}},[_vm._v("mdi-earth")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(((item.country_code) + "-" + (item.party_id))))]),(item.role)?_c(VChip,{staticClass:"ml-2",attrs:{"small":"","outlined":""}},[_vm._v(_vm._s(item.role.replaceAll(',', '/')))]):_vm._e()]}}]),model:{value:(_vm.providersToSend),callback:function ($$v) {_vm.providersToSend=$$v},expression:"providersToSend"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","block":"","loading":_vm.loading,"color":"accent"},on:{"click":_vm.sendLocationToProvider}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v(" PUT Location ")],1)],1),_c(VDialog,{attrs:{"max-width":"1000"},model:{value:(_vm.showReport),callback:function ($$v) {_vm.showReport=$$v},expression:"showReport"}},[(_vm.report)?_c(VCard,[_c(VCardTitle,{staticClass:"accent white--text"},[_c('span',{staticClass:"headline"},[_c(VIcon,{staticClass:"white--text",attrs:{"left":""}},[_vm._v("mdi-file-document-alert-outline")]),_c('span',[_vm._v("Report")])],1)]),_c(VCardText,{staticClass:"py-4 pb-0"},[_c(VDataTable,{staticClass:"rounded-table",attrs:{"headers":[
                        {text: 'Provider', value: 'provider_id'},
                        {text: 'Success', value: 'success', align: 'center'},
                        {text: 'Error', value: 'error_message'}
                    ],"items":_vm.report,"sort-by":"success","sort-desc":"","dense":""},scopedSlots:_vm._u([{key:"item.provider_id",fn:function(ref){
                    var item = ref.item;
return [_c(VRow,{staticClass:"ma-0",attrs:{"set":_vm.provider = _vm.providers.find(function (p) { return p.id == item.provider_id; }) || _vm.locationGroupProviders.find(function (p) { return p.id == item.provider_id; })}},[(_vm.provider)?[_c(VIcon,{attrs:{"small":"","color":"black"}},[_vm._v("mdi-earth")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(((_vm.provider.country_code) + "-" + (_vm.provider.party_id))))])]:[_vm._v(_vm._s(item.provider_id))]],2)]}},{key:"item.success",fn:function(ref){
                    var item = ref.item;
return [(item.success)?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]):_c(VIcon,{attrs:{"color":"error"}},[_vm._v("mdi-close-circle")])]}}],null,false,434470057)})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.showReport = false}}},[_vm._v(" Close ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }