import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.invoices)?_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"headers":[
    { value: 'action', sortable: false, cellClass: 'td-linkto' },
    { text: 'Day', value: 'start_date_time', width: 110 },
    { text: 'Provider', value: 'provider' },
    { text: 'Charge point', value: 'chargepoint' },
    { text: 'Energy', value: 'kwh', width: 100 },
    { text: 'Tariff', value: 'tariff', width: 100 },
    { text: 'Cost', value: 'total_cost' } ],"items":_vm.invoices,"items-per-page":5,"footer-props":{ 'items-per-page-options': [5,10,20,-1] },"sort-by":"start_date_time","dense":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
  var item = ref.item;
return [_c(VBtn,{attrs:{"plain":"","small":"","to":("/session/" + (item.id))}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1)]}},{key:"item.start_date_time",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.start_date_time))+" ")]}},{key:"item.provider",fn:function(ref){
  var item = ref.item;
return [_c('ProviderName',{attrs:{"provider":item.receiverProvider}})]}},{key:"item.chargepoint",fn:function(ref){
  var item = ref.item;
return [_c('ChargepointName',{attrs:{"chargepoint":{ id: item.chargepoint_id, name: item.chargepoint_name },"type":"table"}})]}},{key:"item.kwh",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.kwh)+" kWh ")]}},{key:"item.tariff",fn:function(ref){
  var item = ref.item;
return [(item.cdr && item.cdr.tariff_id)?_c(VBtn,{attrs:{"text":"","to":("/tariffs/" + (item.cdr.tariff_id))}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-tag-text-outline")]),_vm._v(" "+_vm._s(item.cdr.tariff_id)+" ")],1):_vm._e()]}},{key:"item.total_cost",fn:function(ref){
  var item = ref.item;
return [_c('strong',[(!item.end_date_time)?_c(VChip,{staticClass:"text-chip text-uppercase px-1",attrs:{"small":"","label":"","outlined":"","color":"accent"}},[_vm._v(" In progress ")]):_vm._e(),((item.total_cost && item.end_date_time) || item.cdr)?_c(VChip,{staticClass:"text-chip text-uppercase px-1",attrs:{"small":"","label":"","outlined":"","color":"accent"}},[_vm._v(" "+_vm._s(item.total_cost)+" "+_vm._s(item.cdr.currency)+" ")]):_vm._e(),(!item.total_cost && item.end_date_time && !item.cdr)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":item.kwh <= 0.2 ? 'warning' : 'error'}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")])]}}],null,true)},[(item.kwh <= 0.2)?_c('span',[_vm._v("Less or equal than 0.2 kWh, it is correct to not have CDR")]):_c('span',[_vm._v("CDR should have been created")])]):_vm._e()],1)]}},{key:"footer",fn:function(){return [_c(VBtn,{staticClass:"my-4 prepend-footer-content",staticStyle:{"position":"absolute"},attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$emit('seeAllClicked')}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-arrow-top-right")]),_vm._v("See all")],1)]},proxy:true}],null,false,3696465004)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }