<template>
    <v-container fluid v-if="provider">
        <AppBar
            :breadcrumbs="[
            { text: 'Providers', to: '/providers' },
            { text: name, icon: 'mdi-earth', to: `/provider/${provider_id}` },
            { text: 'CronJobs' }
            ]"
        />
        
        <h2 class="mb-2">
            <v-icon left color="accent">mdi-calendar-check</v-icon> Cron Jobs
        </h2>

        <CronJobs :provider_id="provider_id" :roles="provider.role.split(',')"/>
    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar";
import CronJobs from "@/components/cron-jobs/CronJobs"

export default {
    name: "provider-cronjobs",
    components: {
        AppBar,
        CronJobs
    },
    data() {
        return {
        provider: null,
        }
    },
    computed: {
        name() {
            return this.provider
                ? `${this.provider.country_code}-${this.provider.party_id} (${this.provider.role})`
                : "-"
        },
        provider_id(){
            return this.provider ? this.provider.id : 0
        },
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async loadData() {
            let id = this.$router.currentRoute.params.id
            if (!id) {
                return
            }
            let provider = await this.$api
                .get(`/provider/${id}`)
                .then((resp) => resp.data)
                .catch(this.showError)

            if (!provider) {
                this.showError(`Provider #${id} not found`)
                return
            }
            this.provider = provider
        }
    }
}
</script>

