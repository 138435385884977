import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2",class:{ 'shake': _vm.shake },attrs:{"icon":"","to":"/alerts"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-bell-outline")])],1),_c(VBadge,{attrs:{"content":_vm.alerts,"color":"red","inline":""}})],1)]}}])},[_c('span',[_vm._v("Current Alerts")])])}
var staticRenderFns = []

export { render, staticRenderFns }