<template>
    <v-container fluid>

        <AppBar :breadcrumbs="[
            { text: 'Connections', to: '/connections' },
            { text: connectionName }
        ]" />

        <ConnectionForm :user="user" />

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import { EventBus } from "@/event-bus"
import ConnectionForm from "@/components/connection/ConnectionForm"

export default {
    name: "connection.create",
    components: { 
        AppBar, 
        ConnectionForm 
    },
    data() {
        return {            
            loading: false,
            user: null
        }
    },
    computed: {
        connectionName: function() {
            return (this.user) ? (this.user.company?.name || this.user.username) : 'New connection'
        }
    },
    beforeDestroy(){
        EventBus.$off('connection-created')
    },
    async mounted(){

        EventBus.$on("connection-created", (user) => {
            this.$router.push({ path: '/company/create', query: { user_id: user.user.id } })
            setTimeout(() => { this.showSuccess("New connection created") }, 500)
        })

        let id = this.$router.currentRoute.params.id
        if( !id ){ return }

        this.loading = true 

        try {

            let response = await this.$api.get(`/connections/${id}`)
            this.user = response.data

        } catch(e) {
            this.showError(e)
        }

        this.loading = false

    },
};
</script>

<style scoped>
    >>> .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
        max-height: 32px !important;
        min-height: 32px !important;
    }
</style>
