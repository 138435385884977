import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"title":"Out session queues","icon":"mdi-tray-full"}}),_c(VRow,{staticClass:"mb-2 px-2",attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VTextField,{attrs:{"clearable":"","hide-details":"","append-icon":"mdi-magnify","placeholder":"Search","color":"accent"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onFilter.apply(null, arguments)}},model:{value:(_vm.tableSearch),callback:function ($$v) {_vm.tableSearch=$$v},expression:"tableSearch"}})],1),_c(VBtn,{staticClass:"ml-2 mt-3",attrs:{"color":"accent"},on:{"click":_vm.onFilter}},[_c(VIcon,[_vm._v("mdi-filter")])],1)],1),[_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"headers":[
                { text: 'Queue name', value: 'qname', align: 'center' },
                { text: 'Event type', value: 'event_type', align: 'center'  },
                { text: 'Key', value: 'key' , align: 'center' },
                { text: 'Fails', value: 'fails', align: 'center'  },
                { text: 'Errors', value: 'errors', align: 'center'  } ],"items":_vm.queues,"search":_vm.searchFilter,"loading":_vm.tableLoading,"group-by":"qname","sort-by":"key","sort-desc":"","dense":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
            var group = ref.group;
            var headers = ref.headers;
            var toggle = ref.toggle;
            var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(VBtn,{ref:group,attrs:{"icon":""},on:{"click":toggle}},[(isOpen)?_c(VIcon,[_vm._v("mdi-minus")]):_c(VIcon,[_vm._v("mdi-plus")])],1),_c('span',{staticClass:"ml-5 font-weight-bold"},[_vm._v("Session queue")]),_c(VBtn,{staticClass:"ml-0 pl-1",attrs:{"plain":"","small":"","to":("/session/" + (group.split(':')[1]))}},[_vm._v(" #"+_vm._s(group.split(':')[1])+" "),_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1),_c(VBtn,{staticClass:"float-right",attrs:{"plain":"","color":"red","loading":_vm.finishBtnLoading},on:{"click":function($event){_vm.finishQueue(group.split(':')[1])}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-tray-remove")]),_vm._v(" Finish queue ")],1)],1)]}},{key:"item.errors",fn:function(ref){
            var item = ref.item;
return [(item.errors)?_c('json-viewer',{staticClass:"pa-2 text-left",attrs:{"value":item.errors,"expand-depth":0}}):_vm._e()]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }