<template>
    <v-container fluid>

        <AppBar title="Tokens" icon="mdi-key" />

        <!-- Table filters -->
        <v-row class="mb-2 px-2" align="center">
            <v-col cols="12" md="2">
                <v-text-field 
                    v-model="selectedSearch"
                    clearable 
                    hide-details
                    append-icon="mdi-magnify"
                    placeholder="Search"
                    color="accent"
                    autofocus
                    @keyup.enter="onFilter"
                    >
                </v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-autocomplete
                    v-model="selectedProvider"
                    :items="providers"
                    label="Provider"
                    color="accent"
                    hide-details
                >
                    <template v-slot:item="data">
                        <ProviderSelectorTemplate :provider="data.item" />
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2">
                <v-autocomplete
                    v-model="selectedWhitelist"
                    :items="whitelistEnum"
                    label="Whitelist"
                    color="accent"
                    clearable
                    hide-details
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="2">
                <v-autocomplete
                    v-model="selectedType"
                    :items="typeEnum"
                    label="Type"
                    color="accent"
                    clearable
                    hide-details
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="2">
                <v-menu
                    ref="menu"
                    v-model="showDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"    
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="selectedDateFrom"
                            label="Date from"
                            prepend-icon="mdi-calendar"
                            color="accent"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="selectedDateFrom"
                        @input="showDatePicker = false"
                        no-title
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <v-btn 
                class="mt-5 mr-2"
                color="accent"
                :loading="tableLoading" 
                @click="onFilter"
            >
                <v-icon>mdi-filter</v-icon>
            </v-btn>

            <v-spacer />

            <v-btn 
                class="mt-5 mr-2"
                color="accent"
                :loading="loadingCSV"
                outlined 
                @click="createCSV"
            >
                <v-icon left>mdi-file-download-outline</v-icon> CSV
            </v-btn>
        </v-row>

        <!-- Data table -->
        <v-data-table
            v-if="tokens"
            :headers="[
                {text: 'Id.', value: 'id'},
                {text: 'UID', value: 'uid'},
                {text: 'Valid', value: 'valid' },
                {text: 'Type', value: 'type'},
                {text: 'Whitelist', value: 'whitelist' },
                {text: 'Provider', value: 'provider'},
                {text: 'Issuer', value: 'issuer' },
                {text: 'Contract Id.', value: 'contract_id' },
                {text: 'Updated at', value: 'updated_at' },
            ]"
            :items="tokens"
            :items-per-page="tableOptions.itemsPerPage"
            :loading="tableLoading"
            :server-items-length="tokens.length ? (tableOptions.page * tokens.length + 1) : 0"
            :footer-props="{ 'items-per-page-options': pagination.itemsPerPageOptions }"
            @update:options="this.updateTableOptions"
            disable-sort
            sort-by="updated_at"
            dense
            class="accent-table rounded-table hide-footer-pagination-items-count"
            :no-data-text="initialLoadMessage"
        >
            <template v-slot:item.provider="{ item }">
                <ProviderName
                    :provider="{ id: item.provider_id, country_code: item.country_code, party_id: item.party_id }"
                />
            </template>
            <template v-slot:item.valid="{ item }">
                <v-icon v-if="item.valid" color="success">mdi-check-circle</v-icon>
                <v-icon v-else color="error">mdi-close-circle</v-icon>
            </template>
            <template v-slot:item.updated_at="{ item }">
                {{ item.updated_at | dateFullFormat }}
            </template>
        </v-data-table>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"
import ProviderName from "@/components/provider/ProviderName"
import _ from "lodash"

export default {
    name: "wenea",
    components: {
        AppBar,
        ProviderSelectorTemplate,
        ProviderName
    },
    data() {
        return {
            selectedSearch: undefined,
            tableLoading: false,
            selectedProvider: undefined,
            selectedWhitelist: undefined,
            selectedType: undefined,
            selectedDateFrom: undefined,
            showDatePicker: false,
            whitelistEnum: ['ALWAYS', 'ALLOWED', 'ALLOWED_OFFLINE', 'NEVER'],
            typeEnum: ['AD_HOC_USER', 'APP_USER', 'RFID', 'OTHER'],
            providers: [],
            tokens: [],
            pagination: {
                itemsPerPageOptions: [25, 50, 100, 250],
            },
            tableOptions: {
                itemsPerPage: 25,
                page: 1,
            },
            loadingCSV: false,
            wenea_info: null,
            initialLoad: true,
            initialLoadMessage: 'Click the filter button to load the data'
        }
    },
    mounted(){
        this.load()
    },
    watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
            },
            immediate: true
        },
    },
    methods: {
        async load() {
            await this.$api.get('wenea/provider-info')
                .then(resp => { this.wenea_info = resp.data.id })
                .catch(this.showError)
                
            this.$api.get("/providers")
                .then(res => {
                    this.providers = res.data.map(p => { 
                        p.id === this.wenea_info ? this.selectedProvider = p.id : ''
                        return { 
                            ...p, 
                            text: p.role ? `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username} (${p.role})` : `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username}`, 
                            value: p.id 
                        } 
                    })
                })
                .catch(this.showError)

        },
        async loadTokens(){
            this.tableLoading = true

            this.initialLoadMessage = 'No tokens found'

            this.getTokens(true)
                .then(resp => this.tokens = resp.data)
                .catch(this.showError)
                .finally(() => {
                    this.tableLoading = false
                    this.initialLoad = false
                })
        },
        async getTokens(paginatedResponse){

            this.tableLoading = true

            let queryParams = ''

            if(paginatedResponse) { 
            queryParams += `offset=${(this.tableOptions.itemsPerPage * this.tableOptions.page) - this.tableOptions.itemsPerPage}`
            if (this.tableOptions.itemsPerPage > 0) { queryParams += `&limit=${this.tableOptions.itemsPerPage}` }
            }

            if (this.selectedSearch && this.selectedSearch !== '') { queryParams += `&orfilter[id,uid,type,whitelist,country_code,party_id,issuer,contract_id]=${this.selectedSearch}` }
            if (this.selectedWhitelist) { queryParams += `&whitelist=${this.selectedWhitelist}` }
            if (this.selectedType) { queryParams += `&type=${this.selectedType}` }
            if (this.selectedDateFrom) { queryParams += `&date_from=${this.selectedDateFrom}` }

            return await this.$api.get(`provider/${this.selectedProvider || this.wenea_info}/paginated-ocpi-tokens?${queryParams}`)
                .then(resp => resp)
                .catch(this.showError)

        },
        onFilter(){
            if(this.tableLoading) return
            this.urlPush('search', this.selectedSearch)
            this.urlPush('whitelist', this.selectedWhitelist)
            this.urlPush('provider', this.selectedProvider)
            this.urlPush('type', this.selectedType)
            this.urlPush('dateFrom', this.selectedDateFrom)

            this.loadTokens()
        },
        updateTableOptions(args){
            this.tableOptions = args
            if(!this.initialLoad){
                this.loadTokens()
            }
        },
        async createCSV(){
            this.loadingCSV = true

            const tokens = await this.getTokens(false)
                .then(resp => resp.data)
                .catch(this.showError)
                .finally(() => this.loadingCSV = false)

            let content = '"Id.";"UID";"Valid";"Type";"Whitelist";"Country";"Party";"Issuer";"Contract Id.";"Last updated"\r\n'
            
            for( const row of tokens ){
                content += `"${row.id}";"${row.uid}";"${row.valid}";"${row.type}";"${row.whitelist}";"${row.country_code}";"${row.party_id}";"${row.issuer}";"${row.contract_id}";"${row.last_updated}"\r\n`
            }

            this.downloadBlob(content, `tokens.csv`, "text/csv")
            this.tableLoading = false
        }
    }
};
</script>
