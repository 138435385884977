<template>
    <v-expansion-panels v-if="company" class="custom-depressed-expansion-panels rounded-0">
        <v-expansion-panel class="rounded-0" >
            <v-expansion-panel-header>
                <div class="title">
                    <v-icon left color='accent'>mdi-domain</v-icon>Company
                    <v-chip v-if="company.user_id" class="ml-2" small outlined color="accent">Linked to Connection</v-chip>
                    <v-chip v-else class="ml-2" small outlined color="accent">Linked to Provider</v-chip>
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>

                <v-row class="pl-3">
                    <v-col cols="12" sm="4">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap">{{ company.name || '-' }}</v-list-item-title>
                                <v-list-item-subtitle>Name</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap">
                                    {{ company.map_url || '-' }}
                                    <v-btn plain small :href="company.map_url" target="_blank">
                                        <v-icon small>mdi-arrow-top-right</v-icon>
                                    </v-btn>
                                </v-list-item-title>
                                <v-list-item-subtitle>Map URL</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn
                    :to="`/company/${company.id}/edit`"
                    text
                    class="accent mr-10 mt-4"
                    >
                        <v-icon left>mdi-pencil</v-icon>
                        Modify Company
                    </v-btn>
                </v-row>

                <v-row class="pl-3">
                    <v-col cols="12" sm="4">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap">{{ company.notes || '-' }}</v-list-item-title>
                                <v-list-item-subtitle>Notes</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{ company.start_charging_methods || '-' }}</v-list-item-title>
                                <v-list-item-subtitle>Start Charging Methods</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-chip v-for="(country, index) in company.countries" :key="index" small outlined class="mr-1">
                                        {{ country }}
                                    </v-chip>
                                </v-list-item-title>
                                <v-list-item-subtitle>Operational Countries</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="2">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{ company.integration_date | dateFullFormat }}</v-list-item-title>
                                <v-list-item-subtitle>Integration Date</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>

                <!-- Contact Channels-->
                <v-row class="pl-6 pr-6">
                    <v-col v-for="(item, index) in company.channels" v-bind:key="index" align="center">
                        <v-card outlined class="text-center" max-width="485">
                            <v-card-title class="justify-center">
                                {{ item.description }}
                            </v-card-title>

                            {{ item.url }}
                            <v-btn icon small :href="`http://${item.url}`" target="_blank">
                                <v-icon small>mdi-arrow-top-right </v-icon>
                            </v-btn>
                            <v-list-item-subtitle class="mb-2">URL</v-list-item-subtitle>
                        </v-card>
                    </v-col>
                </v-row>

                <!-- Technical Contacts-->
                <v-row class="pl-6 pb-6 pr-6">
                    <v-col v-for="(item, index) in company.technical_contacts" v-bind:key="index" align="center">
                        <v-card outlined class="text-center" max-width="485">
                            
                            <v-card-title class="justify-center">
                                {{ item.name }}
                            </v-card-title>

                            {{ item.email }}
                            <v-list-item-subtitle class="mb-2">Email</v-list-item-subtitle>

                            {{ item.phone }}
                            <v-list-item-subtitle class="mb-2">Phone</v-list-item-subtitle>

                            <v-card-actions class="justify-center">
                                <v-btn :href="`mailto:${item.email}`" target="_blank" text outlined color="accent">
                                    <v-icon left>mdi-email</v-icon>
                                    Send Email
                                </v-btn>

                                <v-btn :href="`tel:${item.phone}`" target="_blank" text outlined color="accent">
                                    <v-icon left>mdi-phone</v-icon>
                                    Call
                                </v-btn>

                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    name: "company-expansion-panel",
    props: ["company"]
};
</script>

<style scoped>
    .custom-depressed-expansion-panels:not(.compressed-expansion-panels) .v-expansion-panel {
        border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
        -webkit-transition: border-top 0s;
        transition: border-top 0s;
    }
    .custom-depressed-expansion-panels .v-expansion-panel::before {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    .v-btn:not(.v-btn--round).v-size--small {
        height: 28px;
        min-width: 0px !important;
        padding: 0 0 4px 0px !important;
    }
  
</style>
