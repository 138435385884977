<template>
    <div v-if="http_info">
        <div class="pa-2 text-center">
            <v-chip small>UTC Date: {{ http_info.timestamp }}</v-chip>
            <div>{{ http_info.request.method }} {{ http_info.request.full_url }}</div>
        </div>

        <v-divider />

        <div class="pa-2">
            <h4>Request</h4>
            <div class="d-flex flex-column">
                <span class="text-overline">Body: </span>
                <json-viewer v-if="http_info.request.body" :value="http_info.request.body" :expand-depth="1" copyable />
                <span v-else class="pl-1 json-text-orange">null</span>
            </div>
            <div>
                <span class="text-overline">Headers: </span>
                <json-viewer v-if="http_info.request.headers" :value="http_info.request.headers" :expand-depth="0" copyable />
            </div>
        </div>

        <v-divider class="mt-2 mx-0" />

        <div class="pa-2">
            <v-row class="mt-2 mx-0">
                <h4 class="mr-auto">Response {{ `${http_info.response.http_status}: ${http_info.response.status_message}` }}</h4>
                <p class="mr-3 mb-0" v-if="http_info.response.duration"><span class="text-body-2">Time: </span><span class="text-body-2 green--text text--lighten-1">{{ http_info.response.duration }} s</span></p>
            </v-row>
            <p class="mt-3">
                <span class="text-overline">Content: </span>
                <json-viewer v-if="http_info.response.content" :value="http_info.response.content" :expand-depth="1" copyable />
            </p>
            <p class="mb-2">
                <span class="text-overline">Headers: </span>
                <json-viewer v-if="http_info.response.headers" :value="http_info.response.headers" :expand-depth="0" copyable />
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "http-info",
    props: {
       http_info: {
        type: Object,
        default: null
      }
    }
}
</script>

<style>
    .jv-code {
        padding: 0 !important
    }
    .json-text-orange {
        white-space: nowrap;
        color: #e08331;
        font-size: 14px;
        font-family: Consolas, Menlo, Courier, monospace;
    }
</style>