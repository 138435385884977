<template>
    <v-container fluid>

        <AppBar title="Alerts" icon="mdi-bell-outline" />

        <!-- Data table -->
        <v-data-table
            :headers="[
                {text: 'Message', value: 'message'},
                {text: 'Provider', value: 'provider_id'},
                {text: 'Content', value: 'content'},
                {text: 'Created at', value: 'created_at'},
                { value: 'actions' }
            ]"
            :items="alerts"
            :loading="loading"
            sort-by="created_at"
            disable-sort
            dense
            class="accent-table rounded-table"         
        >
            <template v-slot:item.provider_id="{ item }">
                <ProviderName v-if="item.provider_id"
                    :provider="providers.find(p => p.id == item.provider_id)"
                />
            </template>
            <template v-slot:item.content="{ item }">
                <json-viewer v-if="item.content"
                    class="pa-2 text-left"
                    :value="item.content"
                    :expand-depth="0"
                    >
                </json-viewer>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | adonisDateFullFormat }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn 
                icon color="red" small @click="deleteAlert(item)">
                    <v-icon small>mdi-delete</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import Bws from "@/plugins/BackofficeWebsocket.js"
import ProviderName from "@/components/provider/ProviderName"

export default {
    name: "evse",
    components: { 
        AppBar, 
        ProviderName 
    },
    data() {
        return {
            loading: false,
            alerts: [],
            providers: []
        }
    },
    mounted() {
        this.load()

        const alertsSubscription = Bws.getSubscription('backoffice:alerts')
        alertsSubscription.on('new_alerts', () => this.load())

        this.$api.get("/providers")
                .then(res => this.providers = res.data)
                .catch(this.showError)
    },
    methods: {
        async load(){
            this.loading = true

            this.$api.get(`alerts`)
                .then((resp) => this.alerts = resp.data)
                .catch(this.showError)
                .then(() => this.loading = false)
        },
        async deleteAlert(alert){
            this.loading = true

            this.$api.delete(`alerts`, { data: alert })
                .then(() => this.showSuccess('Alert has been removed'))
                .catch(this.showError)
                .then(() => this.loading = false)
        }
    }
};
</script>

<style scoped>
    .jv-container.jv-light {
        background: none;
    }
</style>