import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContent,[_c(VContainer,{attrs:{"fluid":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VRow,{staticStyle:{"height":"600px"},attrs:{"align":"center","justify":"center"}},[_c(VFlex,{staticStyle:{"min-width":"400px"},attrs:{"xs12":"","sm8":"","md4":""}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c(VCard,{staticClass:"elevation-12"},[_c(VToolbar,{attrs:{"color":"primary","dark":"","flat":""}},[_c(VToolbarTitle,{staticStyle:{"margin":"0 auto"}},[_c('div',{staticStyle:{"float":"left"}},[_c(VImg,{attrs:{"src":require("../assets/wenea-ico.png"),"height":"54","contain":""}})],1),_c('div',{staticClass:"mt-2 ml-4",staticStyle:{"float":"left"}},[_c('span',{staticClass:"display-1"},[_vm._v("OCPI Backoffice")])])])],1),_c(VCardText,[_c(VTextField,{attrs:{"type":"email","label":"E-mail","prepend-icon":"mdi-account"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"type":"password","label":"Password","prepend-icon":"mdi-lock"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VCardActions,[_c(VBtn,{staticClass:"accent",attrs:{"block":"","type":"submit","loading":_vm.loading}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-login")]),_vm._v("Log in ")],1)],1)],1)],1)])],1)],1)],1),_c(VSnackbar,{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" "),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }