<template>
    <v-container fluid>
        <AppBar title="Location Groups" icon="mdi-folder-marker" />

        <!-- Table filters -->
        <v-row class="mb-2 px-2" align="center">
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="selectedSearch"
                    clearable
                    hide-details
                    color="accent"
                    append-icon="mdi-magnify"
                    placeholder="Search"
                    autofocus
                    @keyup.enter="onFilter"
                >
                </v-text-field>
            </v-col>

            <v-btn 
                class="mt-5 mr-2"
                color="accent"
                :loading="tableLoading"
                @click="onFilter"
            >
                <v-icon>mdi-filter</v-icon>
            </v-btn>

        </v-row>

        <!-- Data table -->
        <v-data-table
            v-if="groups"
            :headers="[
                { value: 'linkto', sortable: false, cellClass: 'td-linkto' },
                { text: 'Id.', value: 'id' },
                { text: 'Name', value: 'name' },
                { text: 'Reference', value: 'reference' },
                { text: 'Private', value: 'is_private' },
                { text: 'Backend Id', value: 'backend_id' },
                { text: 'Updated at', value: 'updated_at' },
            ]"
            :items="groups"
            :loading="tableLoading"
            :search="tableSearch"
            disable-sort
            disable-pagination
            hide-default-footer
            dense
            class="accent-table rounded-table"
        >
            <template v-slot:item.linkto="{ item }">
                <v-btn plain small :to="`/location-group/${item.id}`">
                    <v-icon small>mdi-arrow-top-right</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.is_private="{ item }">
                <v-icon v-if="item.is_private">mdi-check</v-icon>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar";
import _ from "lodash"

export default {
    components: {
        AppBar,
    },
    data() {
        return {
            selectedSearch: null,
            tableLoading: false,
            groups: [],
            tableSearch: null,
        };
    },
    watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },          
    },
    methods: {
        load() {
            this.tableLoading = true;
            this.$api
                .get("location-groups")
                .then((resp) => {
                    this.groups = resp.data;
                })
                .catch(this.showError)
                .then((_) => {
                    this.tableLoading = false;
                });
        },
        onFilter(){
            if(this.tableLoading) return
            this.tableSearch = this.selectedSearch
            this.urlPush('search', this.selectedSearch)

            this.load()
        },
        sumEvsesFromChargePoints(chargepoints) {
            if (!chargepoints || !chargepoints.length) {
                return 0;
            }
            const totalEvses = chargepoints.map((cp) => cp.evses.length);
            if (!totalEvses) {
                return 0;
            }
            return totalEvses.reduce((a, b) => a + b, 0);
        },
    },
};
</script>
