<template>
    <v-container fluid>
        <AppBar
            :breadcrumbs="[
                { text: 'Providers', to: '/providers' },
                { text: name, icon: 'mdi-earth' },
            ]"
        />

        <v-card :loading="providerLoading" outlined>
            <v-card-text class="pa-0">
                <v-row v-if="provider">
                    <v-col cols="12" sm="4" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <ProviderName
                                        :provider="{ id: provider.id, country_code: provider.country_code , party_id: provider.party_id }"
                                        extra="no-arrow"
                                        type="title"
                                    />
                                </v-list-item-title>
                                <v-list-item-subtitle
                                    >Country - Party</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    provider.name || "-"
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >Name</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    provider.role || "-"
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >Roles</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    provider.backend_user_id || "-"
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >Backend Id</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    provider.user.ocpi_version || "-"
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >OCPI Version</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    provider.user.ocpi_url_versions || "-"
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >URL Versions</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    provider.user.ocpi_url_endpoints || "-"
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >URL Endpoints</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    
                    <v-col cols="12" sm="4" md="3" v-if="provider.user.type === 'hub'">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ provider.user.company ? provider.user.company.name || "-" : "-" }}
                                </v-list-item-title>
                                <v-list-item-subtitle>Hub</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    provider.user.username || "-"
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >Username</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    provider.user.email || "-"
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >Email</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>              
                <v-divider class="mx-0"></v-divider>
                <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                    >
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <div v-if="weneaVersionsEndpoint"> 
                                        {{weneaVersionsEndpoint}}
                                    </div>
                                </v-list-item-title>
                                <v-list-item-subtitle>Wenea versions endpoint</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>
            </v-card-text>
            
            <!-- Company -->
            <CompanyPanel v-if="company" :company="company" />
        
        </v-card>

        <template v-if="provider">

            <!-- Buttons -->
            <v-toolbar flat dense class="my-1 buttons-toolbar">

                <v-btn text color="grey darken-3" :to="`/provider/${provider_id}/http-logs`">
                    <v-icon left>mdi-console</v-icon>
                    HTTP Logs
                </v-btn>

                <v-spacer />

                <v-btn :to="`/connection/${provider.user_id}`" text>
                    <v-icon left>mdi-arrow-left</v-icon>
                    Go to {{ provider.user.type === 'hub' ? 'hub' : 'connection' }}
                </v-btn>

                <v-btn 
                    v-if="!company || !company.provider_id" 
                    :to="`/company/create`" 
                    class="ml-2"
                    text
                    color="accent"
                    outlined>
                        <v-icon left>mdi-plus</v-icon>
                        Create company
                </v-btn>

                <v-btn
                    :to="`/provider/${provider.id}/edit`"
                    text
                    class="ml-2 accent"
                >
                    <v-icon left>mdi-pencil</v-icon>
                    Modify
                </v-btn>

            </v-toolbar>

            <v-tabs 
                fixed-tabs
                color="accent"
            >
                <v-tab v-if="hasRole('EMSP')" style="max-width: none;" active-class="active-role-tab">
                    EMSP
                </v-tab>
                <v-tab v-if="hasRole('CPO')" style="max-width: none;" active-class="active-role-tab">
                    CPO
                </v-tab>
                <v-tab v-if="hasRole('NSP')" style="max-width: none;" active-class="active-role-tab">
                    NSP
                </v-tab>

                <!-- EMSP Tab -->
                <v-tab-item
                    v-if="hasRole('EMSP')"
                >
                    <v-container class="mt-4" fluid>
                        <v-row>

                            <!-- Info -->
                            <v-col cols="12" sm="6"  :md="3" >
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-link-box</v-icon> Information
                                </h2>
                                <v-card class="tabs_v-card" height="73%"> 
                                    <v-card-text >
                                        <ProviderInfo :providerId="provider.id" providerRole="EMSP"/>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            
                            <!-- Commons -->
                            <v-col cols="12" sm="6"  :md="3">
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-equal-box</v-icon> Commons
                                </h2>
                                    <v-card class="tabs_v-card v-card_commons" height="73%" >
                                        <v-card-text class="v-card_chip">
                                            <v-chip class="chip_v-card" color="accent" outlined :to="`/provider/${provider.id}/cron-jobs`">
                                                <v-icon left>mdi-calendar-check</v-icon>
                                                Cron jobs
                                            </v-chip>
                                        </v-card-text>
                                    </v-card>
                            </v-col>
                            
                            <!-- Tokens -->
                            <v-col cols="12" sm="6"  :md="3" >
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-key</v-icon> Tokens
                                </h2>
                                    <v-card class="tabs_v-card clickable" height="73%" :to="`/provider/${provider.id}/emsp/tokens`"> 
                                        <v-card-text >
                                            <ul>
                                                <li><strong>Table:</strong> List of provider current tokens</li>
                                                <li><strong>Import Tool:</strong> Import tokens from provider tool</li>
                                                <li><strong>Auth Test:</strong> Real time authorization test tool</li>
                                            </ul>
                                        </v-card-text>
                                    </v-card>
                            </v-col>

                            <!-- Locations -->
                            <v-col cols="12" sm="6"  :md="3" >
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-folder-marker</v-icon> Locations
                                </h2>
                                <v-card class="tabs_v-card clickable" height="73%" :to="`/provider/${provider.id}/emsp/locations`"> 
                                    <v-card-text >
                                        <ul>
                                            <li><strong>Location groups:</strong> Add, Send and Delete location groups</li>
                                            <li><strong>Wenea locations:</strong> Current location lists with Wenea as provider</li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <!-- Commands -->
                            <v-col cols="12" sm="6"  :md="3" >
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-key</v-icon> Commands
                                </h2>
                                <v-card class="tabs_v-card clickable" height="60%" :to="`/provider/${provider.id}/emsp/commands`"> 
                                    <v-card-text >
                                        <ul>
                                            <li><strong>Table:</strong> List of provider executed commands</li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <!-- Sessions -->
                            <v-col cols="12" sm="6"  :md="3" >
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-triangle-wave</v-icon> Sessions
                                </h2>
                                <v-card class="tabs_v-card clickable" height="60%" :to="`/provider/${provider.id}/emsp/sessions`"> 
                                    <v-card-text >
                                        <ul>
                                            <li><strong>Table:</strong> List of provider sessions as receiver</li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <!-- Cdrs -->
                            <v-col cols="12" sm="6"  :md="3" >
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-credit-card-multiple-outline</v-icon> Cdrs
                                </h2>
                                <v-card class="tabs_v-card clickable" height="60%" :to="`/provider/${provider.id}/emsp/cdrs`">
                                    <v-card-text >
                                        <ul>
                                            <li><strong>Table:</strong> List of provider cdrs</li>
                                            <li><strong>Cdr Processes:</strong> Resend failured cdrs tool</li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <!-- Tariffs -->
                            <v-col cols="12" sm="6"  :md="3" >
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-tag-text-outline</v-icon> Tariffs
                                </h2>
                                <v-card class="tabs_v-card clickable" height="60%" :to="`/provider/${provider.id}/emsp/tariffs`"> 
                                    <v-card-text >
                                        <ul>
                                            <li><strong>Table:</strong> Create and list provider tariffs</li>
                                            <li><strong>Send:</strong> Send tariffs to provider</li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>

                <!-- CPO Tab -->
                <v-tab-item
                    v-if="hasRole('CPO')"
                >
                    <v-container class="mt-4" fluid>
                        <v-row>

                            <!-- Info -->
                            <v-col cols="12" sm="6"  :md="3" >
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-link-box</v-icon> Information
                                </h2>
                                <v-card class="tabs_v-card" height="73%"> 
                                    <v-card-text >
                                        <ProviderInfo :providerId="provider.id" providerRole="CPO"/>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <!-- Commons -->
                            <v-col cols="12" sm="6"  :md="3">
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-equal-box</v-icon> Commons
                                </h2>
                                    <v-card class="tabs_v-card v-card_commons" height="73%">
                                        <v-card-text class="v-card_chip">
                                            <v-chip class="chip_v-card"  color="accent" outlined :to="`/provider/${provider.id}/cron-jobs`">
                                                <v-icon left>mdi-calendar-check</v-icon>
                                                Cron jobs
                                            </v-chip>
                                        </v-card-text>
                                    </v-card>
                            </v-col>
                            
                            <!-- Tokens -->
                            <v-col cols="12" sm="6"  :md="3" >
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-key</v-icon> Tokens
                                </h2>
                                <v-card class="tabs_v-card clickable" height="73%" :to="`/provider/${provider.id}/cpo/tokens`"> 
                                    <v-card-text >
                                        <ul>
                                            <li><strong>Wenea tokens:</strong> Current tokens list with Wenea as provider</li>
                                            <li><strong>Send:</strong> Check and send not sent tokens tool</li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <!-- Locations -->
                            <v-col cols="12" sm="6"  :md="3" >
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-folder-marker</v-icon> Locations
                                </h2>
                                <v-card class="tabs_v-card clickable" height="73%" :to="`/provider/${provider.id}/cpo/locations`"> 
                                    <v-card-text >
                                        <ul>
                                            <li><strong>Table:</strong> List of current provider locations</li>
                                            <li><strong>Import Tool:</strong> Call provider endpoint to import new locations</li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <!-- Commands -->
                            <v-col cols="12" sm="6"  :md="3" >
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-key</v-icon> Commands
                                </h2>
                                <v-card class="tabs_v-card clickable" height="60%" :to="`/provider/${provider.id}/cpo/commands`"> 
                                    <v-card-text >
                                        <ul>
                                            <li><strong>Table:</strong> List of provider executed commands</li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <!-- Sessions -->
                            <v-col cols="12" sm="6"  :md="3" >
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-triangle-wave</v-icon> Sessions
                                </h2>
                                <v-card class="tabs_v-card clickable" height="60%" :to="`/provider/${provider.id}/cpo/sessions`"> 
                                    <v-card-text >
                                        <ul>
                                            <li><strong>Table:</strong> List of provider sessions as sender</li>
                                            <li><strong>Import Tool:</strong> Call provider endpoint to import new sessions</li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <!-- Cdrs -->
                            <v-col cols="12" sm="6"  :md="3" >
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-credit-card-multiple-outline</v-icon> Cdrs
                                </h2>
                                <v-card class="tabs_v-card clickable" height="60%" :to="`/provider/${provider.id}/cpo/cdrs`"> 
                                    <v-card-text >
                                        <ul>
                                            <li><strong>Table:</strong> List of provider cdrs</li>
                                            <li><strong>Import Tool:</strong> Call provider endpoint to import new cdrs</li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <!-- Tariffs -->
                            <v-col cols="12" sm="6"  :md="3" >
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-tag-text-outline</v-icon> Tariffs
                                </h2>
                                <v-card class="tabs_v-card clickable" height="60%" :to="`/provider/${provider.id}/cpo/tariffs`"> 
                                    <v-card-text >
                                        <ul>
                                            <li><strong>Table:</strong> Create and list provider tariffs</li>
                                            <li><strong>Import Tool:</strong> Call provider endpoint to import new tariffs</li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>

                <!-- NSP Tab -->
                <v-tab-item
                    v-if="hasRole('NSP')"
                >
                    <v-container class="mt-4" fluid>
                        <v-row>
                            <!-- Info -->
                            <v-col cols="12" sm="6"  :md="3" >
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-link-box</v-icon> Information
                                </h2>
                                <v-card class="tabs_v-card" height="73%"> 
                                    <v-card-text>
                                        <ProviderInfo :providerId="provider.id" providerRole="NSP"/>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            
                            <!-- Commons -->
                            <v-col cols="12" sm="6"  :md="3">
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-equal-box</v-icon> Commons
                                </h2>
                                    <v-card class="tabs_v-card v-card_commons" height="73%" >
                                        <v-card-text class="v-card_chip">
                                            <v-chip class="chip_v-card" color="accent" outlined :to="`/provider/${provider.id}/credentials`">
                                                <v-icon left>mdi-shield-lock</v-icon>
                                                Credentials
                                            </v-chip>
                                        </v-card-text>
                                        <v-card-text class="v-card_chip">
                                            <v-chip class="chip_v-card" color="accent" outlined :to="`/provider/${provider.id}/cron-jobs`">
                                                <v-icon left>mdi-calendar-check</v-icon>
                                                Cron jobs
                                            </v-chip>
                                        </v-card-text>
                                    </v-card>
                            </v-col>
                            
                            <!-- Locations -->
                            <v-col cols="12" sm="6"  :md="3" >
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-folder-marker</v-icon> Locations
                                </h2>
                                <v-card class="tabs_v-card clickable" height="73%" :to="`/provider/${provider.id}/nsp/locations`"> 
                                    <v-card-text >
                                        <ul>
                                            <li><strong>Location groups:</strong> Add, Send and Delete location groups</li>
                                            <li><strong>Wenea locations:</strong> Current location lists with Wenea as provider</li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <!-- Tariffs -->
                            <v-col cols="12" sm="6"  :md="3" >
                                <h2 class="mb-2">
                                    <v-icon left color='accent'>mdi-tag-text-outline</v-icon> Tariffs
                                </h2>
                                <v-card class="tabs_v-card clickable" height="73%" :to="`/provider/${provider.id}/nsp/tariffs`"> 
                                    <v-card-text >
                                        <ul>
                                            <li><strong>Table:</strong> Create and list provider tariffs</li>
                                            <li><strong>Send:</strong> Send tariffs to provider</li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs>

            <!-- Operaciones -->
            <v-container fluid v-if="provider && !provider.backend_user_id">
                <h2 class="mt-6 mb-2"><v-icon left color='accent'>mdi-wrench</v-icon>Provider to Backend</h2>
                <v-row>
                    <v-col 
                    cols="12"
                    sm="6"
                    :md="4"
                    >
                    <v-card class="fill-height" outlined>
                        <v-card-text>
                        <p>
                            Send data of this provider to backend to create it in your DB.
                        </p>
                        <p>
                            (If it already exists, it will not create it again or modify it.
                            Only for external Providers)
                        </p>
                        </v-card-text>
                        <v-card-actions>
                        <v-btn @click="sendProviderToBackend" text block color='accent'>
                            <v-icon left>mdi-plus</v-icon>
                            Create
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-col>
                </v-row>
            </v-container>

            <!-- Http Call Handler Tool -->
            <v-container fluid>
                <h2 class="mt-6 mb-2">
                    <v-icon left color='accent'>mdi-send-outline</v-icon> Http Call Handler Tool
                </h2>
                <HttpCallHandlerTool class="mt-2" :provider_id="provider.id" />
            </v-container>

        </template>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import HttpCallHandlerTool from "@/components/provider/HttpCallHandlerTool"
import ProviderInfo from "@/components/provider/ProviderInfo"
import ProviderName from "@/components/provider/ProviderName"
import CompanyPanel from "@/components/company/CompanyPanel"

export default {
    name: "provider",
    components: {
        AppBar,
        HttpCallHandlerTool,
        ProviderInfo,
        ProviderName,
        CompanyPanel
    },
    data() {
        return {

            providerLoading: true,
            providerIcon: null,

            provider: null,
            user: null,
            company: null,
            credentials: null,
            tokens: null,

            weneaCredentialsUrl: process.env.VUE_APP_WENEA_CREDENTIALS_URL

        }
    },
    computed: {
        name() {
            if(this.provider && this.provider.company) return `${this.provider.company.name} (${this.provider.role})`
            else if(this.provider) return `${this.provider.country_code}-${this.provider.party_id} (${this.provider.role})`
            else return "-"
                
        },
        isExternal() {
            return this.provider && this.provider.id !== 1
        },
        provider_id(){
            return this.provider ? this.provider.id : 0
        },
        weneaVersionsEndpoint(){
            if (this.user?.ocpi_own_url == this.weneaCredentialsUrl) {
                 return this.weneaCredentialsUrl + '/versions'
            }
            return null
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async loadData() {
            let id = this.$router.currentRoute.params.id
            if (!id) {
                return
            }
            this.providerLoading = true
            let provider = await this.$api
                .get(`/provider/${id}`)
                .then((resp) => resp.data)
                .catch(this.showError)

            if (!provider) {
                this.showError(`Provider #${id} not found`)
                return
            }

            this.user = provider.user
            this.tokens = provider.tokens.filter(token => !token.is_revoked)
            this.credentials = provider.credentials
            this.provider = provider

            this.company = await this.$api
                    .get(`/companies?provider_id=${this.provider.id}`)
                    .then(resp => resp.data)
                    .catch(this.showError)

            this.providerLoading = false
        },
        sendProviderToBackend() {
            if (!this.provider || !this.provider.id) {
                this.showError("Provider is not saved to the database. Save it first");
            }

            this.$api
                .get(`provider/${this.provider.id}/send-to-backend`)
                .then((resp) => {
                this.provider.backend_user_id = resp.data.backend_user_id;
                this.showSuccess("Provider data sent to backend");
                })
                .catch(this.showError);
        },
        hasRole(role){
            return this.provider && this.provider.role && this.provider.role.includes(role)
        },
    }
}
</script>

<style scope>
    .buttons-toolbar .v-toolbar__content {
        padding: 0 !important;
    }
    .v-expansion-panel-content__wrap {
        padding: 0 !important;
        margin: 0 !important;
    }
    .active-role-tab::before {
        content: '';
        display: block;
        position: absolute;
        opacity: 0.08 !important;
        background-color: var(--v-accent-lighten1) !important;
    }
    .active-role-tab:hover::before {
        opacity: 0.12 !important;
    }
    .tabs_v-card {
        box-shadow: 0px 3px 0px -10px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
        padding-bottom: 18px;
    }
    .tabs_v-card.clickable:hover::before {
        content: '';
        display: block;
        position: absolute;
        opacity: 0.04;
        background-color: var(--v-accent-lighten1) !important;
    }
    .tabs_v-card.clickable:hover * {
        color: var(--v-accent-lighten1) !important;
    }
    .v-card_commons{
        display: grid;
        align-content: center
    }
    .v-card_chip{
        text-align: center;
    }
    .chip_v-card {
        width: 50%;
        justify-content: space-around;
    }
    .alert_icon {
        color: #ec8a00 !important;
    }
    .v-chip .v-icon {
        padding-right: 3px;
    }
</style>