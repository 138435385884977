<template>
    <v-container fluid>

        <AppBar title="Allowed real-time authorizations" icon="mdi-key-wireless" />

        <!-- Table filters -->
        <v-row class="mb-2 px-2" align="center">
            <v-col cols="12" md="2">
                <v-text-field
                v-model="selectedSearch"
                    placeholder="Search"
                    append-icon="mdi-magnify"
                    color="accent"
                    hide-details
                    clearable
                    autofocus
                    @keyup.enter="onFilter"
                ></v-text-field>
            </v-col>     

            <v-col cols="12" md="2">
                <v-autocomplete
                    v-model="selectedProvider"
                    :items="providers"
                    label="Provider"
                    color="accent"
                    clearable
                    hide-details
                >
                    <template v-slot:item="data">
                        <ProviderSelectorTemplate :provider="data.item" />
                    </template>
                </v-autocomplete>
            </v-col>

            <v-btn 
                class="mt-5 mr-2"
                color="accent"
                :loading="loading"
                @click="onFilter"
            >
                <v-icon>mdi-filter</v-icon>
            </v-btn>
        </v-row>

        <!-- Data table -->
        <v-data-table
            class="accent-table rounded-table"
            :items="allowedRTAs"
            :headers="[
                { text: 'Id.', value: 'id' },
                { text: 'Provider', value: 'provider' },
                { text: 'Location id.', value: 'location' },
                { text: 'Chargepoint id.', value: 'chargepoint' },
                { text: 'RFID', value: 'rfid' },
                { text: 'Created at', value: 'created_at' },
            ]"
            dense
            :loading="loading"
            :search="tableSearch"
            :custom-filter="dataTableDeepSearch"
            sort-by="created_at"
            sort-desc
        >
            <template v-slot:item.provider="{ item }">
                <ProviderName
                    :provider="{ id: item.provider_id, country_code: item.provider_country, party_id: item.provider_party }"
                />
            </template>
            <template v-slot:item.location="{ item }">
                <LocationName
                    :location="{ id: item.location_id, name: item.location_name }"
                    type="table"
                />
            </template>
            <template v-slot:item.chargepoint="{ item }">
                <ChargepointName
                    :chargepoint="{ id: item.chargepoint_id, name: item.chargepoint_reference }"
                    type="table"
                />
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | dateFullFormat }}
            </template>
        </v-data-table>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"
import ProviderName from "@/components/provider/ProviderName"
import LocationName from "@/components/location/LocationName"
import ChargepointName from "@/components/chargepoint/ChargepointName"

export default {
    name: "real-time-authorizations",
    components: {
        AppBar,
        ProviderSelectorTemplate,
        ProviderName,
        LocationName,
        ChargepointName
    },
    data() {
        return {
            loading: false,
            allowedRTAs: [],
            providers: [],
            selectedSearch: null,
            tableSearch: null,
            selectedProvider: null,
        };
    },
    mounted() {
        this.load()
    },
    watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },
    },
    methods: {
        load(){
            this.$api.get("/providers")
                .then(res => {
                    this.providers = res.data.map(p => { 
                        return { 
                            ...p, 
                            text: p.role ? `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username} (${p.role})` : `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username}`, 
                            value: p.id 
                        } 
                    })
                })
                .catch(this.showError)
        },
        async loadRTA(){
            this.loading = true
            await this.$api.get(`/allowed-real-time-authorizations?limit=300`)
                .then((resp) => this.allowedRTAs = resp.data)
                .catch(this.showError)
                .then(() => this.loading = false)
        },
        async onFilter(){
            if(this.loading) return
            this.tableSearch = this.selectedSearch
            this.urlPush('search', this.selectedSearch)
            this.urlPush('provider', this.selectedProvider)

            await this.loadRTA()

            if(this.selectedProvider){
                this.allowedRTAs = this.allowedRTAs.filter(allowedRTA => allowedRTA.provider_id == this.selectedProvider)
            }
        }
    }
};
</script>