import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"500px","content-class":"custom-scollbar"},on:{"click:outside":_vm.close},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"accent white--text"},[_c(VIcon,{attrs:{"large":"","left":"","color":"white"}},[_vm._v("mdi-credit-card-multiple-outline")]),_c('span',{staticClass:"headline"},[_vm._v("Invoice Cdrs")])],1),_c(VSpacer),_c(VDivider),_c(VCardText,{staticClass:"pa-4"},[_c('h4',{staticClass:"mb-2"},[_vm._v(" Do you want to set downloaded CDRs as invoiced? ")]),_vm._v(" This action will set "),_c(VChip,{attrs:{"small":""}},[_vm._v("CDR Invoiced At")]),_vm._v(" to current date. ")],1),_c(VCardActions,[_c(VBtn,{attrs:{"depressed":"","color":"accent","loading":_vm.loading},on:{"click":_vm.invoiceCdrs}},[_vm._v("Agree")]),_c(VSpacer),_c(VBtn,{attrs:{"depressed":""},on:{"click":function($event){_vm.show = false}}},[_vm._v("Disagree")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }