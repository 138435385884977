import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.provider)?_c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"breadcrumbs":[
        { text: 'Providers', to: '/providers' },
        { text: _vm.name, icon: 'mdi-earth', to: ("/provider/" + _vm.provider_id) },
        { text: 'Cdrs' }
        ]}}),_c(VContainer,{attrs:{"fluid":""}},[_c('h2',{staticClass:"mt-6"},[_c(VIcon,{attrs:{"left":"","color":"accent"}},[_vm._v("mdi-credit-card-multiple-outline")]),_vm._v(" CDRs ")],1),_c('ProviderCdrs',{attrs:{"providerId":_vm.provider.id,"providerRole":_vm.url}})],1),(_vm.hasRole('EMSP'))?_c(VContainer,{attrs:{"fluid":""}},[_c('h2',{staticClass:"mt-6"},[_c(VIcon,{attrs:{"left":"","color":"accent"}},[_vm._v("mdi-calculator-variant")]),_vm._v(" CDR Processes ")],1),_c('CdrProcesses',{attrs:{"providerId":_vm.provider.id,"toolbar-right":""}})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }