<template>
    <div class="providers-selector custom-scollbar" @scroll="(e) => scrollIsOnTop = e.target.scrollTop == 0">
        <div class="selector-search-container">
            <v-btn
                v-bind:class="{ 'display': scrollIsOnTop || showSearchBar }"
                class="selector-search-button"
                text
                icon
                color="accent"
                @click="() => { showSearchBar = !showSearchBar; search = null }"
            >
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-text-field
                ref="selectorSearch"
                v-bind:class="{ 'display': showSearchBar }"
                class="selector-search ma-0 pt-0"
                v-model="search" 
                clearable
                color="accent"
                placeholder="Search"
                hide-details
            >
            </v-text-field>
        </div>
        
        <div :class="`selector-search-spacer ${showSearchBar ? 'display' : ''}`"></div>

        <v-data-table
            v-bind:class="{ 
                'all-selected': selectedProviders.length == items.length,
                'hide-header': hideHeader
            }"
            :items="items"
            :headers="[
                { value: 'data-table-select' },
                { text: 'Select all external providers', value: 'providers' },
            ]"
            dense 
            sort-by="providers"
            sort-desc
            v-model="selectedProviders"
            disable-sort
            :items-per-page="-1"
            :search="search"
            :custom-filter="(value, search, item) => customFilter(value, search, item)"
            hide-default-footer
            show-select
        >
            <template v-slot:item.providers="{ item }">
                <ProviderSelectorTemplate :provider="item" />
            </template>
        </v-data-table>
    </div>
</template>

<script>
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"

export default {
    name: "providers-selector-box",
    components: {
        ProviderSelectorTemplate
    },
    props: {
        value: Array,
        items: Array
    },
    data() {
        return {
            loading: false,
            search: null,
            filteredItems: new Set(),
            filteredItemsSize: 0,
            scrollIsOnTop: true,
            showSearchBar: false,
            showReport: false,
            result: null
        }
    },
    watch: {
        showSearchBar(show) {
            if (show) { this.$refs.selectorSearch.focus() }
        }
    },
    computed:{
        selectedProviders: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        hideHeader() {
            return this.search != null && this.filteredItemsSize <= 1
        }
    },
    methods: {
        customFilter(value, search, item){
            const paramsToSearch = { 
                country_code: item.country_code, 
                party_id: item.party_id, 
                role: item.party_id, 
                companyName: item.user.company ? item.user.company.name : item.user.username
            } 

            const show = this.dataTableDeepSearch(value, search, paramsToSearch)
            if (show){ this.filteredItems.add(item) } 
            else { this.filteredItems.delete(item) }
            this.filteredItemsSize = this.filteredItems.size
            return show
        }
    }
}
</script>

<style>
    .providers-selector .selector-search-container {
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 3 !important;
    }
    .selector-search-button {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        width: 36px !important;
        min-width: 36px !important;
        right: 0;
        overflow: hidden;
        background: transparent;
        z-index: 4;
        padding: 0 !important;
        margin-right: 4px;
        transition: opacity 0.5s, visibility 0.5s;
    }
    .selector-search-button.display {
        visibility: visible;
        opacity: 1;
    }
    .selector-search-spacer {
        height: 0;
        transition: height 0.5s;
    }
    .selector-search-spacer.display {
        height: 32px;
    }
    .providers-selector .selector-search {
        position: absolute;
        max-width: 400px;
        width: 0%;
        right: 0;
        overflow: hidden;
        padding-bottom: 1px;
        background: white;
        transition: width 0.5s;
    }
    .providers-selector .selector-search.display {
        width: 100%;
    }
    .providers-selector .selector-search .v-input__icon--clear {
        margin-top: 2px;
        margin-right: 36px;
    }
    .providers-selector .selector-search .v-text-field__slot {
        padding-left: 16px !important;
    }
    .providers-selector {
        border: 1px solid rgba(0, 0, 0, 0.12) !important;
        max-width: 400px;
        max-height: 161px;
        display: block;
        overflow-y: auto;
    }
    .providers-selector .v-data-table-header tr th {
        color: black !important;
    }
    .providers-selector tr th:last-child {
        padding-right: 40px!important;
    }
    .providers-selector tr th:first-child,
    .providers-selector tr td:first-child {
        padding-right: 0!important;
    }
    .providers-selector tr th:last-child,
    .providers-selector tr td:last-child {
        padding-left: 0!important;
    }
    .providers-selector .v-simple-checkbox,
    .providers-selector .v-icon {
        color: var(--v-accent-lighten1) !important;
    }
    .providers-selector .theme--light.v-data-table tbody tr.v-data-table__selected,
    .providers-selector .all-selected .v-data-table-header {
        background: transparent !important; 
        position: relative;
    }
    .providers-selector .theme--light.v-data-table tbody tr.v-data-table__selected::after,
    .providers-selector .all-selected .v-data-table-header::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        opacity: 0.08;
        background-color: var(--v-accent-lighten1) !important;
        border-radius: inherit;
        color: inherit;
        pointer-events: none;
        z-index: 2;
    }
    .providers-selector .hide-header .v-data-table-header {
        display: none;
    }
</style>