<template>
    <v-row class=" mb-2">
        <v-col cols="12" sm="12" :md="columnSize"
            v-for="chargepoint in chargepointList"
            :key="chargepoint.id"
        >
            <ChargepointStructureCard :chargepoint="chargepoint" :parentColumnSize="columnSize" :mode="mode"/>
        </v-col>
    </v-row>
</template>

<script>
    import ChargepointName from "@/components/chargepoint/ChargepointName"
    import ChargepointStructureCard from "@/components/chargepoint/ChargepointStructureCard.vue";

    export default {
        name: "locationStructure",
        components: {
            ChargepointName,
            ChargepointStructureCard
        },
        props: ["chargepoints", "mode"],

        computed: {
            chargepointList() {
                //Order chargepoints by evses length
                return this.chargepoints.sort((a, b) => {
                    return b.evses?.length - a.evses?.length
                })
            },
            columnSize() {
                return this.chargepointList.length > 1 ? 6 : 12
            },
        }
    }
</script>