import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-right mb-2 mr-2"},[_c(VBtn,{attrs:{"outlined":"","color":"accent","small":""},on:{"click":_vm.showDialog_addGroup}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add group ")],1)],1),_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"headers":[
          { value: 'action', sortable:false, cellClass: 'td-linkto' },
          { text: 'Id.', value: 'id' },
          { text: 'Name', value: 'name' },
          { text: 'Private?', value: 'is_private' },
          { text: 'Backend Id', value: 'backend_id' },
          { text: 'Updated at', value: 'updated_at' },
          { text: 'Actions', value: 'actions', sortable: false }
      ],"items":_vm.locationGroups,"disable-sort":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c(VBtn,{attrs:{"plain":"","small":"","to":("/location-group/" + (item.id))}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1)]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c(VBtn,{staticClass:"mr-2",attrs:{"color":"accent","depressed":"","disabled":!_vm.provider || !_vm.provider.role.includes('EMSP')},on:{"click":function($event){$event.stopPropagation();return _vm.sendLocations(item)}}},[_vm._v("Send")]),_c(VBtn,{staticClass:"mr-2",attrs:{"color":"accent","depressed":"","disabled":!_vm.provider || !_vm.provider.role.includes('EMSP')},on:{"click":function($event){$event.stopPropagation();return _vm.deleteLocationGroupUser(item)}}},[_vm._v("Delete")])]}}])}),_c('ProviderAddLocationGroupDialog'),_c('SendLocationGroupDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }