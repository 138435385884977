<template>
  <div>
    <v-data-table
        :headers="[
            {value: 'go_to', sortable:false, cellClass: 'td-linkto' },
            {text: 'Id.', value: 'id' },
            {text: 'Start', value: 'start_date_time'},
            {text: 'End', value: 'end_date_time'},
            {text: 'Sender / Receiver', value: 'sender_receiver' },
            {text: 'Ocpi Id.', value: 'ocpi_id' },
            {text: 'Energy (kWh)', value: 'kwh'},
            {text: 'Id. DTM', value: 'dtm_session_id'},
            {text: 'Backend uuid', value: 'backend_uuid'},
            {text: 'Actions', value: 'actions'},
        ]"
        :items="sessionsWithoutCdrs"
        :loading="loading"  
        class="accent-table rounded-table"
        sort-by="id"
        dense
        no-data-text="No sessions found without CDRs"
    >
       <template v-slot:item.go_to="{ item }">
            <v-btn plain
                small
                :to="`/session/${item.id}`" 
                >
                <v-icon small>mdi-arrow-top-right</v-icon>
            </v-btn>
        </template>
        <template v-slot:item.sender_receiver="{ item }">
          <!-- Sender -->
          <v-chip v-if="item.sender_id === 1" color="secondary" small>{{ item.sender_name }}</v-chip>
          <ProviderName
            v-else
            :provider="{ id: item.sender_id, country_code: item.sender_name.split('-')[0] , party_id: item.sender_name.split('-')[1] }"
          />
          <!-- Arrow -->
          <v-icon color="secondary">mdi-arrow-right</v-icon>
          <!-- Receiver -->
          <v-chip v-if="item.receiver_id === 1" color="secondary" small>{{ item.receiver_name }}</v-chip>
          <ProviderName
            v-else
            :provider="{ id: item.receiver_id, country_code: item.receiver_name.split('-')[0] , party_id: item.receiver_name.split('-')[1] }"
          />
        </template>
        <template v-slot:item.start_date_time="{ item }">
            {{ item.start_date_time | dateFullFormat }}
        </template>
        <template v-slot:item.end_date_time="{ item }">
            {{ item.end_date_time | dateFullFormat }}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-btn
              small
              outlined
              color="accent"
              :disabled="!!item.backend_uuid && processed_backend_uuids.includes(item.backend_uuid)"
              :loading="!!item.backend_uuid && processed_backend_uuids.includes(item.backend_uuid)"
              @click="() => generateCdr(item.backend_uuid)"
            >
              <v-icon left>mdi-credit-card-multiple-outline</v-icon> Generate CDR
            </v-btn>
        </template>
    </v-data-table>

  </div>
</template>

<script>
import ProviderName from "@/components/provider/ProviderName"

export default {
    components: {
        ProviderName
    },
    data() {
      return {
        loading: false,
        sessionsWithoutCdrs: [],
        processed_backend_uuids: [],
        alert: {
          show: false,
          success: false,
          message: null
        }
      }
    },
    async mounted(){  
        this.loadSessionsWithoutCdrs()      
    },
    methods: {
      loadSessionsWithoutCdrs(){
        this.loading = true

        this.$api.get(`/sessions-without-cdrs`)
            .then(resp => this.sessionsWithoutCdrs = resp.data.map(session => {
                  return {
                      id: session.id,
                      ocpi_id: session.ocpi_id,
                      sender_id: session.senderProvider?.id,
                      sender_name: session.senderProvider?.user?.username,
                      receiver_id: session.receiverProvider?.id,
                      receiver_name: session.receiverProvider?.user?.username,
                      dtm_session_id: session.dtm_session_id,
                      kwh: session.kwh,
                      start_date_time: session.start_date_time,
                      end_date_time: session.end_date_time,
                      backend_uuid: session.backend_uuid
                  }
              })
            )
            .catch(this.showError)
            .then(() => this.loading = false)
      },
      generateCdr(backend_uuid){
        this.processed_backend_uuids.push(backend_uuid)
        if (!backend_uuid) {
          this.showError('Session has no backend_uuid')
          return 
        }

        this.$api.post(`generate-cdr/${backend_uuid}`)
          .then(() => this.showCustomAlert('success', 'Process has been executed.', { to:`/cdr-processes?search=${backend_uuid}`, text:'Check CDR process', icon:'mdi-arrow-top-right' }))
          .catch(this.showError)
          .then(() => {
            setTimeout(() => {
              this.processed_backend_uuids = this.processed_backend_uuids.filter(uuid => uuid != backend_uuid)
              this.loadSessionsWithoutCdrs()
            }, 2000)
          })
      }
    }
}
</script>