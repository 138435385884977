<template>
    <v-card outlined>
        <v-toolbar dark dense color="accent" elevation="0">
            <v-app-bar-nav-icon><v-icon>{{ incoming ? 'mdi-phone-incoming' : 'mdi-phone-outgoing' }}</v-icon></v-app-bar-nav-icon>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-list dense class="pa-0">
            <v-list-group
                v-for="(item, index) in http_items"
                :key="item.timestamp"
                no-action
                active-class="grey lighten-2"
            >
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title color="grey">
                            <span>{{ item.timestamp | dateTimeFormat }}</span>
                            <span class="pl-4">{{ `${item.request.method} ${truncateUrl(item.request.url, 32)}` }}</span>
                        </v-list-item-title>
                    </v-list-item-content>
                </template>

                <HttpCallInfo :http_info="item" />

                <v-divider v-if="index != (http_items.length -1)" />

            </v-list-group>
        </v-list>
    </v-card>
</template>

<script>
import HttpCallInfo from "@/components/http/HttpCallInfo"

export default {
    name: "http-list",
    components: {
        HttpCallInfo
    },
    props: {
        incoming: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        },
        http_items: {
            type: Array,
            default: null
        }
    },
    methods: {
        truncateUrl(string, length){
            if( string.length < length ){
                return string
            } else {
                return '...'+string.slice(-1*length)
            }
        }
    }
}
</script>

<style>
    .jv-code {
        padding: 0 !important
    }
    .json-text-orange {
        white-space: nowrap;
        color: #e08331;
        font-size: 14px;
        font-family: Consolas, Menlo, Courier, monospace;
    }
</style>