import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDataTable,{attrs:{"headers":[
        { text: '', value: 'icon' },
        { text: 'Id.', value: 'id' },
        { text: 'OCPI Id.', value: 'ocpi_id' },
        { text: 'Standard', value: 'standard', width: '200' },
        { text: 'Format', value: 'format', width: '150' },
        { text: 'Power type', value: 'power_type', width: '150' },
        { text: 'Max power', value: 'max_electric_power' },
        { text: 'Max voltage', value: 'max_voltage' },
        { text: 'Max amperage', value: 'max_amperage' },
        { text: 'Num.', value: 'number' },
        { text: 'Core Id.', value: 'backend_id' },
        { text: 'Updated', value: 'last_updated' },
        { text: 'Deleted', value: 'deleted_at' }
    ],"items":_vm.connectors,"disable-sort":"","disable-pagination":"","hide-default-footer":"","hide-default-header":"","dense":""},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
    var item = ref.item;
return [_c(VRow,{staticClass:"ml-7"},[_c(VIcon,{attrs:{"color":"accent"}},[_vm._v("mdi-power-plug")])],1)]}},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-caption"},[_vm._v("Id: ")]),_c('div',[_vm._v(_vm._s(item.id))])]}},{key:"item.ocpi_id",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-caption"},[_vm._v("OCPI Id: ")]),_c('div',[_vm._v(_vm._s(item.ocpi_id))])]}},{key:"item.number",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-caption"},[_vm._v("Label:")]),_c('div',[_vm._v(_vm._s(item.label))])]}},{key:"item.standard",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-caption"},[_vm._v("Standard: ")]),_c('div',[_vm._v(_vm._s(item.standard))])]}},{key:"item.format",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-caption"},[_vm._v("Format: ")]),_c('div',[_vm._v(_vm._s(item.format))])]}},{key:"item.power_type",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-caption"},[_vm._v("Power type: ")]),_c('div',[_vm._v(_vm._s(item.power_type))])]}},{key:"item.max_electric_power",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-caption"},[_vm._v("Max power: ")]),_c('div',[_vm._v(_vm._s(parseFloat(item.max_electric_power / 1000).toFixed(1))+"kW")])]}},{key:"item.max_voltage",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-caption"},[_vm._v("Max voltage: ")]),_c('div',[_vm._v(_vm._s(item.max_voltage))])]}},{key:"item.max_amperage",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-caption"},[_vm._v("Max amperage: ")]),_c('div',[_vm._v(_vm._s(item.max_amperage))])]}},{key:"item.backend_id",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-caption"},[_vm._v("Core Id: ")]),_c('div',[_vm._v(_vm._s(item.backend_id))])]}},{key:"item.last_updated",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-caption"},[_vm._v("Last updated: ")]),_c('div',[_vm._v(_vm._s(_vm._f("dateFullFormat")(item.last_updated)))])]}},{key:"item.deleted_at",fn:function(ref){
    var item = ref.item;
return [(item.deleted_at)?_c(VCol,[_c(VRow,[_c('div',{staticClass:"text-caption"},[_vm._v("Deleted: ")])]),_c(VRow,[_c('div',[_vm._v(_vm._s(_vm._f("dateFullFormat")(item.deleted_at)))]),_c(VChip,{staticClass:"ml-4",attrs:{"outlined":"","color":"error","x-small":""}},[_vm._v("REMOVED")])],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }