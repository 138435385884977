<template>

  <v-container fluid class="pt-0">

    <AppBar title="Cdr processes" icon="mdi-calculator-variant" />

    <CdrProcesses/>

  </v-container>
  
</template>

<script>
import AppBar from "@/components/AppBar"
import CdrProcesses from "@/components/cdr-process/CdrProcesses"

export default {
    components: {
        AppBar,
        CdrProcesses
    },
    watch: {
    },
    methods: {

    },
};
</script>