import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"title":"Sessions","icon":"mdi-triangle-wave"}}),_c(VRow,{staticClass:"mb-2 px-2",attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VAutocomplete,{attrs:{"items":_vm.providers,"label":"Sender","color":"accent","clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('ProviderSelectorTemplate',{attrs:{"provider":data.item}})]}}]),model:{value:(_vm.selectedSender),callback:function ($$v) {_vm.selectedSender=$$v},expression:"selectedSender"}})],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VAutocomplete,{attrs:{"items":_vm.providers,"label":"Receiver","color":"accent","clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('ProviderSelectorTemplate',{attrs:{"provider":data.item}})]}}]),model:{value:(_vm.selectedReceiver),callback:function ($$v) {_vm.selectedReceiver=$$v},expression:"selectedReceiver"}})],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VAutocomplete,{attrs:{"items":_vm.countries,"label":"Country","color":"accent","clearable":"","hide-details":""},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","color":"accent","min-width":"auto","hide-details":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Date","prepend-icon":"mdi-calendar","readonly":"","clearable":"","hide-details":""},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},[_c(VDatePicker,{attrs:{"no-title":"","hide-details":""},on:{"input":function($event){_vm.showDatePicker = false}},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"1"}},[_c(VCheckbox,{attrs:{"label":"Without CDR","color":"accent","hide-details":""},model:{value:(_vm.selectedWithoutCDR),callback:function ($$v) {_vm.selectedWithoutCDR=$$v},expression:"selectedWithoutCDR"}})],1),_c(VBtn,{staticClass:"mt-5 mr-2",attrs:{"color":"accent","loading":_vm.tableLoading},on:{"click":_vm.onFilter}},[_c(VIcon,[_vm._v("mdi-filter")])],1),_c(VSpacer),_c(VBtn,{staticClass:"mt-5 mr-2",attrs:{"color":"accent","loading":_vm.loadingCSV,"outlined":""},on:{"click":_vm.createCSV}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-file-download-outline")]),_vm._v(" CSV ")],1)],1),(_vm.sessions)?_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"headers":[
            { value: 'action', sortable:false, cellClass: 'td-linkto' },   
            { text: 'Status', value: 'status', align: 'center', width: '110'},
            { text: 'Start', value: 'start_date_time' },
            { text: 'End', value: 'end_date_time' },
            { text: 'Sender / Receiver', value: 'sender_receiver' },
            { text: 'Location', value: 'location' },
            { text: 'CDR', value: 'cdr' },
            { text: 'Charge point', value: 'chargepoint' },
            { text: 'Energy (kWh)', value: 'kwh' },
            { text: 'Id. DTM', value: 'dtm_session_id' } ],"items":_vm.sessions,"items-per-page":_vm.tableOptions.itemsPerPage,"server-items-length":_vm.pagination.serverItemsLength,"loading":_vm.tableLoading,"footer-props":{ 'items-per-page-options': _vm.pagination.itemsPerPageOptions },"disable-sort":"","dense":""},on:{"update:options":this.updateTableOptions},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c(VBtn,{attrs:{"plain":"","small":"","to":("/session/" + (item.id))}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1)]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('StatusChip',{attrs:{"status":item.status}})]}},{key:"item.sender_receiver",fn:function(ref){
        var item = ref.item;
return [(item.sender_id === 1)?_c(VChip,{attrs:{"color":"secondary","small":""}},[_vm._v(_vm._s(item.sender_name))]):_c('strong',[_c('ProviderName',{attrs:{"provider":item.senderProvider}})],1),_c(VIcon,{attrs:{"color":"secondary"}},[_vm._v("mdi-arrow-right")]),(item.receiver_id === 1)?_c(VChip,{attrs:{"color":"secondary","small":""}},[_vm._v(_vm._s(item.receiver_name))]):_c('strong',[_c('ProviderName',{attrs:{"provider":item.receiverProvider}})],1)]}},{key:"item.location",fn:function(ref){
        var item = ref.item;
return [(item.location)?_c('LocationName',{attrs:{"location":{ id: item.location.id, name: item.location.name },"type":"table"}}):_vm._e()]}},{key:"item.chargepoint",fn:function(ref){
        var item = ref.item;
return [_c('ChargepointName',{attrs:{"chargepoint":{ id: item.chargepoint_id, name: item.chargepoint_name },"type":"table"}})]}},{key:"item.start_date_time",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFullFormat")(item.start_date_time))+" ")]}},{key:"item.end_date_time",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFullFormat")(item.end_date_time))+" ")]}},{key:"item.cdr",fn:function(ref){
        var item = ref.item;
return [(!item.cdr && item.status != 'COMPLETED')?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s('mdi-information')+" ")])]}}],null,true)},[_c('span',[_vm._v("CDRs should only be generated for COMPLETED sessions")])]):(item.kwh > 0.2)?_c(VIcon,{attrs:{"color":item.cdr ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.cdr ? 'mdi-check-circle' : 'mdi-close-circle')+" ")]):_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s('mdi-check-circle')+" ")])]}}],null,true)},[_c('span',[_vm._v("Less or equal than 0.2 kWh, it is correct to not have CDR")])])]}}],null,false,967426678)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }