import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.provider)?_c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"breadcrumbs":[
        { text: 'Providers', to: '/providers' },
        { text: _vm.name, icon: 'mdi-earth', to: ("/provider/" + _vm.provider_id) },
        { text: 'CronJobs' }
        ]}}),_c('h2',{staticClass:"mb-2"},[_c(VIcon,{attrs:{"left":"","color":"accent"}},[_vm._v("mdi-calendar-check")]),_vm._v(" Cron Jobs ")],1),_c('CronJobs',{attrs:{"provider_id":_vm.provider_id,"roles":_vm.provider.role.split(',')}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }