import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"title":"Invoices","icon":"mdi-receipt-text-check-outline"}}),_c(VRow,{staticClass:"mb-2 px-2",attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VAutocomplete,{attrs:{"items":_vm.providers,"label":"Provider","color":"accent","clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('ProviderSelectorTemplate',{attrs:{"provider":data.item}})]}}]),model:{value:(_vm.selectedProvider),callback:function ($$v) {_vm.selectedProvider=$$v},expression:"selectedProvider"}})],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VAutocomplete,{attrs:{"items":_vm.countries,"label":"Country","color":"accent","clearable":"","hide-details":""},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),(_vm.selectedFilterBy == 'month')?_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","color":"accent","min-width":"auto","hide-details":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"value":_vm._f("monthFormat")(_vm.selectedMonth),"label":"Month","prepend-icon":"mdi-calendar","readonly":"","hide-details":""}},'v-text-field',attrs,false),on))]}}],null,false,2530543677),model:{value:(_vm.showMonthPicker),callback:function ($$v) {_vm.showMonthPicker=$$v},expression:"showMonthPicker"}},[_c(VDatePicker,{attrs:{"header-color":"accent","type":"month","no-title":"","hide-details":""},model:{value:(_vm.selectedMonth),callback:function ($$v) {_vm.selectedMonth=$$v},expression:"selectedMonth"}})],1)],1):_vm._e(),(_vm.selectedFilterBy == 'day')?_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","color":"accent","min-width":"auto","hide-details":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Date From","prepend-icon":"mdi-calendar","readonly":"","hide-details":""},model:{value:(_vm.selectedDateFrom),callback:function ($$v) {_vm.selectedDateFrom=$$v},expression:"selectedDateFrom"}},'v-text-field',attrs,false),on))]}}],null,false,1002287494),model:{value:(_vm.showDateFromPicker),callback:function ($$v) {_vm.showDateFromPicker=$$v},expression:"showDateFromPicker"}},[_c(VDatePicker,{attrs:{"no-title":"","hide-details":""},model:{value:(_vm.selectedDateFrom),callback:function ($$v) {_vm.selectedDateFrom=$$v},expression:"selectedDateFrom"}})],1)],1):_vm._e(),(_vm.selectedFilterBy == 'day')?_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VMenu,{ref:"menu",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","color":"accent","min-width":"auto","hide-details":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Date To","prepend-icon":"mdi-calendar","readonly":"","clearable":"","hide-details":""},model:{value:(_vm.selectedDateTo),callback:function ($$v) {_vm.selectedDateTo=$$v},expression:"selectedDateTo"}},'v-text-field',attrs,false),on))]}}],null,false,3787205571),model:{value:(_vm.showDateToPicker),callback:function ($$v) {_vm.showDateToPicker=$$v},expression:"showDateToPicker"}},[_c(VDatePicker,{attrs:{"no-title":"","hide-details":""},model:{value:(_vm.selectedDateTo),callback:function ($$v) {_vm.selectedDateTo=$$v},expression:"selectedDateTo"}})],1)],1):_vm._e(),_c(VCol,{attrs:{"cols":"12","md":"1"}},[_c(VBtnToggle,{staticClass:"pt-2",attrs:{"mandatory":"","dense":""},model:{value:(_vm.selectedFilterBy),callback:function ($$v) {_vm.selectedFilterBy=$$v},expression:"selectedFilterBy"}},[_c(VBtn,{staticClass:"min-with-80",attrs:{"value":"month","color":"accent","text":""}},[_vm._v(" Month ")]),_c(VBtn,{staticClass:"min-with-80",attrs:{"value":"day","color":"accent","text":""}},[_vm._v(" Day ")])],1)],1),_c(VBtn,{staticClass:"mt-2 ml-15",attrs:{"color":"accent","loading":_vm.tableLoading},on:{"click":_vm.onFilter}},[_c(VIcon,[_vm._v("mdi-filter")])],1),_c(VSpacer),_c(VBtn,{staticClass:"mt-2 mr-2",attrs:{"color":"accent","loading":_vm.loadingCSV,"outlined":""},on:{"click":_vm.createCSV}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-file-download-outline")]),_vm._v(" CSV ")],1)],1),(_vm.cdr_sessions)?_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"headers":[
            { value: 'action', sortable:false, cellClass: 'td-linkto' },   
            { text: 'Start', value: 'start_date_time', width: 110 },
            { text: 'End', value: 'end_date_time', width: 110 },
            { text: 'Provider', value: 'provider' },
            { text: 'Location', value: 'location' },
            { text: 'Charge point', value: 'chargepoint' },
            { text: 'Connector Number', value: 'connector.number', width: 100 },
            { text: 'Connector Max power', value: 'connector.max_electric_power', width: 100 },
            
            { text: 'Energy', value: 'kwh', width: 100 },
            { text: 'Tariff', value: 'tariff', width: 100 },
            { text: 'Cost', value: 'total_cost' },

            { text: 'DTM Id.', value: 'dtm_session_id' },
            { text: 'Core UID.', value: 'backend_uuid' } ],"items":_vm.cdr_sessions,"items-per-page":_vm.tableOptions.itemsPerPage,"server-items-length":_vm.pagination.serverItemsLength,"loading":_vm.tableLoading,"footer-props":{ 'items-per-page-options': _vm.pagination.itemsPerPageOptions },"disable-sort":"","dense":""},on:{"update:options":this.updateTableOptions},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c(VBtn,{attrs:{"plain":"","small":"","to":("/session/" + (item.id))}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1)]}},{key:"item.start_date_time",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFullFormat")(item.start_date_time))+" ")]}},{key:"item.end_date_time",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFullFormat")(item.end_date_time))+" ")]}},{key:"item.provider",fn:function(ref){
        var item = ref.item;
return [_c('ProviderName',{attrs:{"provider":item.receiverProvider}})]}},{key:"item.location",fn:function(ref){
        var item = ref.item;
return [(item.location)?_c('LocationName',{attrs:{"location":{ id: item.location_id, name: item.location.name },"type":"table"}}):_vm._e()]}},{key:"item.chargepoint",fn:function(ref){
        var item = ref.item;
return [_c('ChargepointName',{attrs:{"chargepoint":{ id: item.chargepoint_id, name: item.chargepoint_name },"type":"table"}})]}},{key:"item.connector.max_electric_power",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.connector.max_electric_power / 1000)+" kW ")]}},{key:"item.kwh",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.kwh)+" kWh ")]}},{key:"item.tariff",fn:function(ref){
        var item = ref.item;
return [(item.cdr && item.cdr.tariff_id)?_c(VBtn,{attrs:{"text":"","to":("/tariffs/" + (item.cdr.tariff_id))}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-tag-text-outline")]),_vm._v(" "+_vm._s(item.cdr.tariff_id)+" ")],1):_vm._e()]}},{key:"item.total_cost",fn:function(ref){
        var item = ref.item;
return [_c('strong',[(!item.end_date_time)?_c(VChip,{staticClass:"text-chip text-uppercase px-1",attrs:{"small":"","label":"","outlined":"","color":"accent"}},[_vm._v(" In progress ")]):_vm._e(),((item.total_cost && item.end_date_time) || item.cdr)?_c(VChip,{staticClass:"text-chip text-uppercase px-1",attrs:{"small":"","label":"","outlined":"","color":"accent"}},[_vm._v(" "+_vm._s(item.total_cost)+" "+_vm._s(item.cdr.currency)+" ")]):_vm._e(),(!item.total_cost && item.end_date_time && !item.cdr)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"color":item.kwh <= 0.2 ? 'warning' : 'error'}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")])]}}],null,true)},[(item.kwh <= 0.2)?_c('span',[_vm._v("Less or equal than 0.2 kWh, it is correct to not have CDR")]):_c('span',[_vm._v("CDR should have been created")])]):_vm._e()],1)]}},{key:"item.backend_uuid",fn:function(ref){
        var item = ref.item;
return [_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.clipboard(item.backend_uuid)}}},[_c('span',{staticClass:"text-truncate",staticStyle:{"width":"100px"}},[_vm._v(_vm._s(item.backend_uuid))])])]}}],null,false,2546571309)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }