import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"outlined":"","max-width":"800"}},[_c(VCardText,{staticClass:"pa-8"},[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,{attrs:{"md":"6"}},[_c(VTextField,{attrs:{"label":"kWh","color":"accent","outlined":"","dense":"","hide-details":"","rules":[function (v) { return !!v; }]},on:{"change":_vm.calculatePrice},model:{value:(_vm.kwh),callback:function ($$v) {_vm.kwh=$$v},expression:"kwh"}})],1),_c(VCol,{attrs:{"md":"6"}},[_c(VTextField,{attrs:{"label":"Minutes","color":"accent","outlined":"","dense":"","hide-details":"","rules":[function (v) { return !!v; }]},on:{"change":_vm.calculatePrice},model:{value:(_vm.minutes),callback:function ($$v) {_vm.minutes=$$v},expression:"minutes"}})],1),_c(VCol,{attrs:{"md":"6"}},[_c(VAutocomplete,{attrs:{"items":_vm.selectableChargePoints,"label":"Charge point","color":"accent","outlined":"","dense":"","hide-details":"","rules":[function (v) { return !!v; }]},on:{"change":_vm.calculatePrice},model:{value:(_vm.chargePoint),callback:function ($$v) {_vm.chargePoint=$$v},expression:"chargePoint"}})],1),_c(VCol,{attrs:{"md":"6"}},[_c(VSelect,{attrs:{"items":_vm.chargePointConnectors,"label":"Connector","color":"accent","item-value":"id","outlined":"","dense":"","hide-details":"","rules":[function (v) { return !!v; }]},on:{"change":_vm.calculatePrice},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c(VRow,[_c(VChip,{staticClass:"ml-2",attrs:{"color":"primary","small":""}},[_vm._v(_vm._s(item.id))]),_c('span',{staticClass:"ml-1",staticStyle:{"line-height":"24px"}},[_vm._v(_vm._s(item.power_type))])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VRow,[_c(VChip,{attrs:{"color":"primary","small":""}},[_vm._v(_vm._s(item.id))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.power_type))]),_c(VChip,{staticClass:"ml-2",attrs:{"color":"accent","outlined":"","small":""}},[_vm._v(_vm._s(item.max_power)+" kWh")])],1)]}}]),model:{value:(_vm.connector),callback:function ($$v) {_vm.connector=$$v},expression:"connector"}})],1),_c(VCol,{attrs:{"md":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.emspProviders,"label":"Provider","color":"accent","outlined":"","dense":"","hide-details":"","rules":[function (v) { return !!v; }]},on:{"change":_vm.calculatePrice},model:{value:(_vm.provider),callback:function ($$v) {_vm.provider=$$v},expression:"provider"}})],1)],1),_c(VRow,{class:("justify-end " + (_vm.result ? 'pr-4' : 'pr-1') + " pt-4"),attrs:{"align":"center"}},[_c(VTextField,{staticClass:"result-input",attrs:{"label":"Price with vat excluded","color":"accent","outlined":"","dense":"","hide-details":"","readonly":""},model:{value:(_vm.excl_vat),callback:function ($$v) {_vm.excl_vat=$$v},expression:"excl_vat"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.currency))]),(_vm.inc_vat)?[_c(VTextField,{staticClass:"result-input ml-4",attrs:{"label":"Price with vat included","color":"accent","outlined":"","dense":"","hide-details":"","readonly":""},model:{value:(_vm.inc_vat),callback:function ($$v) {_vm.inc_vat=$$v},expression:"inc_vat"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.currency))])]:_vm._e(),(_vm.applied_tariff_id)?_c('span',{staticClass:"ml-4"},[_vm._v(" Aplicated tariff "),_c(VChip,{staticClass:"ml-2 result-chip",attrs:{"outlined":"","small":"","color":"accent","to":("/tariffs/" + _vm.applied_tariff_id)}},[_vm._v(" "+_vm._s(_vm.applied_tariff_id)+" ")])],1):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }