<template>
    <v-container fluid>

        <AppBar title="Sessions" icon="mdi-triangle-wave" />

        <!-- Table filters -->
        <v-row class="mb-2 px-2" align="center">
            <v-col cols="12" md="2">
                <v-autocomplete
                    v-model="selectedSender"
                    :items="providers"
                    label="Sender"
                    color="accent"
                    clearable
                    hide-details
                >
                    <template v-slot:item="data">
                        <ProviderSelectorTemplate :provider="data.item" />
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2">
                <v-autocomplete
                    v-model="selectedReceiver"
                    :items="providers"
                    label="Receiver"
                    color="accent"
                    clearable
                    hide-details
                >
                    <template v-slot:item="data">
                        <ProviderSelectorTemplate :provider="data.item" />
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2">
                <v-autocomplete
                    v-model="selectedCountry"
                    :items="countries"
                    label="Country"
                    color="accent"
                    clearable
                    hide-details
                />
            </v-col>
            <v-col cols="12" md="2">
                <v-menu
                    ref="menu"
                    v-model="showDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    color="accent"
                    min-width="auto"
                    hide-details
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="selectedDate"
                            label="Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="selectedDate"
                        @input="showDatePicker = false"
                        no-title
                        hide-details
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <!-- Checkbox to show only sessions without CDRs -->
            <v-col cols="12" md="1">
                <v-checkbox
                    v-model="selectedWithoutCDR"
                    label="Without CDR"
                    color="accent"
                    hide-details
                >
                </v-checkbox>
            </v-col>

            <v-btn 
                class="mt-5 mr-2"
                color="accent"
                :loading="tableLoading"
                @click="onFilter"
            >
                <v-icon>mdi-filter</v-icon>
            </v-btn>

             <v-spacer />

            <v-btn 
                class="mt-5 mr-2"
                color="accent"
                :loading="loadingCSV"
                outlined 
                @click="createCSV"
            >
                <v-icon left>mdi-file-download-outline</v-icon> CSV
            </v-btn>
        </v-row>

        <!-- Data table -->
        <v-data-table
            v-if="sessions"
            :headers="[
                { value: 'action', sortable:false, cellClass: 'td-linkto' },   
                { text: 'Status', value: 'status', align: 'center', width: '110'},
                { text: 'Start', value: 'start_date_time' },
                { text: 'End', value: 'end_date_time' },
                { text: 'Sender / Receiver', value: 'sender_receiver' },
                { text: 'Location', value: 'location' },
                { text: 'CDR', value: 'cdr' },
                { text: 'Charge point', value: 'chargepoint' },
                { text: 'Energy (kWh)', value: 'kwh' },
                { text: 'Id. DTM', value: 'dtm_session_id' },
            ]"
            :items="sessions"
            :items-per-page="tableOptions.itemsPerPage"
            :server-items-length="pagination.serverItemsLength"
            :loading="tableLoading"
            :footer-props="{ 'items-per-page-options': pagination.itemsPerPageOptions }"
            @update:options="this.updateTableOptions"
            disable-sort
            dense
            class="accent-table rounded-table"
        >
            <template v-slot:item.action="{ item }">
                <v-btn plain small :to="`/session/${item.id}`">
                    <v-icon small>mdi-arrow-top-right</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.status="{ item }">
               <StatusChip :status="item.status"/>
            </template>
            <template v-slot:item.sender_receiver="{ item }">
                <!-- Sender -->
                <v-chip v-if="item.sender_id === 1" color="secondary" small>{{ item.sender_name }}</v-chip>
                <strong v-else>
                    <ProviderName
                        :provider="item.senderProvider"
                    />
                </strong>
                <!-- Arrow -->
                <v-icon color="secondary">mdi-arrow-right</v-icon>
                <!-- Receiver -->
                <v-chip v-if="item.receiver_id === 1" color="secondary" small>{{ item.receiver_name }}</v-chip>
                <strong v-else>
                    <ProviderName
                        :provider="item.receiverProvider"
                    />
                </strong>
            </template>
            <template v-slot:item.location="{ item }">
                <LocationName
                    v-if="item.location"
                    :location="{ id: item.location.id, name: item.location.name }"
                    type="table"
                />
            </template>
            <template v-slot:item.chargepoint="{ item }">
                <ChargepointName
                    :chargepoint="{ id: item.chargepoint_id, name: item.chargepoint_name }"
                    type="table"
                />
            </template>
            <template v-slot:item.start_date_time="{ item }">
                {{ item.start_date_time | dateFullFormat }}
            </template>
            <template v-slot:item.end_date_time="{ item }">
                {{ item.end_date_time | dateFullFormat }}
            </template>

            <template v-slot:item.cdr="{ item }" >
                <v-tooltip bottom v-if="!item.cdr && item.status != 'COMPLETED'">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="warning" v-bind="attrs" v-on="on">
                            {{ 'mdi-information' }}
                        </v-icon>
                    </template>
                    <span>CDRs should only be generated for COMPLETED sessions</span>
                </v-tooltip>

                <v-icon v-else-if="item.kwh > 0.2"
                    :color="item.cdr ? 'success' : 'error'"
                >
                    {{ item.cdr ? 'mdi-check-circle' : 'mdi-close-circle' }}
                </v-icon>

                <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on"
                                color="warning"   
                            >
                                {{'mdi-check-circle'}}
                            </v-icon> 
                    </template>
                    <span>Less or equal than 0.2 kWh, it is correct to not have CDR</span>
                </v-tooltip>
            </template>

  
        </v-data-table>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import ProviderName from "@/components/provider/ProviderName"
import LocationName from "@/components/location/LocationName"
import ChargepointName from "@/components/chargepoint/ChargepointName"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"
import Countries from "@/../../Common/Resources/WorldCountryCodes_EN.json"
import StatusChip from "@/components/session/StatusChip";

export default {
    name: "wenea",
    components: {
        AppBar,
        ProviderName,
        LocationName,
        ChargepointName,
        ProviderSelectorTemplate,
        StatusChip
    },
    data() {
        return {
            selectedSender: undefined,
            selectedReceiver: undefined,
            selectedCountry: null,
            selectedDate: null,
            showDatePicker: false,
            selectedWithoutCDR: false,
            tableLoading: false,
            providers: [],
            sessions: [],
            pagination: {
                itemsPerPageOptions: [25, 50, 100, -1],
                serverItemsLength: 0
            },
            tableOptions: {
                itemsPerPage: 25,
                page: 1,
            },
            loadingCSV: false,
            initialLoad: true
        }
    },
    mounted(){
        this.load()
    },
    computed:{
        countries() { 
            return Countries.map(c => { 
                return { text: `${c.name} - ${c.alpha3}`, value: c.alpha3 } 
            }) 
        }
    },
    watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.loadSessions()
            },
            immediate: true
        },        
    },
    methods: {
        load() {
            this.$api.get("/providers")
                .then(res => {
                    this.providers = res.data.map(p => { 
                        return { 
                            ...p, 
                            text: p.role ? `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username} (${p.role})` : `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username}`, 
                            value: p.id 
                        } 
                    })
                })
                .catch(this.showError)
        },
        async loadSessions(){
            this.tableLoading = true
            this.getSessions(true)
                .then(resp => {
                    this.pagination.serverItemsLength = Number(resp.headers['x-total-count'])
                    this.sessions = resp.data
                })
                .catch(this.showError)
                .finally(() => {
                    this.tableLoading = false
                    this.initialLoad = false
                })
        },
        async getSessions(paginatedResponse){

            this.tableLoading = true

            let queryParams = ''

            if(paginatedResponse) { 
            queryParams += `offset=${(this.tableOptions.itemsPerPage * this.tableOptions.page) - this.tableOptions.itemsPerPage}`
            if (this.tableOptions.itemsPerPage > 0) { queryParams += `&limit=${this.tableOptions.itemsPerPage}` }
            }

            queryParams += '&orderBy[id]=desc'
            if (this.selectedSender) { queryParams += `&filter[sender_provider_id]=${this.selectedSender}` }
            if (this.selectedReceiver) { queryParams += `&filter[receiver_provider_id]=${this.selectedReceiver}` }
            if (this.selectedCountry) { queryParams += `&location_country=${this.selectedCountry}` }
            if (this.selectedWithoutCDR) { queryParams += `&only_without_cdr=true` }
            if (this.selectedDate) { queryParams += `&orfilter[start_date_time,end_date_time]=${this.selectedDate}` }

            return await this.$api.get(`sessions?${queryParams}`)
                .then(resp => {
                    if( Array.isArray(resp.data) ){
                        resp.data.map(session => 
                         {
                                session.id = session.id,
                                session.sender_id = session.senderProvider?.id,
                                session.sender_name= session.senderProvider?.user?.username,
                                session.receiver_id= session.receiverProvider?.id,
                                session.receiver_name= session.receiverProvider?.user?.username,
                                session.location_id= session.location.id,
                                session.location_name= session.location.name,
                                session.location_country= session.location.country,
                                session.cdr= session.cdr,
                                session.chargepoint_id= session.evse.chargepoint.id,
                                session.chargepoint_name= session.evse.chargepoint.reference_name,
                                session.kwh= session.kwh,
                                session.dtm_session_id= session.dtm_session_id,
                                session.start_date_time= session.start_date_time,
                                session.end_date_time= session.end_date_time
                        })   
                    }
                    return resp
                })
                .catch(this.showError)


        },
        onFilter(){
            if(this.tableLoading) return
            this.urlPush('sender', this.selectedSender)
            this.urlPush('receiver', this.selectedReceiver)
            this.urlPush('country', this.selectedCountry)
            this.urlPush('date', this.selectedDate)

            this.loadSessions()
        },
        updateTableOptions(args){
            this.tableOptions = args
            if(!this.initialLoad){
                this.loadSessions()
            }
        },
        async createCSV(){
            this.loadingCSV = true

            const sessions = await this.getSessions(false)
                .then(resp => resp.data)
                .catch(this.showError)
                .finally(() => this.loadingCSV = false)   

            let content = '"Id";"Sender Id";"Sender Name";"Receiver Id";"Receiver Name";"Location";"Location Country";"Chargepoint";"Connector Type";"KWH";"DTM Session ID";"Start Date";"End Date"\r\n'
            
            for( const row of sessions ){
                content += `"${row.id}";"${row.sender_id}";"${row.sender_name}";"${row.receiver_id}";"${row.receiver_name}";"${row.location_name}";"${row.location_country || ''}";"${row.chargepoint_name}";"${row.connector.power_type}";"${row.kwh}";"${row.dtm_session_id}";"${row.start_date_time}";"${row.end_date_time}";\r\n`
            }

            this.downloadBlob(content, `sessions.csv`, "text/csv")
            this.tableLoading = false
        }
    }
};
</script>
