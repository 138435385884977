<template>
    <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :group-by="groupBy"
        :page.sync="page"
        :items-per-page.sync="itemsPerPage"
        :sort-by="['charge_point_id', 'connector_id']"
        :hide-default-footer="hideDefaultFooter"
        hide-default-header
        no-data-text="No collided tariffs found"
        dense
    >
      <template slot="progress">
        <v-progress-linear color="accent" indeterminate></v-progress-linear>
      </template>
      <template v-slot:group.header="{ group, headers, toggle, isOpen }">
        <template v-if="!!group" >
          <td :colspan="headers.length" :class="`${lightTheme ? 'light-theme' : ''}`" @click.prevent="goCtrlClick($event,group,`chargepoint`)">
            <v-btn @click.stop="toggle" icon :ref="group">
              <v-icon v-if="isOpen">mdi-minus</v-icon>
              <v-icon v-else>mdi-plus</v-icon>
            </v-btn>
            <v-icon class="mr-2" color="white">mdi-ev-station</v-icon><span>{{ items.find(item => item.charge_point_id == group).charge_point_name }}</span>
          </td>  
        </template>
      </template>
      <template v-slot:item.charge_point_id="{ item }">
        <v-btn 
          small 
          text 
          min-width="80" 
          :to="`/chargepoint/${item.charge_point_id}`" 
        >
          <v-icon class="mr-2">mdi-ev-station</v-icon>{{ items.find(it => it.charge_point_id == item.charge_point_id).charge_point_name }}
        </v-btn>
      </template>
      <template v-slot:item.connector_id="{ item }">
        <v-chip 
          class="connector-chip" 
          outlined
          small 
          :to="`/chargepoint/${item.charge_point_id}`"

        >
          <v-icon class="mr-2">mdi-power-plug</v-icon>{{ item.connector_id }}
        </v-chip>
      </template>
      <template v-slot:item.provider_name="{ item }">
        <v-btn 
          small 
          text 
          min-width="80" 
          :to="`/provider/${item.applicable_provider}`"
        >
          {{ item.provider_name }}
        </v-btn>
      </template>
      <template v-slot:item.priority="{ item }">
        <div class="d-flex flex-row">
          <span class="text-caption mr-2" style="line-height: 24px; vertical-align: middle;">Priority: </span>
          <span style="line-height: 24px; vertical-align: middle;">{{ item.priority }}</span>
          <PriorityIcon :priority="item.priority" :max="9" :min="1"></PriorityIcon>
        </div>
      </template>
      <template v-slot:item.collided_tariffs="{ item }">
        <span class="text-caption">Collided tariffs:</span>
        <v-chip v-for="(tariff_id) in item.collided_tariffs" :key="tariff_id"
          class="tariff-chip ml-2"
          color="accent"
          small 
          outlined
          :to="`/tariffs/${tariff_id}`"
        >
          {{ tariff_id }}
        </v-chip>
      </template>
    </v-data-table>
</template>

<script>
import PriorityIcon from "@/components/icons/PriorityIcon"

export default {
  props: {
    items: {
      type: Array,
      default: []
    },
    loading: {
      type: Boolean,
      default: false
    },
    groupByChargePoint: {
      type: Boolean,
      default: true
    },
    lightTheme: {
      type: Boolean,
      default: false
    },
    hideProviderInfo: {
      type: Boolean,
      default: false
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      defaul: 1
    },
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  components: {
    PriorityIcon
  },
  computed: {
    headers () {
      let headers = [
            {text: 'charge_point_id', value: 'charge_point_id' },
            {text: 'connector_id', value: 'connector_id'},
            {text: 'provider_name', value: 'provider_name'},
            {text: 'priority', value: 'priority'},
            {text: 'collided_tariffs', value: 'collided_tariffs'},
        ]

      if (this.hideProviderInfo){
        headers = headers.filter(header => header.value != 'provider_name')
      }

      return headers
    },
    groupBy() {
      return this.groupByChargePoint ? 'charge_point_id' : null
    }
  }
}
</script>

<style scoped>     
    >>> .v-row-group__header {
      background: var(--v-accent-lighten1) !important;
    }

    >>> .v-row-group__header > td, >>> .v-row-group__header > td > button  {
      color: white !important;
    }

    >>> .v-data-table__progress > th {
      padding: 0 !important;
    }

    >>> .connector-chip {
      min-width: 85px;
    }
    >>> .connector-chip i {
      margin-right: 2px !important;
    }
    >>> .tariff-chip {
      min-width: 45px;
      justify-content: center;
    }
</style>