<template>
     <v-card outlined>
        <v-card-title>Wenea Token Tool</v-card-title>

        <v-card-text>
            <p>Make test calls against provider endpoints.</p>
            <ul>
                <li><strong>Send:</strong> PUT a Test Token.</li>
                <li>
                    <strong>Gets:</strong> GET of test token inserted in the
                    previous test.
                </li>
            </ul>
        </v-card-text>
        
        <v-card-actions>
            <v-row class="px-3 pb-3">            
                <v-btn class="mb-2" @click="sendTestToken" text block color='accent' :loading="loadingSendTestToken">
                    <v-icon left>mdi-send</v-icon>
                    Send Test Token
                </v-btn>
                <v-btn @click="retrieveTestToken" text block color='accent' :loading="loadingGetTestToken">
                    <v-icon left>mdi-download</v-icon>
                    Get Test Token
                </v-btn>
            </v-row>
        </v-card-actions>
    </v-card>
</template>
<script>

export default {
  name: "wenea-token-tool",
  props: ["provider_id"],
  data() {
    return {                  
        loadingSendTestToken: false,
        loadingGetTestToken: false,
    }
  },
  methods: {
        sendTestToken() {
            this.loadingSendTestToken = true
            this.$api.put(`provider/${this.provider_id}/send-test-token`)
                .then(() => this.showSuccess("Token sent successfully"))
                .catch(this.showError)
                .then(() => this.loadingSendTestToken = false)
        },
        retrieveTestToken() {
            this.loadingGetTestToken = true
            this.$api.get(`provider/${this.provider_id}/retrieve-test-token`)
                .then( (resp) => this.showSuccess(`Token received successfully: ${JSON.stringify(resp.data)}`) )
                .catch(this.showError)
                .then(() => this.loadingGetTestToken = false)
        },
  },
};
</script>