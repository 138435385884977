import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.item.childs)?[_c(VMenu,{attrs:{"open-on-hover":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItem,_vm._g(_vm._b({class:_vm.isActiveNavigationItem(_vm.item, _vm.routerPath) ? 'v-list-item--active' : '',attrs:{"link":""}},'v-list-item',attrs,false),on),[_c(VListItemAction,[_c(VIcon,[_vm._v(_vm._s(_vm.item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.item.text))])],1),_c(VListItemIcon,{staticClass:"mt-3"},[_c(VIcon,[_vm._v("mdi-menu-right")])],1)],1)]}}],null,false,985573830)},[_c(VList,{staticClass:"accent-navigation-drawer py-0",attrs:{"dense":""}},_vm._l((_vm.item.childs),function(child,cIndex){return _c('navigation-item',{key:("" + cIndex),attrs:{"item":child},scopedSlots:_vm._u([_vm._l((_vm.$slots),function(index,name){return {key:name,fn:function(){return [_vm._t(name)]},proxy:true}})],null,true)})}),1)],1),(_vm.$slots[("item." + (_vm.item.id) + ".append")])?[_vm._t(("item." + (_vm.item.id) + ".append"))]:_vm._e()]:[_c(VListItem,{class:_vm.isActiveNavigationItem(_vm.item, _vm.routerPath) ? 'v-list-item--active' : '' + _vm.item.customClass ? (" " + (_vm.item.customClass)) : '',attrs:{"link":"","to":("" + (_vm.item.external ? '' : _vm.item.link)),"href":("" + (_vm.item.link))}},[_c(VListItemAction,[(_vm.$slots[("item." + (_vm.item.id) + ".icon")])?[_vm._t(("item." + (_vm.item.id) + ".icon"))]:_c(VIcon,[_vm._v(_vm._s(_vm.item.icon))])],2),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.item.text))])],1),(_vm.$slots[("item." + (_vm.item.id) + ".action")])?_c(VListItemAction,[_vm._t(("item." + (_vm.item.id) + ".action"))],2):_vm._e()],1),(_vm.$slots[("item." + (_vm.item.id) + ".append")])?[_vm._t(("item." + (_vm.item.id) + ".append"))]:_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }