<template>

  <v-container fluid>
      <v-row  style="justify-content:flex-end"> 
          <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon 
                        v-on="on"
                        v-bind="attrs">mdi-help-circle
                    </v-icon>
                </template>
                <div class="ml-3 mb-6 mt-3">
                    <p style="color:black">Change the state by pressing the checkbox:</p>
                    <v-row align="center"> 
                        <v-simple-checkbox class="success-checkbox"         
                            :value="true"
                            readonly
                            dense
                            hide-details
                            :ripple="false"
                        ></v-simple-checkbox>
                        <span class="text--secondary text-body-2">Works correctly</span>
                    </v-row>
                    <v-row align="center">
                        <v-simple-checkbox class="indeterminate-checkbox"     
                            :indeterminate="true"
                            readonly
                            dense
                            hide-details
                            :ripple="false"
                        ></v-simple-checkbox>
                        <span class="text--secondary text-body-2">There is no information for this provider and database funcionality</span>
                    </v-row>
                    <v-row align="center">
                        <v-simple-checkbox class="error-checkbox"        
                            :indeterminate="true"
                            readonly
                            dense
                            hide-details
                            :ripple="false"
                        ></v-simple-checkbox>
                        <span class="text--secondary text-body-2">There is an error or it needs to be developed</span>
                    </v-row>
                </div>
            </v-tooltip>
        </v-row>
    <v-row style="text-align:center">
        
        <!-- Credentials -->
        <v-col cols="4" sm="2" md="4" >
            <span>Credentials</span>
            <SimpleCheckbox v-bind="functionalities[0]" @click="checkNextVal(functionalities[0])"/>
        </v-col>
        <!-- Tokens -->
        <v-col cols="4" sm="2" md="4" >
            <span>Tokens</span>
            <SimpleCheckbox v-bind="functionalities[1]" @click="checkNextVal(functionalities[1])"/>
        </v-col>
        <!-- Locations -->
        <v-col cols="4" sm="2" md="4" >
            <span>Locations</span>
            <SimpleCheckbox v-bind="functionalities[2]" @click="checkNextVal(functionalities[2])"/>
        </v-col>
        <!-- Commands -->
        <v-col cols="4" sm="2" md="3" >
            <span>Comm.</span>
            <SimpleCheckbox v-bind="functionalities[3]" @click="checkNextVal(functionalities[3])"/>
        </v-col>
        <!-- Sessions -->
        <v-col cols="4" sm="2" md="3" >
            <span>Sessions</span>
            <SimpleCheckbox v-bind="functionalities[4]" @click="checkNextVal(functionalities[4])"/>
        </v-col>
        <!-- Cdrs -->
        <v-col cols="4" sm="2" md="3" >
            <span>Cdrs</span>
            <SimpleCheckbox v-bind="functionalities[5]" @click="checkNextVal(functionalities[5])"/>
        </v-col>
        <!-- Tariffs -->
        <v-col cols="4" sm="2" md="3" >
            <span>Tariffs</span>
            <SimpleCheckbox v-bind="functionalities[6]" @click="checkNextVal(functionalities[6])"/>
        </v-col>

    </v-row>

  </v-container>

</template>

<script>
import SimpleCheckbox from "../ui-components/SimpleCheckbox.vue"

export default {
  name: "providerInfo",
  components: {
    SimpleCheckbox
  },
  props: ["providerId", "providerRole"],
    data() {
        return {
            providersInfo: [],

            functionalities: [
                { id: 'credentials', value:'',info:''},
                { id: 'tokens', value:'',info:''},
                { id: 'locations', value:'',info:''},
                { id: 'commands', value:'',info:''},
                { id: 'sessions', value:'',info:''},
                { id: 'cdrs', value:'',info:''},
                { id: 'tariffs', value:'',info:''},  
            ],


        }
    },
    mounted() {
        this.getProvidersInfo()
    },
    methods: {
        getProvidersInfo(){
            this.$api.get(`dev-providers-info?provider_id=${this.providerId}&provider_role=${this.providerRole}`)
                .then(resp => {
                    if( Array.isArray(resp.data) ){
                        this.providersInfo = resp.data
                        this.getCheckboxClass()
                    }
                })
                .catch(e => { this.showError(e) })
            
        },
        getCheckboxClass(){
            //foreach functionalities
            this.functionalities.forEach(functionality => {
                //foreach provider
                this.providersInfo.forEach(provider => {
                    //if provider has functionality
                    if( provider.identifier.includes(functionality.id) && provider.working == true ){
                        functionality.value = 'success-checkbox'
                        functionality.checked = true
                    }
                    else if( provider.identifier.includes(functionality.id) && provider.working == false ){
                        functionality.value = 'error-checkbox'
                        functionality.info = provider.info
                        functionality.checked = false
                    }
                    else if( provider.identifier.includes(functionality.id) && provider.working == null ){
                        functionality.value = 'indeterminate-checkbox'
                        functionality.info = provider.info
                        functionality.checked = false
                    }

                })
            })
        },
        checkNextVal(functionality) { 

            switch (functionality.value) {
                case 'success-checkbox':
                    functionality.value = 'indeterminate-checkbox'
                    functionality.checked = false
                    break;
                case '':
                    functionality.value = 'error-checkbox'
                    functionality.checked = false
                    break;
                case 'indeterminate-checkbox':
                    functionality.value = 'error-checkbox'
                    functionality.checked = false
                    break;
                case 'error-checkbox':
                    functionality.value = 'success-checkbox'
                    functionality.checked = true
                    break;
                default:
                    break;
            }

            this.$api.post(`dev-providers-info?provider_id=${this.providerId}&provider_role=${this.providerRole}`, {
                identifier: functionality.id,
                working: functionality.checked
            })
            .then(() => this.functionalities.map(funct => {
                if (funct.id == functionality.id) { funct = functionality }
            }))
            .catch(e => { this.showError(e) })
        },
    }
}
</script>

<style scoped>
    >>> .jv-code{
        padding: 0!important;
    }
    >>> .jv-container.jv-light {
        background: transparent !important;
    }
    .v-tooltip__content {
        opacity: 0.95 !important;
        background-color: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        padding-right: 1.5%;
        border: 1px solid rgba(0, 0, 0, 0.12) !important;
    }
    .v-simple-checkbox.success-checkbox i {
        color: #4caf50 !important;
    }
    .v-simple-checkbox.indeterminate-checkbox i {
        color: #ffa72d;
    }
    .v-simple-checkbox.error-checkbox i {
        color: #ff3535;
    }
</style>