<template>
    <v-container fluid>

        <AppBar icon="mdi-map-marker" :breadcrumbs="[
            { text: 'Location Groups', to: '/location-groups'},
            { text: groupName, icon: 'mdi-folder-marker' }
        ]"/>

        <v-card
            :loading="groupLoading"
            outlined
        >
            <v-card-title class="accent-background pl-8">{{ group.name || "-" }}</v-card-title>
            <v-card-text>
                <v-row v-if="group.id">

                    <v-col cols="12" md="2">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{group.id}}</v-list-item-title>
                                <v-list-item-subtitle>Id.</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{group.name}}</v-list-item-title>
                                <v-list-item-subtitle>Name</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{group.reference}}</v-list-item-title>
                                <v-list-item-subtitle>Reference</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-icon 
                                        :color="group.is_private ? 'success' : 'error'"
                                    >
                                    {{ group.is_private ? 'mdi-check-circle' : 'mdi-close-circle' }}
                                    </v-icon>
                                </v-list-item-title>
                                <v-list-item-subtitle>Is private?</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{ group.updated_at }}</v-list-item-title>
                                <v-list-item-subtitle>Updated at</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{ group.backend_id || '-' }}</v-list-item-title>
                                <v-list-item-subtitle>Backend Id.</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>

                </v-row>
            </v-card-text>
        </v-card>

        <v-toolbar flat class="mt-4">
            <v-toolbar-title class="text-h5">
                <v-icon left color="accent">mdi-map-marker</v-icon>
                Group locations
            </v-toolbar-title>
            <v-row>
                <v-spacer />
                <v-col cols="12" md="2">
                    <v-text-field 
                        v-model="tableSearch" 
                        autofocus 
                        clearable 
                        hide-details
                        append-icon="mdi-magnify"
                        placeholder="Search"
                        @keyup.enter="onFilter"
                        >
                    </v-text-field>
                </v-col>

                <v-btn 
                    class="ml-2 mt-3"
                    color="accent"
                    @click="onFilter"
                >
                    <v-icon>mdi-filter</v-icon>
                </v-btn>
            </v-row>

        </v-toolbar>
        <v-divider />

        <v-data-table
            v-if="locations"
            class="accent-table rounded-table"
            :headers="[
                { value: 'click', sortable:false, cellClass: 'td-linkto' },
                {text: 'Id.', value: 'id'},
                {text: 'Name', value: 'name'},
                {text: 'Provider', value: 'provider'},
                {text: 'Publish', value: 'publish'},
                {text: 'Charge points', value: 'chargepoints', width: '600px'},
                {text: 'Map', value: 'action', sortable: false }
            ]"
            :items="locations"
            :search="searchFilter"           
            disable-sort
            dense
        >
            <template v-slot:item.click="{ item }">
                <v-btn plain
                    small
                    :to="`/location/${item.id}`" 
                    >
                    <v-icon small>mdi-arrow-top-right</v-icon>
                </v-btn>
            </template> 
            <template v-slot:item.name="{ item }">
                <LocationName
                    :location="item"
                    extra="no-arrow"
                    type="table"
                />
            </template>
            <template v-slot:item.provider="{ item }">
                <ProviderName
                    :provider="{ id: item.provider_id, country_code: item.country_code, party_id: item.party_id }"
                    type="table"
                />
            </template>
            <template v-slot:item.publish="{ item }" >
                <v-icon 
                    :color="item.publish ? 'success' : 'error'"
                >
                {{ item.publish ? 'mdi-check-circle' : 'mdi-close-circle' }}
                </v-icon>
            </template>
            <template v-slot:item.chargepoints="{ item }">
                <ChargepointName
                    v-for="(chargepoint) in item.chargepoints"
                    :key="`${chargepoint.id}`"
                    class="ml-2"
                    :chargepoint="{ id: chargepoint.id, name: chargepoint.reference_name }"
                    type="table"
                />
            </template>
            <template v-slot:item.action="{ item }">
                <v-btn @click.stop="openMaps(item.coordinates)" small icon><v-icon small>mdi-map</v-icon></v-btn>
            </template>
        </v-data-table>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import ProviderName from "@/components/provider/ProviderName"
import LocationName from "@/components/location/LocationName"
import ChargepointName from "@/components/chargepoint/ChargepointName"

export default {
    components: { AppBar, ProviderName, LocationName, ChargepointName },
    data() {
        return {
            groupLoading: false,
            group: {},

            tableSearch: "",
            searchFilter: "",
            locations: null
        }
    },
    computed: {
        groupName: function(){
            return (this.group) ? this.group.name : "-"
        }
    },
    mounted(){
        this.load()
    },
    methods: {
        load(){

            const id = this.$router.currentRoute.params.id

            this.groupLoading = true
            this.$api.get(`location-group/${id}`)
                .then(resp => {
                    this.group = resp.data
                    if( Array.isArray(resp.data.locations) ){
                        resp.data.locations.map(loc => {
                            loc.chargepoints = loc.chargepoints.map(cp => { return { reference_name: cp.reference_name, id: cp.id } })
                        })
                        this.locations = resp.data.locations
                    }
                })
                .catch(this.showError)
                .then(_ => { this.groupLoading = false })

        },
        onFilter(){
            this.searchFilter = this.tableSearch
        }
    }
};
</script>

<style scoped>
    >>> .accent-background {
        background-color: var(--v-accent-lighten1) !important;
        color: white;
    }
</style>
