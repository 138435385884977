<template>
     <div class="ma-2">
        <v-row>
            <v-col class="pb-0" sm="6" md='8' lg="6">
                <v-text-field 
                    label="Tag Id." 
                    placeholder="12345678ABCD" 
                    v-model="idTag"
                    :loading="loading" 
                    :messages="messages" 
                    :error="error" 
                    :success="success" 
                    :disabled="disabled" 
                    @input="resetToInitialState"
                    dense outlined clearable>
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6" md='4' lg="6" >
                <v-autocomplete 
                    label="Providers"
                    :items="providers"
                    v-model="selectedProviders"
                    dense outlined chips small-chips multiple >

                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="!selectAllProviders">
                            <span>{{ item.text }}</span>
                        </v-chip>
                        <v-chip v-if="selectAllProviders && index == 0">
                            <span> All EMSP Providers </span>
                        </v-chip>
                    </template>

                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6" md='4' lg="6" v-if="!addLocation">
                <u class="mx-3" @click="() => addLocation = true">Add location +</u> 
                <v-btn class="mx-3" @click="sendRealTimeAuthorizationToken(idTag)" depressed color="accent">Check</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="addLocation">
            <v-col class="pt-0" sm="6" md='8' lg="6">
                <v-autocomplete 
                    label="Location"
                    :items="locations"
                    v-model="selectedLocation"
                    :loading="loadingLocations"
                    dense
                    outlined 
                    hide-details
                    clearable
                    prepend-icon="mdi-minus"
                    @click:prepend="addLocation = false; addEvse = false"
                    >
                </v-autocomplete>
            </v-col>
            <v-col class="pt-0" sm="6" md='4' lg="6" v-if="!addEvse">
                <u class="mx-3" @click="() => addEvse = true">add evse +</u> 
                <v-btn class="mx-3" @click="sendRealTimeAuthorizationToken(idTag)" depressed color="accent">Check</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="addLocation & addEvse">
            <v-col sm="6" md='8' lg="6">
                <v-autocomplete 
                    label="Evse"
                    :items="evses"
                    v-model="selectedEvses"
                    :loading="loadingEvses"
                    dense
                    outlined 
                    clearable
                    chips
                    small-chips
                    hide-details
                    multiple
                    prepend-icon="mdi-minus"
                    @click:prepend="addEvse = false">
                </v-autocomplete>
            </v-col>
            <v-col sm="6" md='4' lg="6">
                <v-btn class="mx-3" @click="sendRealTimeAuthorizationToken(idTag)" depressed color="accent">Check</v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>

export default {
    name: "real-time-auth-tool",
    data() {
        return {
            idTag: null,
            locationId: null, 
            loading: false,
            loadingLocations: false,
            loadingEvses: false, 
            messages: null, 
            success: false, 
            error: false, 
            disabled: false,

            addLocation: false,
            providers: [],
            selectedProviders: ['all'],
            selectAllProviders: true,

            locations: [],
            selectedLocation: null,

            addEvse: false,
            evses: [],
            selectedEvses: null,
        }
    },
    mounted() {
        this.$api.get(`providers-external-emsp`)
            .then((resp) => {
                if (Array.isArray(resp.data)) {
                    this.providers = resp.data.map((provider) => {
                        return {
                            text: `${provider.country_code} - ${provider.party_id} ${provider.user.company.name} (${provider.user.type})`,
                            value: provider.id
                        }
                    })

                    //Add default value "All" as first element
                    this.providers.unshift({ text: 'All EMSP Providers', value: 'all' })
                }
            })
            .catch(this.showError)
    },
    watch: {
        selectedProviders(newValues) {
            if (!this.selectAllProviders && newValues.includes('all')) {
                this.selectAllProviders = true
                this.selectedProviders = this.providers.map(provider => provider.value)
                return
            }else if(this.selectAllProviders && !newValues.includes('all')){
                this.selectAllProviders = false
                this.selectedProviders = []
                return
            }else if (this.selectAllProviders && newValues.length < this.providers.length && newValues.includes('all')){
                this.selectAllProviders = false
                this.selectedProviders = newValues.filter(provider => provider !== 'all')
            }else if (newValues.length === 0){
                this.selectedProviders = ['all']
            }
        },
        addLocation() {
            this.loadingLocations = true     
            this.$api.get(`locations/wenea-locations`)
                .then((resp) => {
                    if (Array.isArray(resp.data)) {
                        this.locations = resp.data.map(location => { return { text: location.name, value: location } })
                    }
                })
                .catch(this.showError)
                .finally(() => {
                    this.loadingLocations = false
                })
        },
        selectedLocation(newValue) {
            this.loadingEvses = true  
            if (!newValue || newValue == '') { return }
            
            this.$api.get(`provider/location/${newValue.id}/evses`)
                .then((resp) => {
                    this.evses = resp.data.map(evse => { return { text: `${evse.evse_id} - ${evse.uid}`, value: evse.uid } })
                })
                .catch(this.showError)
                .finally(() => {
                    this.loadingEvses = false
                })
        }
    },
    methods: {
        resetToInitialState(){
            this.loading = false 
            this.messages= null 
            this.success = false
            this.error = false
            this.disabled = false
        },
        sendRealTimeAuthorizationToken(idTag) {
            if (!idTag || idTag === '') {
                this.messages = 'The field is required.'
                this.error = true
                return
            }

            let queryParams = ''
            this.selectedProviders ? queryParams = `?provider_ids=${this.selectedProviders.filter(provider => provider !== 'all')}&` : queryParams = `?`
            
            if (this.selectedLocation){ queryParams = queryParams + `location_id=${this.selectedLocation.ocpi_id}` }
            if (this.selectedEvses){ queryParams = queryParams + `&evse_uids=${this.selectedEvses}` }

            this.loading = true
            this.disabled = true
            this.$api
                .get(`tokens/${idTag}/authorize${queryParams}`)
                .then((resp) => {
                    if (resp.data.allowed) {
                        this.messages = 'Token allowed.'
                        this.success = true
                        this.error = false
                        if(resp.data.allowed.provider){
                            this.messages = `${this.messages} By provider ${resp.data.allowed.provider.country_code} - ${resp.data.allowed.provider.party_id}`
                        }

                    } else{
                        this.messages = 'Token NOT allowed.'
                        this.error = true 
                    }
                })
                .catch(() => {
                    this.messages = 'An error occurred while checking the token.'
                    this.error = true
                })
                .finally(() => {
                    this.loading = false
                    this.disabled = false
                })
        },
    },
};
</script>