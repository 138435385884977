<template>
    <v-container fluid>
            <v-form ref="form">
                <v-card class="mt-4 mb-8" outlined :loading="loading">
                    <v-row  class="px-6 mt-2">
                        <v-col cols="12" md="3" v-if="!formUser.id">
                            <v-text-field
                                v-model="formUser.username"
                                label="Username"
                                :rules="[v => !!v || 'Username is required']"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3" v-if="!formUser.id">
                            <v-text-field
                                v-model="newPassword"
                                label="Password"
                                :rules="[v => !!v || 'Password is required']"
                                required
                                name="password"
                                :type="fieldTypes.password"
                                autocomplete="new-password"
                            ></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="formUser.email"
                                label="e-mail"
                                :rules="[v => !!v || 'e-mail is required']"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="formUser.url"
                                label="Web"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="formUser.ocpi_url_versions"
                                label="URL versions"
                            ></v-text-field>
                        </v-col>
                        
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="formUser.ocpi_own_url"
                                label="Wenea Credentials URL"
                                disabled
                            ></v-text-field>    
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-select
                                :items="status"
                                v-model="formUser.status"
                                label="Status"
                                :rules="[(v) => !!v || 'Status is required']"
                                required
                            >
                                <template v-slot:selection="{item}">
                                    <StatusChip v-if="item" :status="item" />
                                </template>
                                <template v-slot:item="{item}">
                                    <StatusChip v-if="item" :status="item" />
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-divider />

                    <v-row class="px-6 mt-1">
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="formUser.ocpi_url_endpoints"
                                label="URL endpoints"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                            <v-select
                                :items="ocpiVersionsAvailable"
                                v-model="formUser.ocpi_version"
                                label="OCPI version"
                            >
                                <template v-slot:selection="{item}">
                                    <v-chip small outlined color="accent">{{ item }}</v-chip>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-select
                                v-model="formUser.type"
                                :items="[
                                    { value: 'hub', text: 'hub' },
                                    { value: 'provider', text: 'direct' },
                                ]"
                                label="Type"
                                :rules="[(v) => !!v || 'Type is required']"
                                required
                            >
                                <template v-slot:selection="{item}">
                                    <v-chip small outlined color="accent">{{ item.text.toUpperCase() }}</v-chip>
                                </template>
                                <template v-slot:item="{item}">
                                    <v-chip small outlined color="accent">{{ item.text.toUpperCase() }}</v-chip>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="formUser.ocpi_token_a"
                                label="Token A"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                v-model="formUser.ocpi_token_c"
                                label="Token C"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <v-divider />

                    <v-row class="px-6">
                        <v-col cols="12" sm="4" md="3" lg="2">
                            <v-checkbox
                            v-model="formUser.token_update_auto"
                            label="Automatic token update"
                            color="accent"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="4" md="3" lg="2">
                            <v-checkbox
                            v-model="formUser.flag_send_encoded_auth"
                            label="Send encoded auth"
                            color="accent"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="4" md="3" lg="2">
                            <v-checkbox
                            v-model="formUser.flag_url_trailing_slash"
                            label="Add URL trailing slash"
                            color="accent"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card>
            </v-form>

            <v-row class="d-flex justify-end pr-4 pb-4">
                <slot name="actions"></slot>
                <v-btn class="ml-2 accent" depressed @click="save">
                    <v-icon left>mdi-content-save</v-icon>{{ buttonSave }}
                </v-btn>
            </v-row>
    </v-container>
</template>

<script>
import { EventBus } from "@/event-bus";
import StatusChip from "@/components/connection/StatusChip";

export default {
    name: "connection-form",
    props: [ 'user' ],
    components: { StatusChip },
    data() {
        return {            
            loading: false,
            ocpiVersionsAvailable: ['2.1.1', '2.2', '2.2.1'],
            status: ['CONNECTED', 'SUSPENDED', 'DISCONNECTED', 'PLANNED', 'OFFLINE'],
            fieldTypes: {
                password: 'text',
            },
            newPassword: '',
        }
    },
    watch: {
        newPassword: {
            handler: function(val) {
                if(val) {
                    this.fieldTypes.password = 'password'
                }
                
            },
        }
    },
    computed: {
        formUser() {
            const newUser = { status: 'DISCONNECTED', flag_send_encoded_auth: true, ocpi_own_url: process.env.VUE_APP_WENEA_CREDENTIALS_URL }
            return this.user || newUser
        },
        buttonSave: function() { return this.formUser.id ? 'Save' : 'Create connection' }
    },
    methods: {
        save(){
            this.formUser.password = this.newPassword
            if (!this.$refs.form.validate()){ 
                return this.showError("All required fields must be provided")
            }
            let data = (({ tokens, endpoints, company, ...user }) => user)(this.formUser)

            if( this.formUser.id ){
                
                this.$api.patch(`/user/${this.formUser.id}`, data)
                    .then(resp => {
                        this.formUser = resp.data
                        this.showSuccess("Data saved successfully")
                    })
                    .catch(this.showError)

            } else {

                this.$api.post(`/user`, data)
                    .then((resp) => EventBus.$emit("connection-created", { user: resp.data }))
                    .catch(this.showError)

            }
        }
    }
};
</script>
