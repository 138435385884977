<template>
    <v-chip
        :color="statusColor(status)"
        outlined
        small
    >
        {{ status }}
        <v-avatar
            class="ml-2"
            :color="statusColor(status)"
            size="10"
        ></v-avatar>
    </v-chip>
</template>

<script>
export default {
    name: "status-chip",
    props: {
        status: {
            type: String
        }
    },
    methods:{
        statusColor(status) {
            if (status == "CONNECTED") {
                return "green"
            }
            if (status == "OFFLINE") {
                return "grey"
            }
            if (status == "PLANNED") {
                return "#FB8C00"
            }
            if (status == "SUSPENDED" || status == "DISCONNECTED") {
                return "red"
            }
        }
    }
}
</script>

<style scoped>
    .v-chip .v-avatar {
        height: 10px !important;
        min-width: 10px!important;
        width: 10px !important;
    }
</style>