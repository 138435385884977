<template>
    <v-dialog v-model="show" persistent max-width="1180px">
        <v-card>
            <v-card-title class="accent white--text">
                <span class="headline">Not sent Locations</span>
            </v-card-title>
            <v-toolbar flat>
            <v-spacer></v-spacer>
            <div>
                <v-text-field
                v-model="tableSearch"
                clearable
                hide-details
                append-icon="mdi-magnify"
                placeholder="Search"
                class="mr-8"
                >
                </v-text-field>
            </div>
            <v-btn 
                text
                small
                @click="sendAllLocation()">
                Send all
                <v-icon right>mdi-send</v-icon>
            </v-btn>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pa-4">
                    <v-data-table
                        :headers="[
                            { text: 'Id.', value: 'id' },
                            { text: 'OCPI Id.', value: 'ocpi_id' },
                            { text: 'Name', value: 'name' },
                            { text: 'EVSEs', value: 'evses.length' },
                            { text: 'City', value: 'city' },
                            { text: 'Last updated', value: 'last_updated' },
                            { text: 'Actions', value: 'actions', sortable: false }
                        ]"
                        :items="locations"
                        :search="tableSearch"
                        :loading="loading"    
                        disable-sort
                        dense
                        >
                        <template v-slot:item.actions="{ item }">
                            <v-btn
                            class="mr-2"
                            color="accent"
                            depressed
                            small
                            @click="sendLocation(item)"
                            >Send</v-btn>
                        </template>
                        </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="show = false" depressed>Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { EventBus } from "@/event-bus"

export default {
    data() {
        return {
            show: false,
            loading: false,
            locations: [],
            providerId: null,
            tableSearch: ""
        }
    },
    watch: {
        show( show ) {
            if ( !show ) {
                this.locations = []
                this.providerId = null
                this.loading = false
                EventBus.$emit("dialog-not-sent-locations:close")
            }
        }
    },
    created() {
        var vm = this;
        EventBus.$on("dialog-not-sent-locations:open", async function( args ) {
            
            if( !args.locations ){
                vm.showError("Data error, no locations")
                return false
            }

            if( !args.providerId ){
                vm.showError("Data error, no providerId")
                return false
            }

            vm.providerId = args.providerId
            vm.filterAllowedLocations(args.locations, args.providerId)
            vm.show = true

        })
    },
    methods: {
        filterAllowedLocations( locations, providerId ) {
            if ( !locations ) { return [] }

            this.loading = true
            this.$api
                .get(`provider/${providerId}/allowed-locations`)
                .then(resp => {
                    const allowedLocationIds = resp.data.map(location => location.id)
                    this.locations = locations.filter(input => allowedLocationIds.includes(input.id))
                })
                .catch(this.showError)
                .then((_) => { this.loading = false })
        },
        sendLocation(location){
        
            this.loading = true

            this.$api
                .get(`location/${location.id}/send-to/providers/${this.providerId}`)
                .then(resp => {
                    this.locations = this.locations.filter(input => input.id !== location.id)
                    this.showSuccess(`Location has been sent`)
                })
                .catch(this.showError)
                .then((_) => { this.loading = false })
        },
        sendAllLocation(){

            this.loading = true

            const data = {
                location_ids: this.locations.map(location => location.id)
            }

            this.$api
                .post(`locations/send-to/providers/${this.providerId}`, data)
                .then(resp => {
                    this.locations = this.locations.filter(input => !resp.data.sent.includes(input.id))
                    if (resp.data.allSuccess){
                        this.showSuccess('Locations have been sent')
                    } else {
                        this.showError('There was an error sending any of Locations')
                    }
                })
                .catch(this.showError)
                .then((_) => { this.loading = false })
        }
    }
}
</script>