import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{staticClass:"accent-app-bar",attrs:{"app":"","dense":"","elevation":"0"}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();return _vm.$store.commit('toggle_navigator')}}}),(_vm.items)?_c('div',{staticClass:"ml-sm-4"},[_c(VBreadcrumbs,{staticClass:"flex-nowrap",attrs:{"items":_vm.items},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VBreadcrumbsItem,{attrs:{"to":item.to,"exact-active-class":"display-1","active-class":"display-1"}},[(item.icon)?_c(VIcon,{attrs:{"left":"","color":"accent"}},[_vm._v(_vm._s(item.icon))]):_vm._e(),_c('span',{staticClass:"overflow-hidden"},[_vm._v(_vm._s(item.text))])],1)]}},{key:"divider",fn:function(){return [_c(VIcon,[_vm._v("mdi-chevron-right")])]},proxy:true}],null,false,4223656768)})],1):_vm._e(),_c('div',{staticClass:"flex-grow-1"}),_c(VSpacer),(_vm.actions)?_c('div',[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-magnify")])],1)],1):_vm._e(),_c('div',[_c('OcpiIdSearcherTool',{attrs:{"type":"appBar"}})],1),(_vm.$store.state.alerts)?_c('div',{staticClass:"ml-4 d-flex"},[_c(VDivider,{staticClass:"border-opacity-50 mr-2",attrs:{"vertical":""}}),_c('AlertsButton')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }