<template>
    <v-card outlined max-width="800">
      <v-card-text class="pa-8">
        <v-form 
          ref="form"
          v-model="valid"
        >
          <v-row>
            <v-col md="6">
              <v-text-field
                v-model="kwh"
                label="kWh"
                color="accent"
                outlined
                dense
                hide-details
                :rules="[v => !!v]"
                @change="calculatePrice"
              ></v-text-field>
            </v-col>

            <v-col md="6">
              <v-text-field
                v-model="minutes"
                label="Minutes"
                color="accent"
                outlined
                dense
                hide-details
                :rules="[v => !!v]"
                @change="calculatePrice"
              ></v-text-field>
            </v-col>

            <v-col md="6">
              <v-autocomplete
                v-model="chargePoint"
                :items="selectableChargePoints"
                label="Charge point"
                color="accent"
                outlined
                dense
                hide-details
                :rules="[v => !!v]"
                @change="calculatePrice"
              >
              </v-autocomplete>
            </v-col>

            <v-col md="6">
              <v-select
                v-model="connector"
                :items="chargePointConnectors"
                label="Connector"
                color="accent"
                item-value="id"
                outlined
                dense
                hide-details
                :rules="[v => !!v]"
                @change="calculatePrice"
              >
                <template v-slot:selection="{ item }">
                  <v-row>
                    <v-chip class="ml-2" color="primary" small>{{ item.id }}</v-chip>
                    <span class="ml-1" style="line-height: 24px;">{{ item.power_type }}</span>
                  </v-row>
                </template>
                <template v-slot:item="{ item }">
                  <v-row>
                    <v-chip color="primary" small>{{ item.id }}</v-chip>
                    <span class="ml-2">{{ item.power_type }}</span>
                    <v-chip class="ml-2" color="accent" outlined small>{{ item.max_power }} kWh</v-chip>
                  </v-row>
                </template>
              </v-select>
            </v-col>

            <v-col md="12">
              <v-autocomplete
                v-model="provider"
                :items="emspProviders"
                label="Provider"
                color="accent"
                outlined
                dense
                hide-details
                :rules="[v => !!v]"
                @change="calculatePrice"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <!-- Result -->
          <v-row :class="`justify-end ${result ? 'pr-4' : 'pr-1'} pt-4`" align="center">
            <v-text-field
              class="result-input"
              label="Price with vat excluded"
              color="accent"
              v-model="excl_vat"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
            <span class="ml-2">{{ currency }}</span>
            <template v-if="inc_vat">
              <v-text-field
                class="result-input ml-4"
                label="Price with vat included"
                color="accent"
                v-model="inc_vat"
                outlined
                dense
                hide-details
                readonly
              ></v-text-field>
              <span class="ml-2">{{ currency }}</span>
            </template>
            <span v-if="applied_tariff_id" class="ml-4">
              Aplicated tariff 
              <v-chip class="ml-2 result-chip" 
                outlined 
                small
                color="accent"
                :to="`/tariffs/${applied_tariff_id}`" 
              >
                {{ applied_tariff_id }}
              </v-chip>
            </span>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
</template>

<script>
export default {
  data(){
    return {
      valid: true,
      result: null,
      kwh: 0,
      minutes: 0,
      chargePoint: null,
      connector: null,
      provider: null,

      chargePoints: [],
      emspProviders: []
    }
  },
  computed: {
    selectableChargePoints() {
      return this.chargePoints.map(cp => { return { text: cp.charge_point_reference_name, value: cp } })
    },
    chargePointConnectors() {
      return this.chargePoint?.connectors
    },
    excl_vat() {
      return this.result?.invoice?.total_cost?.excl_vat ? this.result.invoice.total_cost.excl_vat : 0
    },
    inc_vat() {
      return this.result?.invoice?.total_cost?.inc_vat ? this.result.invoice.total_cost.inc_vat : 0
    },
    currency() {
      return this.result ? this.result.currency : null
    },
    applied_tariff_id() {
      return this.result ? this.result.applied_tariff_id : null
    }
  },
  mounted() {
    this.$api.get(`/tools/chargepoints-connectors`)
      .then(resp => {this.chargePoints = resp.data })
      .catch(this.showError)

    this.$api.get("/providers-external")
          .then(res => {
              this.emspProviders = res.data
                  .filter(p => p.role && p.role.indexOf('EMSP') > -1)
                  .map(p => { 
                      return {
                          text: `${p.country_code}-${p.party_id} ${p.user.company.name} (${p.user.type})`,
                          value: p.id
                      }
                  })
          })
          .catch(this.showError)
  },
  methods:{
    calculatePrice() {
      if (!this.$refs.form || !this.$refs.form.validate()) {
        return this.result = null
      }

      this.$api.get(`/calculate-charge-price?kwh=${this.kwh}&minutes=${this.minutes}&connector=${this.connector}&provider=${this.provider}`)
        .then(resp => this.result = resp.data)
        .catch(_ => {
            this.result = null
            this.showError('Incorrect data entered or there is no tariff for this provider and connector.')
          })
    }
  }
}
</script>

<style scoped>
  >>> .result-input {
    max-width: 150px;
  }

  >>> .result-input input {
    text-align: center;
  }

  >>> .result-chip {
    min-width: 45px;
    justify-content: center;
  }
</style>