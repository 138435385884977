<template>
  <div>
    <v-data-table
        :headers="[
            {value: 'action', sortable:false, cellClass: 'td-linkto' },
            {text: 'Id.', value: 'id' },
            {text: 'Provider', value: 'provider'}
        ]"
        :items="providersWithoutTariffs"
        :loading="loading"       
        class="accent-table rounded-table"
        sort-by="id"
        dense
        no-data-text="No providers found without assigned tariffs"
    >
       <template v-slot:item.action="{ item }">
            <v-btn plain
                small
                :to="`/providers/${item.id}/connectors-without-tariffs`" 
                >
                <v-icon small>mdi-arrow-top-right</v-icon>
            </v-btn>
        </template>
        <template v-slot:item.provider="{ item }">
            <ProviderName
                :provider="{ id: item.id, country_code: item.provider_name.split('-')[0] , party_id: item.provider_name.split('-')[1] }"
            />
        </template>
    </v-data-table>    
  </div>
</template>

<script>
import ProviderName from "@/components/provider/ProviderName"

export default {
    components: {
        ProviderName
    },
    data() {
      return {
        loading: false,
        providersWithoutTariffs: []
      }
    },
    async mounted(){  
        this.loadProvidersWithoutTariffs()      
    },
    methods: {
      loadProvidersWithoutTariffs(){
        this.loading = true

        this.$api.get(`/providers-without-tariffs`)
            .then(resp => this.providersWithoutTariffs = resp.data)
            .catch(this.showError)
            .then(() => this.loading = false)
      }
    }
}
</script>