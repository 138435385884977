<template>
    <div class="d-flex flex-nowrap" :style="cssVars">
        <v-autocomplete
            class="component-select"
            @input="$emit('input', $event)"
            :items="items"
            :filter="filter"
            :label="label"
            height="36"
            single-line
            outlined
            hide-details
            dense
        >
            <template v-slot:item="{ item }">
                <span>{{ item.menuText }}</span>
            </template>
        </v-autocomplete>
        
        <v-tooltip bottom :disabled="!tooltipLabel">
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on"
                :loading="loading"
                @click="$emit('click')"
                depressed 
                :color="getColor(color)"
                class="component-button">
                    <slot></slot>
                </v-btn>
            </template>
            <span>{{ tooltipLabel }}</span>
        </v-tooltip>
    </div>
</template>

<script>
import colors from 'vuetify/lib/util/colors'

export default ({
    props: {
        // Object list with text(Displayed text of selected item), menuText(Text displayed in dropdown) and value(Returned value) parameters
        items: Array, 
        filter: Function, 
        label: String, 
        loading: Boolean, 
        'tooltip-label': String,
        color: {
            type: String,
            default: 'primary'
        },
        'text-color': {
            type: String,
            default: 'white'
        }
    },
    computed: {
        cssVars(){
            return {
                '--color': this.getColor(this.color),
                '--text-color': this.getColor(this.textColor)
            }
        }
    },
    methods: {
        getColor(color){
            if (color in colors) {
                // LibColor
                return colors[color].base
            } else {
                // ThemeColor
                let themeColor
                if (this.$vuetify.theme.isDark) {
                    themeColor = this.$vuetify.theme.themes.dark[color]
                } else {
                    themeColor = this.$vuetify.theme.themes.light[color]
                }

                // If color was not found neither in library nor in theme, input value is returned
                if(!themeColor) {
                    return color
                }

                return themeColor
            }
      }
    }
})
</script>

<style scoped>
    >>> .component-select.v-input {
        max-width: 115px;
        color: var(--color) !important;
        border-color: var(--color) !important;
        background-color: var(--color) !important;
    }
    >>> .component-select.v-input .v-input__slot {
        min-height: 36px!important;
    }
    >>> .component-select.v-input fieldset {
        border-color: var(--color) !important;
        border-right-color: var(--text-color) !important;
        border: 1px solid currentColor;
    }
    >>> .component-select.v-input input {
        color: var(--text-color) !important;
        caret-color: var(--text-color) !important;
    }
    >>> .component-select.v-input label {
        color: var(--text-color) !important;
    }
    >>> .component-select.v-input .v-input__append-inner {
        margin-top: 6px!important;
    }
    >>> .component-select.v-input .theme--light.v-icon {
        color: var(--text-color) !important;
    }
    >>> .component-select.v-text-field--outlined {
        border-radius: 4px 0 0 4px;
    }
    >>> .component-button.v-btn {
        color: var(--text-color) !important;
        border-radius: 0 4px 4px 0;
        height: 36px;
        min-width: 48px;
    }
    >>> .component-button.v-btn .v-icon {
        color: var(--text-color) !important;
    }
</style>
