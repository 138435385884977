import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import ClickOutside from 'vuetify/lib/directives/click-outside';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{directives:[{def: ClickOutside,name:"click-outside",rawName:"v-click-outside",value:(function (e) { return _vm.onClickOutside(e); }),expression:"(e) => onClickOutside(e)"}],staticClass:"elevation-0",staticStyle:{"height":"20px"},attrs:{"loading":_vm.loading}},[_c('template',{slot:"progress"},[_c(VProgressLinear,{attrs:{"color":"accent","indeterminate":""}})],1),_c(VRow,{attrs:{"dense":""}},[_c(VBtn,{staticClass:"d-none d-md-block",attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c(VIcon,[_vm._v("mdi-magnify")])],1),_c(VBtn,{staticClass:"d-block d-md-none",attrs:{"icon":"","to":"/searcher"}},[_c(VIcon,[_vm._v("mdi-magnify")])],1),_c('transition',{attrs:{"name":"slide-y-transition"}},[(_vm.show)?_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"md":"6"}},[_c(VTextField,{attrs:{"dense":"","clearable":"","placeholder":"ID / OCPI ID Search","color":"accent","hide-details":"","autofocus":""},on:{"click":_vm.onClick,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},model:{value:(_vm.search_str),callback:function ($$v) {_vm.search_str=$$v},expression:"search_str"}})],1),_c(VCol,{staticClass:"d-inline",attrs:{"md":"6"}},[_c(VBtn,{staticClass:"ml-1 rounded-r-0",staticStyle:{"min-width":"135px"},attrs:{"color":"accent","elevation":"0","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.search.apply(null, arguments)}}},[_vm._v(" Search "+_vm._s(_vm.search_model)+" ")]),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"accent rounded-l-0 v-btn-groove","elevation":"0","x-small":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-down-drop-circle-outline")])],1)]}}],null,false,2474252260)},[_c(VList,{staticClass:"non-v-click-outside"},[_c(VListItemGroup,{attrs:{"color":"primary"},model:{value:(_vm.search_model),callback:function ($$v) {_vm.search_model=$$v},expression:"search_model"}},_vm._l((_vm.search_model_options),function(item,index){return _c(VListItem,{key:index,staticClass:"non-v-click-outside",attrs:{"value":item}},[_c(VListItemTitle,{staticClass:"non-v-click-outside"},[_vm._v(_vm._s(item))])],1)}),1)],1)],1)],1)],1):_vm._e()],1)],1),_c(VRow,{staticClass:"elevation-20 mt-0 d-block",staticStyle:{"position":"absolute","right":"0"}},[(_vm.sessions.length)?_c(VCol,{staticClass:"py-0 px-0"},[_c(VDataTable,{staticClass:"accent-table",attrs:{"items":_vm.sessions,"headers":[
                    { value: 'click', sortable: false, cellClass: 'td-linkto' },
                    { text: 'Id.', value: 'id' },
                    { text: 'Sender Id.', value: 'sender_provider_id' },
                    { text: 'Receiver Id.', value: 'receiver_provider_id' },
                    { text: 'OCPI Id.', value: 'ocpi_id' },
                    { text: 'Created at', value: 'created_at' } ],"disable-sort":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.click",fn:function(ref){
                var item = ref.item;
return [_c(VBtn,{attrs:{"plain":"","small":"","to":("/session/" + (item.id))}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1)]}}],null,false,2522214541)})],1):_vm._e(),(_vm.cdrs.length)?_c(VCol,{staticClass:"py-0 px-0"},[_c(VDataTable,{staticClass:"accent-table",attrs:{"items":_vm.cdrs,"headers":[
                    { value: 'click', sortable: false, cellClass: 'td-linkto' },
                    { text: 'Id.', value: 'id' },
                    { text: 'Session Id.', value: 'charging_session_id' },
                    { text: 'OCPI Id.', value: 'ocpi_id' },
                    { text: 'Total Cost', value: 'total_cost.incl_vat' } ],"disable-sort":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.click",fn:function(ref){
                var item = ref.item;
return [_c(VBtn,{attrs:{"plain":"","small":"","to":("/session/" + (item.charging_session_id))}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1)]}}],null,false,848958540)})],1):_vm._e(),(_vm.no_data && _vm.search_str)?_c(VCol,{staticClass:"accent white--text no-data"},[_c('span',[_vm._v("No data found")])]):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }