import Vue from "vue"
import Router from "vue-router"
import store from "./store"

// Common
import Login from "./views/Login.vue"
import Page404 from "./views/Page404.vue"

import Providers from "./views/Providers.vue"
import Connections from "./views/Connections.vue"
import Connection from "./views/Connection.vue"
import ConnectionEdit from "./views/Connection.edit"
import Provider from "./views/Provider.vue"
import ProviderEdit from "./views/Provider.edit"
import Location from "./views/Location"
import Chargepoint from "./views/Chargepoint"
import Evse from "./views/Evse"
import Commands from "./views/Commands.vue"
import CommandStart from "./views/CommandStart.vue"
import CommandStop from "./views/CommandStop.vue"
import Locations from "./views/Locations.vue"
import LocationGroups from "./views/LocationGroups.vue"
import LocationGroup from "./views/LocationGroup.vue"
import Sessions from "./views/Sessions.vue"
import OutSessionQueues from "./views/OutSessionQueues.vue"
import FailuredRequests from "./views/Settings/FailuredRequests.vue"
import Cdrs from "./views/Cdrs.vue"
import CdrProcesses from "./views/CdrProcesses.vue"
import Tokens from "./views/Tokens.vue"
import Tariffs from "./views/Tariffs.vue"
import Tariff from "./views/Tariff.vue"
import TariffEdit from "./views/Tariff.edit.vue"
import Tools from "./views/Settings/Tools.vue"
import ProviderConnectorsWithoutTariffs from "./components/tools/ProviderConnectorsWithoutTariffs.vue"
import Session from "./views/Session.vue"
import CronJobs from "./views/CronJobs.vue"
import ChargeRequests from "./views/ChargeRequests.vue"
import AllowedRealTimeAuthorizations from "./views/AllowedRealTimeAuthorizations.vue"
import Invoices from "./views/Invoices.vue"
import BillingDashboard from "./views/BillingDashboard.vue"
import Alerts from "./views/Alerts.vue"

// Company
import CompanyEdit from "./views/Company/Company.edit"

// Provider
import ProviderHttpLogs from "./views/Provider/HttpLogs.vue"
import ProviderCronJobs from "./views/Provider/CronJobs.vue"
import ProviderLocations from "./views/Provider/Locations.vue"
import ProviderTokens from "./views/Provider/Tokens.vue"
import ProviderCommands from "./views/Provider/Commands.vue"
import ProviderSessions from "./views/Provider/Sessions.vue"
import ProviderCdrs from "./views/Provider/Cdrs.vue"
import ProviderTariffs from "./views/Provider/Tariffs.vue"

// Reports
import SessionsByProvider from "./views/Reports/SessionsByProvider.vue"
import LocationsAmount from "./views/Reports/LocationsAmount.vue"
import PendingLocationsExportToCore from "./views/Reports/PendingLocationsExportToCore.vue"

// Settings
import Settings from "./views/Settings/Settings.vue"
import Users from "./views/Settings/Users.vue"
import ExportLocationsToCore from './views/Settings/ExportLocationsToCore'
import Wenea from "./views/Settings/Wenea.vue"
import HttpInternalLogs from "./views/Settings/HttpInternalLogs"
import Searcher from './views/Settings/Searcher'


Vue.use(Router)

const router = new Router({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        { path: '/', redirect: '/providers' },
        { path: '/login', name: 'login', component: Login, meta: { guestAllow: true } },
        
        { path: '/providers', name: 'providers', component: Providers },
        { path: '/provider/create', name: 'Provider.create', component: ProviderEdit },
        { path: '/provider/:id/edit', name: 'Provider.edit', component: ProviderEdit },
        { path: '/provider/:id/http-logs', name: 'provider-httplogs', component: ProviderHttpLogs },
        { path: '/provider/:id/cron-jobs', name: 'provider-cron-jobs', component: ProviderCronJobs },
        { path: '/provider/:id/:role/locations', name: 'provider-locations', component: ProviderLocations },
        { path: '/provider/:id/:role/tokens', name: 'provider-tokens', component: ProviderTokens },
        { path: '/provider/:id/:role/commands', name: 'provider-commands', component: ProviderCommands },
        { path: '/provider/:id/:role/sessions', name: 'provider-sessions', component: ProviderSessions },
        { path: '/provider/:id/:role/cdrs', name: 'provider-cdrs', component: ProviderCdrs },
        { path: '/provider/:id/:role/tariffs', name: 'provider-tariffs', component: ProviderTariffs },
        { path: '/provider/:id', name: 'Provider', component: Provider },
        
        { path: '/connections', name: 'connections', component: Connections },
        { path: '/connection/create', name: 'connection.create', component: ConnectionEdit },
        { path: '/connection/:id/edit', name: 'connection.edit', component: ConnectionEdit },
        { path: '/connection/:id', name: 'connection', component: Connection },

        { path: '/company/create', name: 'company.create', component: CompanyEdit },
        { path: '/company/:id/edit', name: 'company.edit', component: CompanyEdit },

        
        { path: '/location/:id', name: 'Location', component: Location },
        { path: '/chargepoint/:id', name: 'Chargepoint', component: Chargepoint },
        { path: '/evse/:id', name: 'Evse', component: Evse },
        
        { path: '/commands', name: 'commands', component: Commands },
        { path: '/command/START_SESSION/:id', name: 'CommandStart', component: CommandStart },
        { path: '/command/STOP_SESSION/:id', name: 'CommandStop', component: CommandStop },
        { path: '/location-groups', name: 'location-groups', component: LocationGroups },
        { path: '/location-group/:id', name: 'location-group', component: LocationGroup },
        { path: '/locations', name: 'locations', component: Locations },
        
        { path: '/sessions', name: 'sessions', component: Sessions },
        { path: '/session/:id', name: 'session', component: Session },
        
        { path: '/tariffs', name: 'tariffs', component: Tariffs },
        { path: '/tariffs/create', name: 'tariff-create', component: TariffEdit },
        { path: '/tariffs/:id', name: 'tariff', component: Tariff },
        { path: '/tariffs/:id/edit', name: 'tariff-edit', component: TariffEdit },
        { path: '/cdrs', name: 'cdrs', component: Cdrs },
        { path: '/cdr-processes', name: 'cdr-processes', component: CdrProcesses },
        { path: '/tools', name: 'tools', component: Tools },
        { path: '/providers/:id/connectors-without-tariffs', name: 'provider-connectors-without-tariffs', component: ProviderConnectorsWithoutTariffs },
        
        { path: '/allowed-real-time-authorizations', name: 'allowed-real-time-authorizations', component: AllowedRealTimeAuthorizations },
        { path: '/charge-requests', name: 'charge-requests', component: ChargeRequests },
        { path: '/billing-dashboard', name: 'billing-dashboard', component: BillingDashboard },
        { path: '/invoices', name: 'invoices', component: Invoices },
        
        { path: '/sessions-by-provider', name: 'sessions-by-provider', component: SessionsByProvider },
        { path: '/locations-amount', name: 'locations-amount', component: LocationsAmount },
        { path: '/pending-locations-export-to-core', name: 'pending-locations-export-to-core', component: PendingLocationsExportToCore },

        { path: '/out-session-queues', name: 'out-session-queues', component: OutSessionQueues },
        { path: '/failured-requests', name: 'failured-requests', component: FailuredRequests },
        { path: '/users', name: 'users', component: Users },
        { path: '/tokens', name: 'tokens', component: Tokens },
        { path: '/cron-jobs', name: 'cron-jobs', component: CronJobs },
        
        { path: '/wenea', name: 'Wenea', component: Wenea },
        { path: '/settings', name: 'Settings', component: Settings },
        { path: '/export-locations-to-core', name: 'Export Locations to Core', component: ExportLocationsToCore },
        { path: '/http-internal-logs', name: 'HTTP Internal Logs', component: HttpInternalLogs },
        { path: '/searcher', name: 'Searcher', component: Searcher },
        { path: '/alerts', name: 'alerts', component: Alerts },
        { path: '/404', name: '404', component: Page404 },
        { path: '*', component: Page404 }
    ]
})

router.beforeEach(async (to, from, next) => {

    if( to.matched.some(record => record.meta.guestAllow) ){
        next()
        return
    }

    if( !store.getters.isLoggedIn ){
        next('/login')
        return
    }

    next()
})

router.afterEach(to => {
    if (to.meta.title) {
      document.title = `${to.meta.title}`;
    }
  });

export default router