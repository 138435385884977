import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-nowrap",style:(_vm.cssVars)},[_c(VAutocomplete,{staticClass:"component-select",attrs:{"items":_vm.items,"filter":_vm.filter,"label":_vm.label,"height":"36","single-line":"","outlined":"","hide-details":"","dense":""},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.menuText))])]}}])}),_c(VTooltip,{attrs:{"bottom":"","disabled":!_vm.tooltipLabel},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"component-button",attrs:{"loading":_vm.loading,"depressed":"","color":_vm.getColor(_vm.color)},on:{"click":function($event){return _vm.$emit('click')}}},'v-btn',attrs,false),on),[_vm._t("default")],2)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltipLabel))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }