import Vue from "vue"
import store from '@/store'

export default {

    async connect () {

        return new Promise((resolve, reject) => {
    
            const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
            let wsPort = window.location.port === '' ? '' : ':8899'
            if( window.location.port === "4433" ){
                //En producción utilizamos un puerto especial para el SSL
                wsPort = ":4433"
            }
            const wsDomain = `${wsProtocol}//${window.location.hostname}${wsPort}`
    
            Vue.ws.disconnect()
            Vue.ws.connect(
                {
                    wsDomain,
                    jwtToken: localStorage.getItem("token")
                },
                {
                    path: 'adonis-ws',
                    reconnection: true,
                    reconnectionAttempts: 300,
                    reconnectionDelay: 5000
                }
            )
    
            Vue.ws.socket.on("open", () => {
                console.log('WS Connected to Worker')
                store.commit('ws_connected')

                // Worker events
                let alertsSubscription = Vue.ws.socket.getSubscription('backoffice:alerts')
                if( !alertsSubscription ){
                    alertsSubscription = Vue.ws.subscribe('backoffice:alerts') 
                }
                alertsSubscription.on('new_alerts', raw => store.commit('set_alerts', parseInt(raw)))

                resolve()
            })
    
            Vue.ws.socket.on("close", () => {
                console.error('WS Disconnected from Worker')
            })
    
            Vue.ws.socket.on("error", (err) => {
                console.error("WS Error: ", err)
            })
    
        })
    },

    disconnect () {
        Vue.ws.disconnect()
    },

    getSubscription(channel){

        let wsChannel = Vue.ws.socket.getSubscription(channel)
        if ( !wsChannel ) {
            wsChannel = Vue.ws.subscribe(channel)
        }

        return wsChannel

    }
}