<template>
  <div>
    <template v-if="tariffConfig">
        <v-row>
            <v-col cols="12" md="3">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>
                            <template v-if="tariffConfig.applicable_providers">
                                <ProviderName
                                    :key="applicable_provider" v-for="applicable_provider of tariffConfig.applicable_providers"
                                    class="ml-1"
                                    :provider="providers.find(p => p.id == applicable_provider)"
                                    extra="applicable_provider"
                                />
                            </template>
                            <template v-else-if="tariffConfig.applicable_user">
                                <ProviderName
                                    :provider="user"
                                    extra="applicable_user"
                                    type="title"
                                />
                            </template>
                            <template v-else>
                                <v-chip small outlined color="accent">DEFAULT</v-chip>
                            </template>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <template v-if="tariffConfig.applicable_providers">
                                <span>Applicable providers</span>
                            </template>
                            <template v-else-if="tariffConfig.applicable_user">
                                <span>Applicable user</span>
                            </template>
                            <template v-else>
                                <span>Applicable provider/user</span>
                            </template>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-col>
            <v-col cols="12" md="3">
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title>
                            <div v-if="tariffConfig.priority" class="d-flex flex-row">
                                <span style="line-height: 24px; vertical-align: middle;">{{ tariffConfig.priority }}</span>
                                <PriorityIcon :priority="tariffConfig.priority" :max="9" :min="1"></PriorityIcon>
                            </div>
                            <template v-else>-</template>
                        </v-list-item-title>
                        <v-list-item-subtitle>Priority</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-col>
            <v-col cols="12" md="12">
                <v-list-item two-line>
                    <v-list-item-content>
                        <p>{{ tariffConfig.applicable_query }}</p>
                        <v-list-item-subtitle>Applicable query</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-col>

            <v-btn
                class="ml-6 mb-6"
                small
                depressed
                max-width="165"
                color="accent"
                :disabled="disabled"
                @click="updateTariffRelations"
            >
                Update relations
            </v-btn>
        </v-row>
    </template>
    <template v-else>
        <p class="ml-6 mt-4">No configurations have been defined.</p>
    </template>
  </div>
</template>

<script>
import PriorityIcon from "@/components/icons/PriorityIcon"
import ProviderName from "@/components/provider/ProviderName"

export default {
    name: "tariff-configurations",
    props: ["value", "disabled"],
    components: {
        PriorityIcon,
        ProviderName
    },
    data() {
      return {
            providers: [],
            user: null,
            tariffConfig: {}
      }
    },
    mounted(){
      this.loadTariffConf()
    },
    watch:{
        value() {
            this.loadTariffConf()
        }
    },
    methods: {
        loadTariffConf(){
            this.tariffConfig = this.value

            if (this.tariffConfig.applicable_providers) {
                this.$api.get("/providers")
                    .then(res => this.providers = res.data)
                    .catch(this.showError)
            }

            if (this.tariffConfig.applicable_user) {
                this.$api.get(`/connections/${this.tariffConfig.applicable_user}`)
                    .then(res => this.user = {
                            username: res.data.username,
                            applicable_user: res.data.id
                        })
                    .catch(this.showError)
            }

        },
        updateTariffRelations(){
            this.$api.put(`/tariffs/${this.tariffConfig.tariff_id}/relations`)
                .then(() => this.showSuccess("Tariff relations have been successfully updated"))
                .catch(this.showError)
        }
    }
}
</script>

<style scoped>
   .cancel-btn {
    color: black !important;
    border: 1px solid #ccc!important;
  }
</style>