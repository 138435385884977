import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v("Wenea Token Tool")]),_c(VCardText,[_c('p',[_vm._v("Make test calls against provider endpoints.")]),_c('ul',[_c('li',[_c('strong',[_vm._v("Send:")]),_vm._v(" PUT a Test Token.")]),_c('li',[_c('strong',[_vm._v("Gets:")]),_vm._v(" GET of test token inserted in the previous test. ")])])]),_c(VCardActions,[_c(VRow,{staticClass:"px-3 pb-3"},[_c(VBtn,{staticClass:"mb-2",attrs:{"text":"","block":"","color":"accent","loading":_vm.loadingSendTestToken},on:{"click":_vm.sendTestToken}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-send")]),_vm._v(" Send Test Token ")],1),_c(VBtn,{attrs:{"text":"","block":"","color":"accent","loading":_vm.loadingGetTestToken},on:{"click":_vm.retrieveTestToken}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Get Test Token ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }