<template>
    <v-container fluid>

        <AppBar icon="mdi-map-marker" :breadcrumbs="[
            { text: 'Providers', to: '/'},
            { text: providerInfo.name, to: `/provider/${providerInfo.id}`, icon: 'mdi-earth' }, 
            { text: locationInfo.name, to: `/location/${locationInfo.id}`, icon: 'mdi-map-marker' },
            { text: evse ? evse.evse_id : '-', icon: 'mdi-ev-station'}
        ]"/>

        <h1>
            <v-icon left large>mdi-ev-station</v-icon>{{evse ? evse.evse_id : ''}}
        </h1>
        <v-card v-if="evse" :loading="loading">
            <v-row>
                <v-col cols="12" md="3">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>Id.</v-list-item-title>
                            <v-list-item-subtitle>{{evse.id}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="3">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>OCPI Id.</v-list-item-title>
                            <v-list-item-subtitle>{{evse.evse_id}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="3">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>Status</v-list-item-title>
                            <v-list-item-subtitle>{{evse.status}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="3" v-if="evse.coordinates">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>Coordinates</v-list-item-title>
                            <v-list-item-subtitle>
                                <v-btn text @click="openMaps(evse)">{{evse.coordinates.latitude}}, {{evse.coordinates.longitude}}</v-btn>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
        </v-card>

        <v-btn text class="accent" @click="startSession">
            <v-icon left>mdi-pencil</v-icon>
            START SESSION
        </v-btn>
        
        <h1 class="mt-4 ml-2">
            <v-icon large>mdi-power-plug</v-icon>    
            Connectors
        </h1>
        <v-card :loading="loading">
            <v-data-table
                v-if="connectors"
                :headers="[
                    {text: 'Id.', value: 'id'},
                    {text: 'Standard', value: 'standard'},
                    {text: 'Format', value: 'format'},
                    {text: 'Power type', value: 'power_type'},
                    {text: 'Max voltage', value: 'max_voltage'},
                    {text: 'Max amperage', value: 'max_amperage'},
                    {text: 'Last updated', value: 'last_updated'}
                ]"
                :items="connectors"
                disable-sort
                disable-pagination
                hide-default-footer
                class="elevation-0 pointer-on-hover"
            >
            </v-data-table>
        </v-card>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"

export default {
    name: "evse",
    components: { AppBar },
    data() {
        return {
            loading: false,
            evse: null,
            location: null,
            connectors: null,
            provider: null
        }
    },
    computed: {
        locationInfo: function(){
            return this.location ? { name: this.location.name, id: this.location.id } : { name: '-', id: null }
        },
        providerInfo: function(){
            return this.provider ? { name: `${this.provider.country_code}-${this.provider.party_id} (${this.provider.role})`, id: this.provider.id } : { name: '-', id: null }
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load(){
            
            let id = this.$router.currentRoute.params.id
            if( !id ){ this.showError('ID is required!'); return; }

            this.loading = true
            this.$api.get(`provider/location/evse/${id}`)
                .then(resp => {

                    this.evse = resp.data
                    this.provider = resp.data.location.provider
                    this.location = resp.data.location
                    this.connectors = resp.data.connectors

                    /*this.$api.get(`/provider/client/${resp.data.location.provider_client_id}`)
                        .then(resp => { this.providerClient = resp.data })
                        .catch(e => { this.showError(e) })*/

                })
                .catch(e => { this.showError(e) })
                .then(_ => { this.loading = false })
            
        },
        openMaps(item){
            window.open(`https://www.google.com/maps/search/?api=1&query=${item.coordinates.latitude},${item.coordinates.longitude}`)
        },
        startSession(){

            this.$api.get(`evse/${this.evse.id}`)
                .then(this.showSuccess)
                .catch(this.showError)

        }
    }
};
</script>
