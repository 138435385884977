<template>
    <v-container fluid>

        <AppBar title="Out session queues" icon="mdi-tray-full" />

        <!-- Table filters -->
        <v-row class="mb-2 px-2" align="center">
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="tableSearch"  
                    clearable 
                    hide-details
                    append-icon="mdi-magnify"
                    placeholder="Search"
                    color="accent"
                    @keyup.enter="onFilter"
                    >
                </v-text-field>
            </v-col>

            <v-btn 
                class="ml-2 mt-3"
                color="accent"
                @click="onFilter"
            >
                <v-icon>mdi-filter</v-icon>
            </v-btn>
        </v-row>

        <template>
            <!-- Data table -->
            <v-data-table
                class="accent-table rounded-table"
                :headers="[
                    { text: 'Queue name', value: 'qname', align: 'center' },
                    { text: 'Event type', value: 'event_type', align: 'center'  },
                    { text: 'Key', value: 'key' , align: 'center' },
                    { text: 'Fails', value: 'fails', align: 'center'  },
                    { text: 'Errors', value: 'errors', align: 'center'  },
                ]"
                :items="queues"
                :search="searchFilter"
                :loading="tableLoading"               
                group-by="qname"
                sort-by="key"
                sort-desc
                dense
            >
                <template v-slot:group.header="{ group, headers, toggle, isOpen }">
                    <td :colspan="headers.length">
                        <v-btn @click="toggle" icon :ref="group">
                            <v-icon v-if="isOpen">mdi-minus</v-icon>
                            <v-icon v-else>mdi-plus</v-icon>
                        </v-btn>
                        <span class="ml-5 font-weight-bold">Session queue</span>
                        <v-btn class="ml-0 pl-1" plain small :to="`/session/${group.split(':')[1]}`">
                            #{{ group.split(':')[1] }}
                            <v-icon small>mdi-arrow-top-right</v-icon>
                        </v-btn>

                        <v-btn class="float-right" 
                            @click="finishQueue(group.split(':')[1])" 
                            plain color="red"
                            :loading="finishBtnLoading"
                        >
                            <v-icon left>mdi-tray-remove</v-icon>
                            Finish queue
                        </v-btn>
                    </td>
                </template>

                <template v-slot:item.errors="{ item }">
                    <json-viewer v-if="item.errors"
                        class="pa-2 text-left"
                        :value="item.errors"
                        :expand-depth="0"
                        >
                    </json-viewer>
                </template>
            </v-data-table>
        </template>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"

export default {
    name: "wenea",
    components: {
        AppBar
    },
    data() {
        return {
            queues: [],
            tableSearch: "",
            searchFilter: "",
            tableLoading: false,
            finishBtnLoading: false
        }
    },
    mounted(){
        this.load()
    },
    methods: {
        load() {
            this.tableLoading = true
            this.$api.get("/out-session-queues")
                .then(res => { this.queues = res.data })
                .catch(this.showError)
                .then(() => this.tableLoading = false)
        },
        finishQueue(session_id){
            const confirmed = confirm('Are you sure to end queue for this session?')
            if(!confirmed){ return }
            
            this.finishBtnLoading = true
            this.$api.delete(`/out-session-queues/${session_id}`)
                .then(this.showSuccess('Queue has been finished'))
                .catch(this.showError)
                .then(() => { 
                    this.load()
                    this.finishBtnLoading = false
                })
        },
        onFilter(){
            this.searchFilter = this.tableSearch
        }
    }
};
</script>
