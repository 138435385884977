import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"breadcrumbs":[
        { text: 'Providers', to: '/providers' },
        { text: _vm.name, icon: 'mdi-earth', to: ("/provider/" + _vm.provider_id) },
        { text: 'Commands' }
        ]}}),(_vm.provider)?_c(VContainer,{attrs:{"fluid":""}},[_c('h2',[_c(VIcon,{attrs:{"left":"","color":"accent"}},[_vm._v("mdi-console-line")]),_vm._v(" Commands")],1),_c('ProviderCommands',{attrs:{"providerId":_vm.provider.id,"role":this.$route.params.role}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }