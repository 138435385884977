<template>
    <v-dialog v-model="show" persistent max-width="500px" @click:outside="close">
        <v-card>
            <v-card-title class="accent white--text">
                <v-icon left color="white">mdi-key</v-icon><span>Token</span>
            </v-card-title>

            <v-card-text class="pa-4 pt-10 text-center">
                <v-row>
                    <v-col>
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-subtitle>Plain Token</v-list-item-subtitle>
                                <v-list-item-title class="text-wrap">

                                    {{ token }}

                                    <v-btn icon @click="clipboard(token)">
                                        <v-icon>mdi-content-copy</v-icon>
                                    </v-btn>

                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>
                <v-row >
                    <v-col>
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-subtitle>Encoded Token</v-list-item-subtitle>
                                <v-list-item-title class="text-wrap">
                                    
                                    {{ encodedToken }}

                                    <v-btn icon @click="clipboard(encodedToken)">
                                        <v-icon>mdi-content-copy</v-icon>
                                    </v-btn>
                                
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn @click="close" depressed>Close</v-btn>
            </v-card-actions>
            
           
        </v-card>
    </v-dialog>
</template>
<script>
import { EventBus } from "@/event-bus"

export default {
    data() {
        return {
            show: false,
            token: null,
            encodedToken: null,
        }
    },
    created() {
        var vm = this
        EventBus.$on("dialog-show-token:open", (args) => {
            vm.token = args
            this.encodedToken = this.encodeToken()
            vm.show = true
        })
        EventBus.$on("dialog-show-token:close", () => {
            vm.show = false
        })
    },
    methods: {
        encodeToken() {
            return btoa(this.token)
        },
        close() {
            EventBus.$emit("dialog-show-token:close")
        },
    }
}
</script>