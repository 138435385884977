import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.provider)?_c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"breadcrumbs":[
        { text: 'Providers', to: '/providers' },
        { text: _vm.name, icon: 'mdi-earth', to: ("/provider/" + _vm.provider_id) },
        { text: 'Locations' }
        ]}}),(_vm.hasRole('EMSP') || _vm.hasRole('NSP'))?_c(VContainer,{attrs:{"fluid":""}},[_c('h2',{staticClass:"mt-6"},[_c(VIcon,{attrs:{"left":"","color":"accent"}},[_vm._v("mdi-folder-marker")]),_vm._v(" Location groups ")],1),_c('ProviderLocationGroups',{attrs:{"provider":_vm.provider}})],1):_vm._e(),(_vm.hasRole('EMSP') || _vm.hasRole('NSP'))?_c(VContainer,{attrs:{"fluid":""}},[_c('h2',{staticClass:"mt-6"},[_c(VIcon,{attrs:{"left":"","color":"accent"}},[_vm._v("mdi-map-marker")]),_vm._v(" Wenea locations into provider ")],1),_c('LocationsIntoProvider',{attrs:{"provider-id":_vm.provider.id}})],1):_vm._e(),(_vm.hasRole('CPO'))?_c(VContainer,{attrs:{"fluid":""}},[_c('h2',{staticClass:"mt-6"},[_c(VIcon,{attrs:{"left":"","color":"accent"}},[_vm._v("mdi-map-marker")]),_vm._v(" Locations from Provider ")],1),_c('LocationsFromProvider',{attrs:{"provider-id":_vm.provider.id}})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }