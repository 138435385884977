<template>
    <v-container fluid>
        <AppBar title="Failured requests" icon="mdi-alert-octagon" />

        <!-- Table filters -->
        <v-row class="mb-2 px-2" align="center">
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="tableSearch"
                    placeholder="Search"
                    append-icon="mdi-magnify"
                    color="accent"
                    hide-details
                    clearable
                    @keyup.enter="onFilter"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-select
                    v-model="selectedType"
                    :items="types"
                    label="Type"
                    color="accent"
                    clearable
                    hide-details
                >
                    <template v-slot:selection="{ item }">
                        <v-chip class="text-uppercase" outlined small>{{item}}</v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                        <span class="text-uppercase">{{ item }}</span>
                    </template>
                </v-select>
            </v-col>
            <v-col cols="12" md="2">
                <v-select
                    v-model="selectedProvider"
                    :items="providers"
                    label="Providers"
                    color="accent"
                    clearable
                    hide-details
                >
                </v-select>
            </v-col>
            
            <v-col cols="12" md="2">
                <v-menu
                    ref="menu"
                    v-model="showDateFromPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"   
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="selectedDateFrom"
                            label="Date from"
                            prepend-icon="mdi-calendar"
                            color="accent"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="selectedDateFrom"
                        @input="showDateFromPicker = false"
                        no-title
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="12" md="2">
                <v-menu
                    ref="menu"
                    v-model="showDateToPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"   
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="selectedDateTo"
                            label="Date to"
                            prepend-icon="mdi-calendar"
                            color="accent"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="selectedDateTo"
                        @input="showDateToPicker = false"
                        no-title
                    ></v-date-picker>
                </v-menu>
            </v-col>

            <v-btn 
                class="ml-2 mt-3"
                color="accent"
                @click="onFilter"
            >
                <v-icon>mdi-filter</v-icon>
            </v-btn>

        </v-row>
        
        <!-- Data table -->
        <v-data-table
            class="accent-table rounded-table"
            :items="filteredFailuredRequests"
            :headers="[
                { text: 'Type', value: 'type' },
                { text: 'Provider', value: 'provider', sortable: false },
                { text: 'Created at', value: 'timestamp' },
                { text: 'Actions', value: 'actions', sortable: false },
            ]"
            dense
            :search="searchFilter"
            :custom-filter="dataTableDeepSearch"
            :loading="loading"        
            sort-by="timestamp"
            item-key="key"
            show-expand
        >
            <template v-slot:item.type="{ item }">
                <v-chip class="text-uppercase" outlined small>{{item.type}}</v-chip>
            </template>
            <template v-slot:item.timestamp="{ item }">
                {{ item.timestamp | dateFullFormat }}
            </template>
            <template v-slot:item.provider="{ item }">
                <ProviderName
                    v-if="!!item.user.provider_name"
                    :provider="{ id: item.user.provider_id, country_code: item.user.provider_name.split(' ')[0] }"
                />
            </template>
            <template v-slot:item.actions="{ item }">
            <v-icon
                small
                @click="deleteItem(item)"
            >
                mdi-delete
            </v-icon>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-row>
                        <v-col cols="12" md="6" class="d-flex flex-column">
                            <span class="pl-2 py-2 text-overline">Request</span>
                            <json-viewer class="pa-0"
                                :value="item.request"
                                :copyable="{copyText: 'Copy', copiedText: 'Copied!', timeout: 2000}"
                                :expand-depth="1"
                                >
                            </json-viewer>
                        </v-col>
                        <v-divider vertical class="my-3"></v-divider>
                        <v-col cols="12" md="6" class="d-flex flex-column">
                            <span class="pl-2 py-2 text-overline">Response</span>
                            <json-viewer class="pa-0"
                                :value="item.response"
                                :copyable="{copyText: 'Copy', copiedText: 'Copied!', timeout: 2000}"
                                :expand-depth="1"
                                >
                            </json-viewer>
                        </v-col>
                    </v-row>
                </td>
            </template>
        </v-data-table>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="mt-5 mr-2 v-btn v-btn--outlined v-btn--router theme--light v-size--default accent--text" text @click="closeDelete">Cancel</v-btn>
                <v-btn class="mt-5 mr-2 v-btn v-btn--outlined v-btn--router theme--light v-size--default accent--text" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import AppBar from '@/components/AppBar'
import ProviderName from "@/components/provider/ProviderName"

export default {
    components: {
        AppBar,
        ProviderName
    },
    data() {
        return {
            loading: false,
            dialogDelete: false,
            tableSearch: null,
            searchFilter: null,
            failuredRequests: [],
            selectedfailuredRequest: {
                key: null,
                type: null
            },
            selectedType: null,
            selectedProvider: null,
            selectedDateFrom: null,
            showDateFromPicker: false,
            selectedDateTo: null,
            showDateToPicker: false,
        }
    },
    watch:{
        selectedDateFrom(val) {
            if (val && val > this.selectedDateTo) {
                this.selectedDateTo = val
            }
        },
        selectedDateTo(val) {
            if (val && val < this.selectedDateFrom) {
                this.selectedDateFrom = val
            }
        },
        dialogDelete (val) {
        val || this.closeDelete()
        },
    },
    computed: {
        filteredFailuredRequests() {
            let filteredFailuredRequests = this.failuredRequests
            const dateFormater = this.$options.filters.dateFormat

            if (this.selectedType){
                filteredFailuredRequests = filteredFailuredRequests.filter(input => input.type == this.selectedType)
                filteredFailuredRequests = filteredFailuredRequests.filter(input => input.type == this.selectedType)
            }

            if (this.selectedProvider){
                filteredFailuredRequests = filteredFailuredRequests.filter(input => input.user.provider_id == this.selectedProvider)
            }

            if (this.selectedDateFrom){ 
                filteredFailuredRequests = filteredFailuredRequests.filter(input => dateFormater(input.timestamp) >= dateFormater(this.selectedDateFrom))
            }

            if (this.selectedDateTo){  
                filteredFailuredRequests = filteredFailuredRequests.filter(input => dateFormater(input.timestamp) <= dateFormater(this.selectedDateTo))
            }

            return filteredFailuredRequests
        },
        types() {
            const types = this.failuredRequests.map(input => input.type)
            return [ ...new Set(types) ]
        },
        providers() {
            const types = this.failuredRequests.map(input => { return { value: input.user.provider_id, text: input.user.provider_name?.split(' ')[0] } })
            return [ ...new Set(types) ]
        },
    },
    mounted(){
        this.loadFailuredRequests()
    },
    methods: {
        loadFailuredRequests() {
            this.loading = true
            this.$api.get('failured-requests')
                .then(res => { this.failuredRequests = res.data })
                .catch(this.showError)
                .then(() => this.loading = false)
        },
        deleteItem(item) {
            this.selectedfailuredRequest = Object.assign({}, item)
            this.dialogDelete = true
        },
        deleteItemConfirm () {
            if(this.selectedfailuredRequest.type != "undefined"){
                //Api delete failured-requests/{id}?type={type} if type is not undefined string
                this.$api.delete(`failured-requests/${this.selectedfailuredRequest.key}?type=${this.selectedfailuredRequest.type}`)
                    .then(res => {
                        this.loadFailuredRequests()
                        this.closeDelete()
                    })
                    .catch(this.showError)
            }
            else{
                this.$api.delete(`failured-requests/${this.selectedfailuredRequest.key}`)
                    .then(res => {
                        this.loadFailuredRequests()
                    })
                    .catch(this.showError)
            }
            this.closeDelete()
        },
        closeDelete () {
            this.dialogDelete = false
        },
        onFilter(){
            this.searchFilter = this.tableSearch
        }
    }
};
</script>

<style scoped>
    >>> .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
        box-shadow: none;
    }
</style>
