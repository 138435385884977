<template>
    <v-content>
        <v-container fluid>
            <v-col cols="12">
                <v-row align="center" justify="center" style="height: 600px;">
                    <v-flex xs12 sm8 md4 style="min-width: 400px">
                        <form @submit.prevent="login">
                            <v-card class="elevation-12">
                                <v-toolbar color="primary" dark flat>
                                    <v-toolbar-title style="margin: 0 auto">
                                        <div style="float: left">
                                            <v-img src="../assets/wenea-ico.png" height="54" contain></v-img>
                                        </div>
                                        <div style="float: left;" class="mt-2 ml-4">
                                            <span class="display-1">OCPI Backoffice</span>
                                        </div>
                                    </v-toolbar-title>
                                </v-toolbar>
                                <v-card-text>
                                    <v-text-field
                                        type="email"
                                        v-model="email"
                                        label="E-mail"
                                        prepend-icon="mdi-account"
                                    ></v-text-field>
                                    <v-text-field
                                        type="password"
                                        v-model="password"
                                        label="Password"
                                        prepend-icon="mdi-lock"
                                    ></v-text-field>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn block type="submit" class="accent" :loading="loading">
                                        <v-icon left>mdi-login</v-icon>Log in
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </form>
                    </v-flex>
                </v-row>
            </v-col>
        </v-container>

        <v-snackbar v-model="snackbar">
            {{ errorMessage }}
            <v-btn color="error" text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
    </v-content>
</template>

<script>
export default {
    data() {
        return {
            email: "",
            password: "",
            errorMessage: "",
            loading: false,
            snackbar: false
        };
    },
    methods: {
        login() {
            let email = this.email;
            let password = this.password;
            this.loading = true;

            this.$store
                .dispatch("login", { email, password })
                .then(() => {
                    this.loading = false;
                    this.$router.push("/");
                })
                .catch(err => {
                    this.loading = false;
                    this.errorMessage = err.response.data;
                    this.snackbar = true;
                });
        }
    }
};
</script>
