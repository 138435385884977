<template>
  <v-dialog v-model="show" persistent max-width="1180px">
    <v-card>
      <v-card-title>
        <span class="headline">Set filters</span>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row class="mt-4" align="center" justify="center">
          <!-- Date range -->
          <v-col cols="12" md="3" v-if="selectedFilterBy == 'year'">
              <v-select 
                  v-model="selectedYear" 
                  :items="years" 
                  label="Year" 
                  prepend-icon="mdi-calendar" 
                  dense flat hide-details 
                  :menu-props="{ contentClass: 'year-selector' }"
              >
                  <template v-slot:item="{ item, on, attrs }">
                      <v-list-item v-on="on" v-bind="attrs" color="accent">
                          <v-list-item-content>
                              <v-list-item-title 
                                  class="text-left pepe" 
                                  :style="`${attrs.inputValue 
                                      ? 'font-size: 1.625rem; line-height: 1.375rem; font-weight: 500;' 
                                      : 'font-size: 1rem; font-weight: 400;'} text-align: center !important;`"
                              >
                                  {{ item }}
                              </v-list-item-title>
                          </v-list-item-content>
                      </v-list-item>
                  </template>
              </v-select>
          </v-col>

          <v-col cols="12" md="3" v-if="selectedFilterBy == 'month'">
              <v-menu 
                  ref="menu" 
                  v-model="showMonthPicker" 
                  :close-on-content-click="false" 
                  transition="scale-transition"
                  offset-y
                  color="accent"
                  min-width="auto"
                  hide-details
                  >
                  <template v-slot:activator="{ on, attrs }">
                      <v-text-field 
                          v-bind="attrs" 
                          v-on="on"
                          v-bind:value="selectedMonth | monthFormat" 
                          label="Month"
                          prepend-icon="mdi-calendar" 
                          readonly
                          dense
                          hide-details
                      >
                      </v-text-field>
                  </template>
                  <v-date-picker 
                      v-model="selectedMonth" 
                      header-color="accent" 
                      type="month" 
                      no-title
                      hide-details 
                      @input="showMonthPicker = false"
                  >
                  </v-date-picker>
              </v-menu>
          </v-col>

          <v-col cols="12" md="3" v-if="selectedFilterBy == 'day'">
              <v-menu 
                  ref="menu" 
                  v-model="showDateFromPicker" 
                  :close-on-content-click="false"
                  transition="scale-transition" 
                  offset-y min-width="auto" 
                  hide-details
              >
                  <template v-slot:activator="{ on, attrs }">
                      <v-text-field 
                          v-bind="attrs"
                          v-on="on"
                          v-model="selectedDateFrom" 
                          label="Date From" 
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          dense flat hide-details
                      >
                      </v-text-field>
                  </template>
                  <v-date-picker v-model="selectedDateFrom" no-title hide-details @input="showDateFromPicker = false"></v-date-picker>
              </v-menu>
          </v-col>

          <v-col cols="12" md="3" v-if="selectedFilterBy == 'day'">
              <v-menu 
                  ref="menu" 
                  v-model="showDateToPicker" 
                  :close-on-content-click="false" 
                  transition="scale-transition"
                  offset-y 
                  min-width="auto" 
                  hide-details
              >
                  <template v-slot:activator="{ on, attrs }">
                      <v-text-field 
                          v-bind="attrs"
                          v-on="on"
                          v-model="selectedDateTo"
                          label="Date To"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          dense flat hide-details
                      ></v-text-field>
                  </template>
                  <v-date-picker v-model="selectedDateTo" no-title hide-details @input="showDateToPicker = false"></v-date-picker>
              </v-menu>
          </v-col>

          <v-btn-toggle v-model="selectedFilterBy" mandatory>
              <v-btn class="min-with-80" value="year" color="accent" text> Year </v-btn>
              <v-btn class="min-with-80" value="month" color="accent" text> Month </v-btn>
              <v-btn class="min-with-80" value="day" color="accent" text> Day </v-btn>
          </v-btn-toggle>

        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="show = false" text>Cancel</v-btn>
        <v-btn @click="applyFilters" depressed color="accent">Filter</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { EventBus } from "@/event-bus"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"

export default {
  components: {
    ProviderSelectorTemplate
  },
  data() {
    return {
      show: false,
      providers: [],
      years: [],
      selectedYear: null,
      selectedMonth: null,
      selectedDateFrom: null,
      selectedDateTo: null,
      selectedFilterBy: null,
      showMonthPicker: false,
      showDateFromPicker: false,
      showDateToPicker: false,
    };
  },
  watch: {
    show(show) {
      if (!show) {

        this.selectedYear = null
        this.selectedMonth = null
        this.selectedDateFrom = null
        this.selectedDateTo = null
        this.selectedFilterBy = null

        EventBus.$emit("dialog-filters:close")
      }
    },
    selectedFilterBy: function (newValue) {
      if (!newValue) {
          this.selectedFilterBy = null
      }

      if (newValue == "day" && this.selectedDateFrom == null) {
          this.selectedMonth = null
          this.selectedYear = null
          this.selectedDateFrom = new Date().toISOString().substr(0, 10)
          this.selectedDateTo = new Date().toISOString().substr(0, 10)
      }

      if (newValue == "month" && this.selectedMonth == null) {
          this.selectedMonth = new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              1
          )
              .toISOString()
              .substr(0, 7)

          this.selectedDateFrom = null
          this.selectedDateTo = null
          this.selectedYear = null
      }

      if (newValue == "year" && this.selectedYear == null) {
          this.selectedMonth = null
          this.selectedDateFrom = null
          this.selectedDateTo = null

          this.selectedYear = Number(new Date().getFullYear())
      }
    },
  },
  mounted() {
    const actualYear = Number(new Date().getFullYear())
    this.years = [...Array(40)].map((_, i) => actualYear - i)

    this.$api
      .get("/providers")
      .then((res) => {
        this.providers = res.data.map((p) => {
          return {
            ...p,
            text: p.role
              ? `${p.country_code}-${p.party_id} ${p.user.company_name} (${p.role})`
              : `${p.country_code}-${p.party_id} ${p.user.company_name}`,
            value: p.id,
          }
        })
      })
      .catch(this.showError)
  },
  created() {
    var vm = this
    EventBus.$on("dialog-filters:open", async function (args) {

      vm.selectedYear = args.selectedYear
      vm.selectedMonth = args.selectedMonth
      vm.selectedDateFrom = args.selectedDateFrom
      vm.selectedDateTo = args.selectedDateTo
      vm.selectedFilterBy = args.selectedFilterBy || "month"

      vm.show = true
    })
  },
  methods: {
    applyFilters() {

      EventBus.$emit("dialog-filters:filters-applied", {
        selectedYear: this.selectedYear,
        selectedMonth: this.selectedMonth,
        selectedDateFrom: this.selectedDateFrom,
        selectedDateTo: this.selectedDateTo,
        selectedFilterBy: this.selectedFilterBy,
      })

      this.show = false

    },
  },
}
</script>

<style scoped>
.min-with-80 {
  min-width: 80px !important;
  height: 40px !important;
}
</style>