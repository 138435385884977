import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import en from 'vuetify/es5/locale/en';
import VDataFooter from 'vuetify/lib/components/VDataIterator/VDataFooter';
import VDataTable from 'vuetify/lib/components/VDataTable'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { en },
        current: 'en',
    },
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#424242',     //oscuro
                secondary: '#fbb800',   //amarillo
                accent: '#8c4799',      //violeta
                //error: '#FF5252',
                //info: '#2196F3',
                //success: '#4CAF50',
                //warning: '#FFC107',
            },
            dark: {
                primary: '#424242',     //oscuro
                secondary: '#fbb800',   //amarillo
                accent: '#8c4799',      //violeta
            }
        }
    },
    
});

VDataFooter.options.props.itemsPerPageOptions.default = () => [25, 50, 100, -1]
VDataTable.$_vuetify_subcomponents.VDataTable.options.props.itemsPerPage.default = 25
