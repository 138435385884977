<template>
    <v-container fluid>
        <div v-if="connection">

            <AppBar
                :breadcrumbs="[{ text: 'Connections', to: '/connections' }, { text: name, icon: 'mdi-vector-polyline' }]" />

            <v-card outlined>
                <v-card-title class="accent-background pl-8">{{name}}</v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="2">
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ connection.id || "-" }}</v-list-item-title>
                                    <v-list-item-subtitle>Id</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ connection.username || "-" }}</v-list-item-title>
                                    <v-list-item-subtitle>Username</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-chip
                                            v-if="connection.type"
                                            color="accent"
                                            outlined
                                            small
                                        >
                                            {{ connection.type == 'provider' ? 'DIRECT' : connection.type.toUpperCase() }}
                                        </v-chip>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Type</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-chip
                                            v-if="connection.ocpi_version"
                                            color="accent"
                                            outlined
                                            small
                                        >
                                        {{ connection.ocpi_version || "-" }}
                                        </v-chip>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>OCPI Version</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <StatusChip v-if="connection.status" :status="connection.status" />
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Status</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        

                        <v-col cols="12" sm="6">
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <div> 
                                        <v-btn small icon @click="() => { copyToClipboard(weneaVersionsEndpoint); showSuccess('URL copied to clipboard') }">
                                            <v-icon small>mdi-content-copy</v-icon>
                                        </v-btn>
                                        {{weneaVersionsEndpoint}}
                                    </div>
                                    <v-list-item-subtitle>Wenea versions endpoint</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ connection.email || "-" }}</v-list-item-title>
                                    <v-list-item-subtitle>e-mail</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ connection.ocpi_url_versions || "-" }}</v-list-item-title>
                                    <v-list-item-subtitle>Versions URL</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ connection.ocpi_url_endpoints || "-" }}</v-list-item-title>
                                    <v-list-item-subtitle>Endpoints URL</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>

                    </v-row>
                </v-card-text>

                <!-- Company -->
                <CompanyPanel v-if="company && company.id" :company="company" />
            
            </v-card>

            <v-toolbar flat dense class="my-1 buttons-toolbar">

                <v-spacer />

                <v-btn 
                    v-if="!company || !company.id" 
                    :to="`/company/create?user_id=${connection.id}`" 
                    text
                    color="accent"
                    class="mr-2 ml-2"
                    outlined>
                        <v-icon left>mdi-plus</v-icon>
                        Create company
                </v-btn>

                <v-btn
                    v-if="connection.type == 'hub' && providers.find(p => p.role.includes('HUB'))"
                    :to="`/provider/${providers.find(p => p.role.includes('HUB')).id}`"
                    text
                    class="mr-2 ml-2"
                    outlined
                    color="accent"
                >
                    <v-icon left>mdi-earth</v-icon>
                    Provider
                </v-btn>

                <v-btn
                    v-if="connection.type == 'provider' && providers.length > 0"
                    :to="`/provider/${providers[0].id}`"
                    text
                    class="mr-2 ml-2"
                    outlined
                    color="accent"
                >
                    <v-icon left>mdi-earth</v-icon>
                    Provider
                </v-btn>

                <v-btn
                    :to="`/connection/${connection.id}/edit`"
                    text
                    class="accent"
                >
                    <v-icon left>mdi-pencil</v-icon>
                    Modify
                </v-btn>

            </v-toolbar>

            <div class="my-2" v-if="connection">
                <div class="float-right">
                    
                </div>
            </div>

            <!-- Operaciones -->
            <h2 class="mt-6 mb-2 ml-2">
                <v-icon left color='accent'>mdi-wrench</v-icon>Operations
            </h2>
            <v-row>

                <v-col cols="12" sm="6" md="4">
                    <v-card outlined class="fill-height">
                        <v-card-text>
                            <p>Obtain the connection's credentials or start registration to create new credentials.</p>
                        </v-card-text>
                        <v-card-actions>
                            <v-row class="px-3 pb-3">
                                <v-btn class="mb-2" @click="fetchCurrentCredentials" text block color='accent'>
                                    <v-icon left>mdi-shield-lock</v-icon>
                                    Get current
                                </v-btn>
                                <v-btn @click="showDialogProviderFetchCredetnials" text block color='accent'>
                                    <v-icon left>mdi-shield-lock</v-icon>
                                    Start registration
                                </v-btn>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="6" md="4" v-if="connection.type == 'hub'">
                    <v-card outlined class="fill-height">
                        <v-card-text>
                            <p>Obtain the list of known ClientInfo objects.</p>
                        </v-card-text>
                        <v-card-actions class="fill-height">
                            <v-btn class="mb-sm-5 mb-lg-4 mb-xl-10" @click="fetchHubClientInfo" text block color='accent'>
                                <v-icon left>mdi-shield-lock</v-icon>
                                Get hub info
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>

            </v-row>

            <v-expansion-panels v-if="connection" class="accent-expansion-panels depressed-expansion-panels mt-4" focusable multiple>
                <!-- Credetnials (user-type = provider) -->
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <div class="title">
                            <v-icon left color='accent'>mdi-shield-lock</v-icon>Credentials
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content> 
                        <div class="pa-4">
                            <h3 class="ml-2">
                                <v-chip color="secondary" class="subtitle-1">Wenea</v-chip>
                                <v-icon color="secondary">mdi-arrow-right</v-icon>
                                <span v-if="connection.company">{{ connection.company.name }}</span>
                                <span v-else>{{ connection.username }}</span>
                            </h3>

                            <v-row>
                                <v-col cols="2">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>OCPI Version</v-list-item-title>
                                            <v-list-item-subtitle>{{ connection.ocpi_version }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="2">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-btn v-if="connection.ocpi_token_a" @click="showDialogToken(connection.ocpi_token_a)" text color='accent'>
                                                    <v-icon left>mdi-key</v-icon>
                                                    Token A
                                                </v-btn>
                                                <span v-else>Token A</span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="2">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-btn v-if="connection.ocpi_token_c" @click="showDialogToken(connection.ocpi_token_c)" text color='accent'>
                                                    <v-icon left>mdi-key</v-icon>
                                                    Token C
                                                </v-btn>
                                                <span v-else>Token C</span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </div>

                        <v-divider />

                        <v-row class="pa-3" align="center">
                            <h3 class="ml-4 mt-4">
                                <span v-if="connection.company">{{ connection.company.name }}</span>
                                <span v-else>{{ connection.username }}</span>
                                <v-icon color="secondary">mdi-arrow-right</v-icon>
                                <v-chip color="secondary" class="subtitle-1">Wenea</v-chip>
                            </h3>
                            <v-spacer />

                            <!-- Show only non revoked tokens -->
                            <v-checkbox
                                label="Show revoked"
                                class="mr-7 mt-3"
                                color="accent"
                                hide-details
                                v-model="showRevoked"
                            />

                            <v-btn
                                class="mr-2 mt-3"
                                color="accent"
                                outlined
                                text
                                small
                                @click="renewToken"
                                >
                                <v-icon class="mr-2" small>mdi-autorenew</v-icon>Renew Token
                            </v-btn>
                        </v-row>

                        <div>
                            <v-data-table 
                                class="accent-table mt-2"
                                :headers="[
                                    { text: 'Created at', value: 'created_at' },
                                    { text: 'Token', value: 'token' },
                                    { text: 'Revoked', value: 'is_revoked', align: 'center' },
                                    { text: '', value: 'action', sortable: false }
                                ]" 
                                :items="filteredTokens" 
                                sort-by="is_revoked"
                                disable-pagination 
                                hide-default-footer 
                                dense
                            >
                                <template v-slot:item.token="{ item }">
                                    <v-btn @click="showDialogToken(item.token)" text color='accent'>
                                        <v-icon left>mdi-key</v-icon>
                                        Show
                                    </v-btn>
                                </template>
                                <template v-slot:item.is_revoked="{ item }">
                                    <v-icon v-if="item.is_revoked" color="error">mdi-close-circle</v-icon>
                                    <v-icon v-else color="success">mdi-check-circle</v-icon>
                                </template>
                                <template v-slot:item.action="{ item }">

                                    <!-- UnRevoke Token -->
                                    <v-tooltip bottom v-if="item.is_revoked">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon 
                                                v-on="on"
                                                v-bind="attrs"
                                                class="mx-2" 
                                                @click="unRevokeToken(item)"
                                            >
                                                mdi-key-plus
                                            </v-icon>
                                        </template>
                                        <span>Set token as unrevoked</span>
                                    </v-tooltip>

                                    <!-- Revoke Token -->
                                    <v-tooltip bottom v-else>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon 
                                                v-on="on"
                                                v-bind="attrs"
                                                class="mx-2" 
                                                @click="revokeToken(item)"
                                            >
                                                mdi-key-remove
                                            </v-icon>
                                        </template>
                                        <span>Set token as revoked</span>
                                    </v-tooltip>
                                    
                                    <!-- Delete Token -->
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon 
                                                v-on="on"
                                                v-bind="attrs"
                                                class="mx-2"
                                                @click="removeToken(item)"
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </template>
                                        <span>Delete Token</span>
                                    </v-tooltip>
                                    
                                </template>
                            </v-data-table>
                        </div>
                        <div v-if="!filteredTokens" class="pa-4 text-center">This connection does not have any defined Token</div>

                    </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Versions -->
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <div class="title">
                            <v-icon left color='accent'>mdi-numeric</v-icon>Versions
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-toolbar flat>
                            <v-spacer></v-spacer>
                            <v-btn 
                                color="accent"
                                outlined
                                text 
                                small
                                @click="fetchVersions" 
                            >
                                Check versions
                                <v-icon right>mdi-arrow-down-bold</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-divider />

                        <v-data-table 
                            class="accent-table"
                            :headers="[
                                { text: 'Version', value: 'version' },
                                { text: 'URL', value: 'url' }
                            ]"
                            :items="versions" 
                            disable-sort
                            disable-pagination
                            hide-default-footer
                            dense
                        ></v-data-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Endpoints -->
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <div class="title">
                            <v-icon left color='accent'>mdi-link-box</v-icon>Endpoints
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-toolbar flat>
                            <v-spacer></v-spacer>
                            <v-btn 
                                color="accent"
                                outlined
                                text 
                                small
                                @click="fetchEndpoints" 
                            >
                                Check endpoints
                                <v-icon right>mdi-arrow-down-bold</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-divider />

                        <v-data-table 
                            class="accent-table"
                            :headers="[
                                { text: 'Module', value: 'identifier' },
                                { text: 'Role', value: 'role' },
                                { text: 'URL', value: 'url' }
                            ]" 
                            :items="endpoints" 
                            disable-sort 
                            disable-pagination 
                            hide-default-footer 
                            dense
                        ></v-data-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- Providers -->
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <div class="title">
                            <v-icon left color='accent'>mdi-earth</v-icon>Providers
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-toolbar flat>
                            <v-spacer></v-spacer>
                            <div>
                                <v-text-field v-model="tableProvidersSearch" clearable hide-details
                                    append-icon="mdi-magnify" placeholder="Search">
                                </v-text-field>
                            </div>
                            <v-btn v-if="connection.type == 'hub'"
                                class="ml-8"
                                @click="checkProviders()" text small :loading="fetchingProviders">
                                Get providers
                                <v-icon right>mdi-arrow-down-bold</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-divider />

                        <v-data-table 
                            class="accent-table"
                            :headers="[
                                { text: 'Provider', value: 'provider' },
                                { text: 'Role', value: 'role' },
                                { text: 'Last updated', value: 'last_updated' }
                            ]" 
                            :search="tableProvidersSearch"
                            :custom-filter="dataTableDeepSearch"
                            :items="providers"
                            disable-pagination
                            hide-default-footer
                            disable-sort dense
                        >
                            <template v-slot:item.provider="{ item }">
                                <ProviderName
                                    :provider="{ id: item.id, country_code: item.country_code , party_id: item.party_id }"
                                />
                            </template>
                        </v-data-table>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

        </div>

        <UserFetchCredentials />
        <TokenDialog />
        <JsonDialog />

    </v-container>
</template>

<script>
import { EventBus } from "@/event-bus"
import AppBar from "@/components/AppBar"
import JsonDialog from "@/components/JsonDialog"
import UserFetchCredentials from "@/components/UserFetchCredentials"
import TokenDialog from "@/components/connection/TokenDialog.vue"
import StatusChip from "@/components/connection/StatusChip"
import ProviderName from "@/components/provider/ProviderName"
import CompanyPanel from "@/components/company/CompanyPanel"

export default {
    name: "providers",
    components: {
        AppBar,
        UserFetchCredentials,
        TokenDialog,
        StatusChip,
        ProviderName,
        CompanyPanel,
        JsonDialog
    },
    data() {
        return {
            connection: null,
            tokens: [],
            filteredTokens: [],
            showRevoked: false,
            loadingProviders: false,
            company: {},
            providers: [],
            tableProvidersSearch: "",
            versions: [],
            endpoints: [],

            fetchingProviders: false,

            weneaVersionsEndpoint: process.env.VUE_APP_WENEA_CREDENTIALS_URL + '/versions'
        }
    },
    mounted() {
        this.loadData()

        EventBus.$on("dialog-user-fetch-credentials:close", (res) => {
            this.loadData()
        })
    },
    computed: {
        name(){
            return this.connection?.company?.name || this.connection?.username
        },
    },
    watch: {
        showRevoked() {
            if(this.showRevoked) {
                this.filteredTokens = this.tokens
            } else {
                this.filteredTokens = this.tokens.filter(t => !t.is_revoked)
            }
        }
    },
    methods: {
        async loadData() {

            let id = this.$router.currentRoute.params.id
            if (!id) { return }

            try {

                let response = await this.$api.get(`/connections/${id}`)
                this.connection = response.data
                this.endpoints = response.data.endpoints
                this.tokens = response.data.tokens
                this.filteredTokens = this.tokens.filter(t => !t.is_revoked)
                this.company = response.data.company
                
                this.showRevoked = false
                this.loadingProviders = true

                this.providers = await this.$api
                    .get(`/connections/${id}/providers`)
                    .then(resp => resp.data)
                    .catch(this.showError)

                this.loadingProviders = false

            } catch (e) {
                this.showError(e)
            }

        },
        showDialogProviderFetchCredetnials() {
            EventBus.$emit("dialog-provider-fetch-credentials:open", this.connection)
        },
        showDialogToken(token) {
            EventBus.$emit("dialog-show-token:open", token)
        },
        async fetchVersions() {
            this.versions = await this.$api
                .get(`user/${this.connection.id}/fetch-versions`)
                .then(resp => resp.data)
                .catch(this.showError)
        },
        async fetchEndpoints() {
            this.endpoints = await this.$api
                .get(`/user/${this.connection.id}/fetch-endpoints`)
                .then(resp => resp.data.endpoints)
                .catch(this.showError)
        },
        async checkProviders() {
            this.fetchingProviders = true
            await this.$api
                .get(`/hub/${this.connection.id}/check-providers`)
                .then(resp => {
                    this.showSuccess('Providers have been updated successfully')
                    this.providers = resp.data
                })
                .catch(this.showError)
            this.fetchingProviders = false
        },
        fetchHubClientInfo(){
            this.$api.get(`/hub/${this.connection.id}/client-info`)
                .then(resp => EventBus.$emit(
                    "dialog-json:open",
                    { title: 'Hub Client Info', json: resp.data, expand: 4, maxWidth: 600 }
                ))
                .catch(this.showError)
        },
        revokeToken(item){
            this.$api
                .patch(`/user/${this.connection.id}/revoke-token/${item.id}`)
                .then(() => this.filteredTokens.map(t => {
                    if(t.id == item.id) t.is_revoked = true
                }))
                .catch(this.showError)
        },
        unRevokeToken(item){
            this.$api
                .patch(`/user/${this.connection.id}/unrevoke-token/${item.id}`)
                .then(() => this.filteredTokens.map(t => {
                    if(t.id == item.id) t.is_revoked = false
                }))
                .catch(this.showError)
        },
        removeToken(item) {
            this.$api
                .delete(`/provider/token/${item.id}`)
                .then(() => this.loadData())
                .catch(this.showError)
        },
        renewToken() {

            const confirmed = window.confirm('When token is renewed, all other tokens are revoked. Do you want to continue?')
            if(!confirmed){ return }

            this.$api.patch(`/user/${this.connection.id}/renew-token`)
                .then(() => {
                    this.showSuccess('Token renewed successfully')
                    this.loadData()
                })
                .catch(this.showError)
        },
        fetchCurrentCredentials() {
            this.$api.get(`user/${this.connection.id}/fetch-credentials`)
                .then(() => this.showSuccess("Query done correctly"))
                .catch(this.showError)
        }
    },
};
</script>

<style scoped>
    >>> .accent-background {
        background-color: var(--v-accent-lighten1) !important;
        color: white;
    }
    >>> .accent-background::before {
        background-color: currentColor;
        opacity: 0.12;
    }
</style>