<template>
    <v-card outlined>
        <v-card-text>
            <p class="text-subtitle-2"><v-chip class="mr-2" color="indigo" outlined small>PUT</v-chip>Location</p>
            <p>Send information of this Location to Provider. Location, evses and connectors that belong to it will be
                sent.</p>
            <v-autocomplete class="mb-4" v-model="providersToSend"
                :items="showAllProviders ? providers : locationGroupProviders" 
                :rules="[v => !!v]" item-text="id"
                :filter="autocompleteDeepSearch"
                item-value="id" 
                label="Send to"
                multiple 
                outlined 
                hide-details
            >
                <template v-slot:selection="{ item }">
                    <v-chip small outlined>
                        <v-icon small left color="black">mdi-earth</v-icon>{{ `${item.country_code}-${item.party_id}` }}
                    </v-chip>
                </template>
                <template v-slot:prepend-item>
                    <v-row class="ma-0 pa-0 px-4" align="center">
                        <v-simple-checkbox 
                            :value="allSelected"
                            :indeterminate="!allSelected && providersToSend.length > 0"
                            :color="providersToSend.length > 0 ? 'accent' : ''" 
                            @input="selectAll"
                        >
                        </v-simple-checkbox>
                        <span>Select All</span>

                        <v-spacer />

                        <span>Show all providers</span>
                        <v-switch v-model="showAllProviders" class="ma-0 pb-2 pl-2" color="accent" hide-details></v-switch>
                    </v-row>
                    <v-divider></v-divider>
                </template>
                <template v-slot:item="{ item, on }">
                    <v-simple-checkbox v-on="on" :value="providersToSend.includes(item.id)" color="accent"></v-simple-checkbox>
                    <v-icon small color="black">mdi-earth</v-icon>
                    <span class="ml-2">{{ `${item.country_code}-${item.party_id}` }}</span>
                    <v-chip v-if="item.role" class="ml-2" small outlined>{{ item.role.replaceAll(',', '/') }}</v-chip>
                </template>
            </v-autocomplete>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="sendLocationToProvider" text block :loading="loading" color="accent">
                <v-icon left>mdi-upload</v-icon>
                PUT Location
            </v-btn>
        </v-card-actions>

        <v-dialog v-model="showReport" max-width="1000">
            <v-card v-if="report">
                <v-card-title class="accent white--text">
                    <span class="headline">
                        <v-icon class="white--text" left>mdi-file-document-alert-outline</v-icon> 
                        <span>Report</span>
                    </span>
                </v-card-title>
                <v-card-text class="py-4 pb-0">
                    <v-data-table
                        class="rounded-table"
                        :headers="[
                            {text: 'Provider', value: 'provider_id'},
                            {text: 'Success', value: 'success', align: 'center'},
                            {text: 'Error', value: 'error_message'}
                        ]"
                        :items="report"
                        sort-by="success"
                        sort-desc
                        dense
                    >
                    <template v-slot:item.provider_id="{ item }">
                        <v-row :set="provider = providers.find(p => p.id == item.provider_id) || locationGroupProviders.find(p => p.id == item.provider_id)" class="ma-0">
                            <template v-if="provider">
                                <v-icon small color="black">mdi-earth</v-icon>
                                <span class="ml-2">{{ `${provider.country_code}-${provider.party_id}` }}</span>
                            </template>
                            <template v-else>{{ item.provider_id }}</template>
                        </v-row>
                    </template>
                    <template v-slot:item.success="{ item }">
                        <v-icon v-if="item.success" color="success">mdi-check-circle</v-icon>
                        <v-icon v-else color="error">mdi-close-circle</v-icon>
                    </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="showReport = false" text>
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
export default {
    name: "send-location-tool",
    props: {
        locationId: {
            type: Number,
            required: true
        },
        locationGroupProviders: {
            type: Array,
            required: true
        },
        providers: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            loading: false,
            showAllProviders: false,
            providersToSend: [],
            showReport: false,
            report: null
        }
    },
    computed:{
        allSelected() {
            return this.showAllProviders 
                ? this.providersToSend.length == this.providers.length 
                : this.providersToSend.length == this.locationGroupProviders.length
        }
    },
    watch:{
        showReport(show) {
            if (!show) { this.report = null }
        }
    },
    methods: {
        selectAll(value) {
            if (value) {
                if (this.showAllProviders) {
                    this.providersToSend = this.providers.map(p => p.id)
                } else {
                    this.providersToSend = this.locationGroupProviders.map(p => p.id)
                }
            } else {
                this.providersToSend = []
            }
        },
        sendLocationToProvider() {
            if (!this.providersToSend.length) { this.showError('Provider must be selected to send Location'); return }
            
            const data = { providerIds: this.providersToSend }
            
            this.loading = true
            this.$api.post(`location/${this.locationId}/send-to/providers`, data)
                .then((resp) => this.showCustomAlert('success', 'Location has been sent successfully.', { clickFn: () =>  this.openReport(resp.data), text:'View Report', icon:'mdi-arrow-top-right' }))
                .catch(({ response }) => this.showCustomAlert('error', 'There was an error sending location.', { clickFn: () =>  this.openReport(response.data), text:'View Report', icon:'mdi-arrow-top-right' }))
                .then(() => { this.loading = false })
        },
        openReport(report){
            this.report = report
            this.showReport = true
        }
    }
}
</script>

<style scoped>
    /* Fix vuetify visual bug */
    >>> .v-list-item--highlighted::before {
        opacity: 0 !important;
    }
    /* Fix vuetify visual bug */
    >>> .v-list-item--highlighted.v-list-item--active::before {
        opacity: 0.12 !important;
    }
</style>