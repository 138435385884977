<template>
    <v-data-table
        :headers="[
            { value: 'click', sortable:false, cellClass: 'td-linkto' },
            { text: 'Id.', value: 'id' },
            { text: 'Name', value: 'name' },
            { text: 'Provider', value: 'provider' },
            { text: 'Publish', value: 'publish' },
            { text: 'Charge points', value: 'chargepoints', width: '600px' },
            { text: 'Deleted', value: 'deleted_at' },
            { text: 'Map', value: 'action', sortable: false }
        ]"
        :items="locations"
        :loading="tableLoading"
        :search="selectedSearch"
        :items-per-page="options.itemsPerPage"
        :server-items-length="pagination.serverItemsLength"
        :footer-props="{ 'items-per-page-options': pagination.itemsPerPageOptions }"
        @update:options="this.updateTableOptions"
        disable-sort
        dense
        class="accent-table rounded-table"
    >   

        <template v-slot:item.click="{ item }">
            <v-btn plain small :to="`/location/${item.id}`">
                <v-icon small>mdi-arrow-top-right</v-icon>
            </v-btn>
        </template>

        <template v-slot:item.name="{ item }">
            <LocationName
                :location="item"
                extra="no-arrow"
                type="table"
            />
        </template>

        <template v-slot:item.provider="{ item }">
            <ProviderName
                :provider="item.provider"
            />
        </template>

        <template v-slot:item.action="{ item }">
            <v-btn @click.stop="openMaps(item.coordinates)" small icon><v-icon small>mdi-map</v-icon></v-btn>
        </template>
        
        <template v-slot:item.deleted_at="{ item }">
            {{ item.deleted_at | dateFullFormat }}
            <v-chip v-if="item.deleted_at" class="ml-4" outlined color="error" small>REMOVED</v-chip>
        </template> 

        <template v-slot:item.publish="{ item }" >
            <v-icon 
                :color="item.publish ? 'success' : 'error'"
            >
            {{ item.publish ? 'mdi-check-circle' : 'mdi-close-circle' }}
            </v-icon>
        </template>

        <template v-slot:item.chargepoints="{ item }">
            <ChargepointName
                v-for="(chargepoint) in item.chargepoints"
                :key="`${chargepoint.id}`"
                class="ml-2"
                :chargepoint="{ id: chargepoint.id, name: chargepoint.reference_name }"
                type="table"
            />
        </template>

    </v-data-table>

</template>

<script>
    import ProviderName from "@/components/provider/ProviderName"
    import LocationName from "@/components/location/LocationName"
    import ChargepointName from "@/components/chargepoint/ChargepointName"

    export default {
    name: "locationsTable",
    components: {
    ProviderName,
    LocationName,
    ChargepointName,
    },
    props: ["locations", "tableLoading", "options", "pagination", "selectedSearch", "updateTableOptions"],
    
}

</script>