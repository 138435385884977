<template>
    <v-card 
        class="elevation-0"
        style="height: 20px;"
        :loading="loading"
        v-click-outside="(e) => onClickOutside(e)"
    >
        <template slot="progress">
            <v-progress-linear color="accent" indeterminate></v-progress-linear>
        </template>

        <v-row dense>

            <v-btn
                icon
                @click="show = !show"
                class="d-none d-md-block"
            >
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
            
            <v-btn
                icon
                to="/searcher"
                class="d-block d-md-none"
            >
                <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <transition name="slide-y-transition">
                <v-row dense v-if="show">  
                    <v-col md="6">
                        <v-text-field
                            dense
                            v-model="search_str"
                            clearable
                            placeholder="ID / OCPI ID Search"
                            color="accent"
                            hide-details
                            autofocus
                            @click="onClick"
                            @keyup.enter="search"
                        ></v-text-field>
                    </v-col>

                    <v-col md="6"  class="d-inline">
                        <v-btn
                            color="accent"
                            elevation="0"
                            class="ml-1 rounded-r-0"
                            small
                            style="min-width: 135px;"
                            @click.stop="search"
                        >
                            Search {{ search_model }}
                        </v-btn>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                color="accent rounded-l-0 v-btn-groove"
                                elevation="0"
                                x-small
                                v-bind="attrs"
                                v-on="on"
                                >
                                    <v-icon small>mdi-arrow-down-drop-circle-outline</v-icon>
                                </v-btn>
                            </template>
                            <v-list class="non-v-click-outside">
                                <v-list-item-group
                                v-model="search_model"
                                color="primary"
                                >
                                    <v-list-item
                                        v-for="(item, index) in search_model_options"
                                        class="non-v-click-outside"
                                        :key="index"
                                        :value="item"
                                    >
                                    <v-list-item-title class="non-v-click-outside">{{ item }}</v-list-item-title>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>
            </transition>
        </v-row>

        <v-row class="elevation-20 mt-0 d-block" style="position: absolute; right: 0;">

            <v-col class="py-0 px-0" v-if="sessions.length" >
                <v-data-table
                    :items="sessions"
                    :headers="[
                        { value: 'click', sortable: false, cellClass: 'td-linkto' },
                        { text: 'Id.', value: 'id' },
                        { text: 'Sender Id.', value: 'sender_provider_id' },
                        { text: 'Receiver Id.', value: 'receiver_provider_id' },
                        { text: 'OCPI Id.', value: 'ocpi_id' },
                        { text: 'Created at', value: 'created_at' },
                    ]"
                    disable-sort
                    hide-default-footer
                    dense
                    class="accent-table"
                >
                    <template v-slot:item.click="{ item }">
                        <v-btn plain small :to="`/session/${item.id}`">
                            <v-icon small>mdi-arrow-top-right</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>

            <v-col class="py-0 px-0" v-if="cdrs.length" >
                <v-data-table
                    :items="cdrs"
                    :headers="[
                        { value: 'click', sortable: false, cellClass: 'td-linkto' },
                        { text: 'Id.', value: 'id' },
                        { text: 'Session Id.', value: 'charging_session_id' },
                        { text: 'OCPI Id.', value: 'ocpi_id' },
                        { text: 'Total Cost', value: 'total_cost.incl_vat' },
                    ]"
                    disable-sort
                    hide-default-footer
                    dense
                    class="accent-table"
                >
                    <template v-slot:item.click="{ item }">
                        <v-btn plain small :to="`/session/${item.charging_session_id}`">
                            <v-icon small>mdi-arrow-top-right</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>

            <v-col  v-if="no_data && search_str" class="accent white--text no-data ">
                <span>No data found</span>
            </v-col>
        </v-row>

    </v-card>
</template>
<script>
import _ from "lodash"

export default {
    data() {
        return {
            show: false,
            loading: false,
            no_data: false,
            search_str: null,
            search_model: 'Sessions',
            search_model_options: ['Sessions','Cdrs'],
            sessions: [],
            cdrs: []
        };
    },
    watch: {
        search_str: function (newValue) {
            if (!newValue) {
                this.sessions = []
                this.cdrs = []
                this.no_data = false
                return
            }
        },
        search_model: function (newValue, oldValue) {
            if (typeof newValue === 'undefined') this.search_model = oldValue

            if (!this.search_str) {
                return
            }
            this.search()
        },
        show: function (newValue) {
            if (!newValue) {
                this.sessions = []
                this.cdrs = []
                this.no_data = false
            }
        }
    },
    methods: {
        async search() {

            if(!this.search_str) {
                return
            }
            
            this.no_data = false
            this.loading = true

            const data = await this.$api
                .get(`tools/search/${this.search_str}/${this.search_model}`)
                .then((resp) => resp.data)
                .catch(this.showError)

            this.loading = false

            if (data) {
                this.sessions = data.sessions || []
                this.cdrs = data.cdrs || []
                this.no_data = false
            } 
            
            if (!this.sessions.length && !this.cdrs.length) {
                this.no_data = true
            }
        },
        onClickOutside(event) {
            if(event.srcElement.className.includes("non-v-click-outside")) return

            this.sessions = []
            this.cdrs = []
            this.no_data = false
            this.show = false
        },
        onClick() {
            if (this.no_data) {
                this.search()
            }
        },
    },
};
</script>

<style scoped>
    .v-toolbar__content .v-btn.v-btn--icon.v-size--default {
        height: 30px;
    }

    >>> .v-btn {
        height: 36px !important;
        top: -5px;
        width: 36px !important;
    }

    .v-btn-groove {
        border-left: groove;
    }

    .no-data {
        text-align: center;
        width: 300px;
    }
</style>