import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"headers":[
        { value: 'click', sortable:false, cellClass: 'td-linkto' },
        { text: 'Id.', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Provider', value: 'provider' },
        { text: 'Publish', value: 'publish' },
        { text: 'Charge points', value: 'chargepoints', width: '600px' },
        { text: 'Deleted', value: 'deleted_at' },
        { text: 'Map', value: 'action', sortable: false }
    ],"items":_vm.locations,"loading":_vm.tableLoading,"search":_vm.selectedSearch,"items-per-page":_vm.options.itemsPerPage,"server-items-length":_vm.pagination.serverItemsLength,"footer-props":{ 'items-per-page-options': _vm.pagination.itemsPerPageOptions },"disable-sort":"","dense":""},on:{"update:options":this.updateTableOptions},scopedSlots:_vm._u([{key:"item.click",fn:function(ref){
    var item = ref.item;
return [_c(VBtn,{attrs:{"plain":"","small":"","to":("/location/" + (item.id))}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1)]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('LocationName',{attrs:{"location":item,"extra":"no-arrow","type":"table"}})]}},{key:"item.provider",fn:function(ref){
    var item = ref.item;
return [_c('ProviderName',{attrs:{"provider":item.provider}})]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c(VBtn,{attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openMaps(item.coordinates)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-map")])],1)]}},{key:"item.deleted_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFullFormat")(item.deleted_at))+" "),(item.deleted_at)?_c(VChip,{staticClass:"ml-4",attrs:{"outlined":"","color":"error","small":""}},[_vm._v("REMOVED")]):_vm._e()]}},{key:"item.publish",fn:function(ref){
    var item = ref.item;
return [_c(VIcon,{attrs:{"color":item.publish ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.publish ? 'mdi-check-circle' : 'mdi-close-circle')+" ")])]}},{key:"item.chargepoints",fn:function(ref){
    var item = ref.item;
return _vm._l((item.chargepoints),function(chargepoint){return _c('ChargepointName',{key:("" + (chargepoint.id)),staticClass:"ml-2",attrs:{"chargepoint":{ id: chargepoint.id, name: chargepoint.reference_name },"type":"table"}})})}}])})}
var staticRenderFns = []

export { render, staticRenderFns }