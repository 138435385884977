import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"providers-selector custom-scollbar",on:{"scroll":function (e) { return _vm.scrollIsOnTop = e.target.scrollTop == 0; }}},[_c('div',{staticClass:"selector-search-container"},[_c(VBtn,{staticClass:"selector-search-button",class:{ 'display': _vm.scrollIsOnTop || _vm.showSearchBar },attrs:{"text":"","icon":"","color":"accent"},on:{"click":function () { _vm.showSearchBar = !_vm.showSearchBar; _vm.search = null }}},[_c(VIcon,[_vm._v("mdi-magnify")])],1),_c(VTextField,{ref:"selectorSearch",staticClass:"selector-search ma-0 pt-0",class:{ 'display': _vm.showSearchBar },attrs:{"clearable":"","color":"accent","placeholder":"Search","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{class:("selector-search-spacer " + (_vm.showSearchBar ? 'display' : ''))}),_c(VDataTable,{class:{ 
            'all-selected': _vm.selectedProviders.length == _vm.items.length,
            'hide-header': _vm.hideHeader
        },attrs:{"items":_vm.items,"headers":[
            { value: 'data-table-select' },
            { text: 'Select all external providers', value: 'providers' } ],"dense":"","sort-by":"providers","sort-desc":"","disable-sort":"","items-per-page":-1,"search":_vm.search,"custom-filter":function (value, search, item) { return _vm.customFilter(value, search, item); },"hide-default-footer":"","show-select":""},scopedSlots:_vm._u([{key:"item.providers",fn:function(ref){
        var item = ref.item;
return [_c('ProviderSelectorTemplate',{attrs:{"provider":item}})]}}]),model:{value:(_vm.selectedProviders),callback:function ($$v) {_vm.selectedProviders=$$v},expression:"selectedProviders"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }