<template>
    <div class="d-inline text-no-wrap" v-if="provider">
        <v-icon small color="black" class="v-left-icon" v-if="extra !== 'no-icon'">mdi-earth</v-icon>
        <span class="text-uppercase" :style="getClass()" v-if="extra === 'applicable_provider'">
            {{ provider.country_code }}-{{ provider.party_id }}
        </span>
        <span class="text-uppercase" :style="getClass()" v-else-if="extra === 'applicable_user'">
            {{ provider.username }}
        </span>
        <template v-else>
          <span v-if="provider.company">
              {{ provider.company.name }}
          </span>
          <span v-else-if="provider.user && provider.user.company">
              {{ provider.user.company.name }}
          </span>
          <span v-else-if="provider.user">
              {{ provider.user.username }}
          </span>
          <span :style="getClass()">
              {{ country_party }}
          </span>
        </template>
        <v-btn plain small :to="getURL()" v-if="extra !== 'no-arrow'">
            <v-icon small>mdi-arrow-top-right</v-icon>
        </v-btn>
    </div>
</template>
  
<script>
    export default {
    name: "providerName",
    props: ["provider", "extra", "type"],
    computed: {
      country_party(){
        if(this.provider?.party_id) { 
            return this.provider?.country_code + "-" + this.provider?.party_id
        }else{
            return this.provider?.country_code
        }
      }
    },
    methods: {
      getURL() {
        switch (this.extra) {
          case "applicable_user":
            return `/connection/${this.provider.applicable_user}`;

          default:
            return `/provider/${this.provider.id}`;
        }
      },
      getClass() {
            switch(this.type){
                case "title":
                    return "font-size: 1rem; font-weight: 500;";
                default:
                    return "font-size: 0.85rem; font-weight: 500;";
            }
        },
    }
  }
</script>

<style scoped>
  >>> .v-btn:not(.v-btn--round).v-size--small {
    height: 28px;
    min-width: 0px !important;
    padding: 0 0 4px 0px !important;
  }

  >>> .v-left-icon {
    height: 10px;
    min-width: 0px !important;
    padding: 0 0 2px 0px !important;
  }

</style>