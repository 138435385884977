<template>
        <div class="d-inline">
            <v-icon small color="black" class="v-left-icon" v-if="extra !== 'no-icon'">mdi-map-marker</v-icon>
            <span :style="getClass()"> {{ location.name }} </span>
            <v-btn plain small :to="`/location/${location.id}`" v-if="extra !== 'no-arrow'">
                <v-icon small>mdi-arrow-top-right</v-icon>
            </v-btn>
        </div>
   
</template>
  
<script>
    export default {
    name: "locationName",
    props: ["location", "extra", "type"],

    methods: {
        getClass() {
            switch(this.type){
                case "table":
                    return "font-size: 0.85rem; font-weight: 500;";
                default:
                    return "font-size: 1rem; font-weight: 500;";
            }
        },
    },
  }

</script>

<style scoped>
  >>> .v-btn:not(.v-btn--round).v-size--small {
    height: 28px;
    min-width: 0px !important;
    padding: 0 0 4px 0px !important;
  }

  >>> .v-left-icon {
    height: 10px;
    min-width: 0px !important;
    padding: 0 0 2px 0px !important;
  }

</style>