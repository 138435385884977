import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"1180px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"accent white--text"},[_c('span',{staticClass:"headline"},[_vm._v("Sending locations to provider")])]),_c(VCardText,{staticClass:"pa-4"},[(_vm.loading)?_c(VLayout,{attrs:{"align-center":"","justify-center":"","column":"","fill-height":""}},[_c(VFlex,{attrs:{"row":"","align-center":""}},[_c(VProgressCircular,{staticClass:"my-4",attrs:{"indeterminate":"","color":"accent","size":70,"width":7}})],1),_c(VFlex,{attrs:{"row":"","align-center":""}},[_c('p',[_vm._v("Sending locations to provider...")])])],1):_c('div',[_c(VDataTable,{attrs:{"headers":[
                        { text: 'Id.', value: 'id'},
                        { text: 'OCPI Id.', value: 'ocpi_id' },
                        { text: 'Sent successfully', value: 'success' },
                        { text: 'Message', value: 'message' } ],"items":_vm.sentLocations,"group-by":"success","dense":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
                    var group = ref.group;
                    var headers = ref.headers;
                    var toggle = ref.toggle;
                    var isOpen = ref.isOpen;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(VBtn,{ref:group,attrs:{"icon":""},on:{"click":toggle}},[(isOpen)?_c(VIcon,[_vm._v("mdi-minus")]):_c(VIcon,[_vm._v("mdi-plus")])],1),_c('span',{staticClass:"mx-5 font-weight-bold"},[_vm._v(_vm._s(group ? 'Locations sent successfully' : 'Locations NOT sent successfully'))])],1)]}}])})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"depressed":""},on:{"click":function($event){_vm.show = false}}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }