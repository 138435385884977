<template>
  <div>
    <v-card
      outlined
    >
      <v-card-title class="py-2">Import locations from provider</v-card-title>
      <v-card-text>
        <v-form 
          ref="form"
          v-model="valid"
        >
          <v-row>
              <v-col cols="12" md="2" xl="2">
                  <v-menu
                    ref="menu"
                    v-model="showDateFromPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                      <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="selectedDateFrom"
                              label="Date from"
                              prepend-icon="mdi-calendar"
                              readonly
                              clearable
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="selectedDateFrom"
                          @input="showDateFromPicker = false"
                          no-title
                      ></v-date-picker>
                  </v-menu>
              </v-col>
              <v-col cols="12" md="2" xl="2">
                  <v-menu
                      ref="menu"
                      v-model="showDateToPicker"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                      <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="selectedDateTo"
                              label="Date to"
                              prepend-icon="mdi-calendar"
                              readonly
                              clearable
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="selectedDateTo"
                          @input="showDateToPicker = false"
                          no-title
                      ></v-date-picker>
                  </v-menu>
              </v-col>
              <v-col cols="12" md="2" xl="2">
                <v-text-field 
                  v-model="selectedOffset"
                  :rules="[rules.isANumber]"
                  clearable
                  label="Offset"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" xl="2">
                <v-text-field 
                  v-model="selectedLimit"
                  :rules="[rules.isANumber]"
                  clearable
                  label="Limit"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" xl="2">
                <v-text-field 
                  v-model="selectedMaxPages"
                  :rules="[rules.isANumber]"
                  clearable
                  label="Max pages"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" xl="2">
                <v-text-field 
                  v-model="ocpiToCountryCode"
                  :rules="[rules.length2]"
                  clearable
                  label="OCPI to country code"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" xl="2">
                <v-text-field 
                  v-model="ocpiToPartyId"
                  :rules="[rules.length3]"
                  clearable
                  label="OCPI to party id"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="1" xl="2">
                  <v-btn
                      class="ml-4 mt-3 mb-4"
                      depressed
                      color="accent"
                      :loading="loading"
                      :disabled="!valid || loading"
                      @click="updateLocations"
                  >
                    Import<v-icon right>mdi-table-arrow-up</v-icon>
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
              </v-col>
              <v-col cols="12" md="1" xl="2">
                <v-btn 
                    class="ml-4 mt-3 mb-4" 
                    text @click="showReport = !showReport"
                >
                    Last report <v-icon right>{{ showReport ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <v-row>
          <transition name="slide-y-transition">
            <div v-if="showReport" class="ml-4 mb-2" style="">
              <v-btn text icon @click="showReportDialog"><v-icon>mdi-magnify</v-icon></v-btn>
              <v-chip class="ml-2" outlined color="blue">Updated {{ locationCollectionReport.updated }}</v-chip>
              <v-chip class="ml-2" outlined color="green">Created {{ locationCollectionReport.created }}</v-chip>
              <v-chip class="ml-2" outlined color="red">Errors {{ locationCollectionReport.errors }}</v-chip>
              <v-chip class="ml-2" outlined><v-icon left color="grey">mdi-calendar</v-icon> {{ locationCollectionReport.created_at | dateFullFormat }}</v-chip>
              <v-chip class="ml-2" outlined :color="locationCollectionReport.success ? 'green' : 'red'">
                Finished <v-icon right :color="locationCollectionReport.success ? 'green' : 'red'">{{ locationCollectionReport.success ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
              </v-chip>
            </div>
          </transition>
        </v-row>

      </v-card-text>
    </v-card>  

    <JsonDialog />
  </div>
</template>
<script>
import { EventBus } from "@/event-bus"
import Bws from "@/plugins/BackofficeWebsocket.js"
import JsonDialog from "@/components/JsonDialog"

export default {
  name: "import-locations-from-provider",
  props: ["providerId"],
  components: {
    JsonDialog
  },
  data() {
    return {
        valid: true,
        loading: false,
        selectedDateFrom: new Date().toISOString().substr(0, 10),
        showDateFromPicker: false,
        selectedDateTo: null,
        showDateToPicker: false,
        selectedOffset: null,
        selectedLimit: 10,
        selectedMaxPages: null,
        ocpiToCountryCode: null,
        ocpiToPartyId: null,
        showReport: false,
        locationCollectionReport: null,
        rules: {
          isANumber: v => (!v || !isNaN(v)) || 'Must be a number',
          length2: v => (!v || v.length === 2) || '2 characters are required',
          length3: v => (!v || v.length === 3) || '3 characters are required',
        }
    };
  },
  mounted() {
    this.getLastLocationCollectionReport()

    // Nos suscribimos a Redis para nuevos reportes
    this.reportsChannel = Bws.getSubscription(`location-import:location_collection:provider-${this.providerId}`)
    
    this.reportsChannel.on('message', (data) => {
      this.locationCollectionReport = data
      this.loading = false
      this.showReport = true

      if (data.errorMessage) {
        return this.showError(data.errorMessage)
      }

      this.showSuccess('A new report of a location import process has been received.')
      this.$emit('data-imported')
    })
  },
  methods: {
    getLastLocationCollectionReport(){
      this.$api.get(`/provider/${this.providerId}/reports/location_collection`)
        .then(res => this.locationCollectionReport = res.data)
        .catch(this.showError)
    },
    updateLocations() {
        if (!this.providerId || !this.$refs.form.validate()) { return }

        let queryParams = {
          provider_id: this.providerId,
          offset: this.selectedOffset ? this.selectedOffset : 0,
          limit: this.selectedLimit && this.selectedLimit > 0 ? this.selectedLimit : 10
        }

        if (this.selectedDateFrom) { queryParams.date_from = new Date(this.selectedDateFrom).toISOString() }
        if (this.selectedDateTo) { queryParams.date_to = new Date(this.selectedDateTo).toISOString() }
        if (this.selectedMaxPages) { queryParams.maxPages = this.selectedMaxPages }
        if (this.ocpiToCountryCode) { queryParams['OCPI-to-country-code'] = this.ocpiToCountryCode }
        if (this.ocpiToPartyId) { queryParams['OCPI-to-party-id'] = this.ocpiToPartyId }

        this.loading = true
        this.reportsChannel.emit('import', queryParams)
    },
    showReportDialog(){
      EventBus.$emit(
          "dialog-json:open",
          { title: 'Location collection report', json: this.locationCollectionReport }
      )
    }
  }
};
</script>