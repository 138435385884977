import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"headers":[
          {value: 'action', sortable:false, cellClass: 'td-linkto' },
          {text: 'Id.', value: 'id' },
          {text: 'Provider', value: 'provider'}
      ],"items":_vm.providersWithoutTariffs,"loading":_vm.loading,"sort-by":"id","dense":"","no-data-text":"No providers found without assigned tariffs"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c(VBtn,{attrs:{"plain":"","small":"","to":("/providers/" + (item.id) + "/connectors-without-tariffs")}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1)]}},{key:"item.provider",fn:function(ref){
      var item = ref.item;
return [_c('ProviderName',{attrs:{"provider":{ id: item.id, country_code: item.provider_name.split('-')[0] , party_id: item.provider_name.split('-')[1] }}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }