import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"outlined":""}},[_c(VCardText,[_c('div',{staticClass:"pa-2"},[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.send.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VSelect,{staticClass:"method-select",attrs:{"items":_vm.methods,"rules":[ function (v) { return !!v; } ],"single-line":"","label":"METHOD","outlined":"","hide-details":"","dense":""},model:{value:(_vm.method),callback:function ($$v) {_vm.method=$$v},expression:"method"}}),_c(VCombobox,{staticClass:"url-input",attrs:{"loading":_vm.loading,"items":_vm.endpoints,"rules":[ function (v) { return !!v; } ],"single-line":"","label":"Enter request URL","return-object":"","outlined":"","hide-details":"","append-icon":null,"dense":""},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}}),_c(VBtn,{staticClass:"ml-2",attrs:{"elevation":"0","color":"accent","height":"40","type":"submit"}},[_vm._v("Send")])],1),_c(VRow,{staticClass:"mt-8"},[_c(VTextarea,{attrs:{"rules":[ _vm.validateBody ],"label":"Body","rows":"2","auto-grow":"","outlined":""},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}})],1)],1),_c(VDivider),_c(VRow,{staticClass:"mt-6"},[(_vm.response)?_c('div',{staticClass:"response-content pa-4"},[_c(VRow,[_c('json-viewer',{staticClass:"pa-2",staticStyle:{"width":"100%"},attrs:{"value":_vm.response,"copyable":{
                            copyText: 'Copy',
                            copiedText: 'Copied!',
                            timeout: 2000,
                            },"expand-depth":3}})],1)],1):_c('div',{staticClass:"response-content px-2 py-4"},[_c('span',{staticStyle:{"font-size":"16px","font-weight":"400","height":"20px","line-height":"20px"}},[_vm._v("Response")])])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }