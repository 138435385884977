<template>
    <v-card
      outlined
    >
      <v-card-title class="py-2">Import Tariffs from provider</v-card-title>
      <v-card-text>
        <v-form 
          ref="form"
          v-model="valid"
        >
          <v-row>
              <v-col cols="12" md="2" xl="2">
                  <v-menu
                    ref="menu"
                    v-model="showDateFromPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                      <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="selectedDateFrom"
                              label="Date from"
                              prepend-icon="mdi-calendar"
                              :rules="[v => !!v || 'Date from is required']"
                              readonly
                              clearable
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="selectedDateFrom"
                          @input="showDateFromPicker = false"
                          no-title
                      ></v-date-picker>
                  </v-menu>
              </v-col>
              <v-col cols="12" md="2" xl="2">
                  <v-menu
                      ref="menu"
                      v-model="showDateToPicker"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                      <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="selectedDateTo"
                              label="Date to"
                              prepend-icon="mdi-calendar"
                              readonly
                              clearable
                              v-bind="attrs"
                              v-on="on"
                          ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="selectedDateTo"
                          @input="showDateToPicker = false"
                          no-title
                      ></v-date-picker>
                  </v-menu>
              </v-col>
              <v-col cols="12" md="2" xl="2">
                <v-text-field 
                  v-model="pagination"
                  :rules="[rules.isANumber]"
                  clearable
                  placeholder="Pagination"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" xl="2">
                  <v-btn
                      class="mt-3"
                      depressed
                      color="accent"
                      :loading="loading"
                      :disabled="!valid || loading"
                      @click="updateTariffs"
                  >
                    Import<v-icon right>mdi-table-arrow-up</v-icon>
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
              </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>  
</template>
<script>
export default {
  name: "import-tariffs-from-provider",
  props: ["providerId"],
  data() {
    return {
        valid: true,
        loading: false,
        selectedDateFrom: new Date().toISOString().substr(0, 10),
        showDateFromPicker: false,
        selectedDateTo: null,
        showDateToPicker: false,
        pagination: null,
        rules: {
          isANumber: v => (!v || !isNaN(v)) || 'Must be a number'
        }
    };
  },
  methods: {
    updateTariffs() {
        if (!this.providerId || !this.$refs.form.validate()) { return }

        let queryParams = '?'
        if (this.selectedDateFrom) { queryParams += `&date_from=${new Date(this.selectedDateFrom).toISOString()}`}
        if (this.selectedDateTo) { queryParams += `&date_to=${new Date(this.selectedDateTo).toISOString()}`}
        if (this.pagination) { queryParams += `&limit=${this.pagination}`}

        this.loading = true
        this.$api
            .get(`provider/${this.providerId}/tariffs/fetch${queryParams}`)
            .then(() => {
              this.showSuccess('Tariffs imported successfully')
              this.$emit('data-imported')
            })
            .catch(this.showError)
            .finally(() => this.loading = false)
    }
  }
};
</script>