import Vue from 'vue'
import api from './axios.instance'

Vue.config.productionTip = false
Vue.prototype.$api = api

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// https://github.com/adonisjs/adonis-websocket-client
// https://github.com/reg2005/adonis-vue-websocket
import Ws from '@adonisjs/websocket-client'
import WsPlugin from 'adonis-vue-websocket'
Vue.use(WsPlugin, { adonisWS: Ws })

import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)

// https://www.npmjs.com/package/vue-clipboard2
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// https://www.highcharts.com/blog/tutorials/highcharts-vue-wrapper/
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
Highcharts.setOptions({
    credits: false,
    title: false,
    chart: {
        backgroundColor: 'transparent'
    },
    global: {
        useUTC: false
    }
})
Vue.use(HighchartsVue, HighchartsVue)

import mixins from './plugins/mixins'
import './assets/css/global-styles.css'

Vue.config.productionTip = false

Vue.mixin(mixins)

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
    async created() {

        //Si ya tenemos token, iniciamos sesión y hacemos llamada para obtener los datos del usuario
        if (localStorage.getItem('token')) {
            api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

            let user = await api.get('/user/info')
                .then(resp => resp.data )
                .catch(console.error)

            if( user ){
                this.$store.commit('auth_success', { token: localStorage.getItem('token'), user })
            } else {
                console.log("Can not fetch GET /user/info")
            }

        }

    }
}).$mount('#app')
