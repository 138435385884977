import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"form"},[_c(VRow,{staticClass:"ml-3 mt-6"},[_c(VCol,{attrs:{"cols":"12","lg":"3","xl":"2"}},[_c(VSelect,{staticClass:"pr-4",attrs:{"items":_vm.applicableOptions,"clearable":"","dense":"","label":"Applicable to"},on:{"change":function () { _vm.value.applicable_providers = null; _vm.value.applicable_user = null }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"small":"","color":"accent","outlined":""}},[_vm._v(_vm._s(item.toUpperCase()))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"small":"","color":"accent","outlined":""}},[_vm._v(_vm._s(item.toUpperCase()))])]}}]),model:{value:(_vm.selectedApplicableOptions),callback:function ($$v) {_vm.selectedApplicableOptions=$$v},expression:"selectedApplicableOptions"}})],1),(_vm.selectedApplicableOptions && _vm.selectedApplicableOptions != 'default')?_c(VCol,{attrs:{"cols":"12","lg":"5","xl":"3"}},[(_vm.selectedApplicableOptions == 'provider')?_c(VAutocomplete,{staticClass:"pr-4",attrs:{"items":_vm.externalProviders,"label":"Applicable providers","rules":[function (v) { return (!!v && !!v.length) || 'Applicable provider is required'; }],"multiple":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"small":"","color":"accent","outlined":""}},[_vm._v(_vm._s(item.country_code)+"-"+_vm._s(item.party_id))])]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('ProviderSelectorTemplate',_vm._g(_vm._b({attrs:{"multiple":"","provider":item}},'ProviderSelectorTemplate',attrs,false),on))]}}],null,false,1350061774),model:{value:(_vm.value.applicable_providers),callback:function ($$v) {_vm.$set(_vm.value, "applicable_providers", $$v)},expression:"value.applicable_providers"}}):_vm._e(),(_vm.selectedApplicableOptions == 'hub')?_c(VAutocomplete,{staticClass:"pr-4",attrs:{"items":_vm.hubs,"label":"Applicable user","rules":[function (v) { return !!v || 'Applicable user is required'; }],"dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"small":"","color":"accent","outlined":""}},[_vm._v(_vm._s(item.text.toUpperCase()))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"small":"","color":"accent","outlined":""}},[_vm._v(_vm._s(item.text.toUpperCase()))])]}}],null,false,4088143932),model:{value:(_vm.value.applicable_user),callback:function ($$v) {_vm.$set(_vm.value, "applicable_user", $$v)},expression:"value.applicable_user"}}):_vm._e()],1):_vm._e(),_c(VCol,{attrs:{"cols":"12","lg":"4","xl":"3"}},[_c(VSubheader,{staticClass:"pl-0",staticStyle:{"height":"0px"}},[_vm._v(" Priority ")]),_c(VRow,{staticClass:"mx-0 pt-2 pr-4",attrs:{"justify":"center"}},[_c('PriorityIcon',{attrs:{"priority":_vm.value.priority || 1,"max":9,"min":1}}),_c(VSlider,{staticClass:"pt-4",attrs:{"max":"9","min":"1","thumb-label":"","dense":"","color":"accent"},model:{value:(_vm.value.priority),callback:function ($$v) {_vm.$set(_vm.value, "priority", $$v)},expression:"value.priority"}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c(VTextField,{staticClass:"pr-6",attrs:{"label":"Applicable query","dense":""},model:{value:(_vm.value.applicable_query),callback:function ($$v) {_vm.$set(_vm.value, "applicable_query", $$v)},expression:"value.applicable_query"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }