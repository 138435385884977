<template>
    <div>
        <v-row align="start">
            <ProvidersSelectorBox v-model="selectedProviders" :items="externalProviders"/>

            <v-col class="mt-0 ml-6">
                <v-row>
                    <v-btn class="mr-2"
                        color="accent"
                        :loading="loading"
                        outlined 
                        @click="synchronize"
                    >
                        <v-icon left>mdi-sync</v-icon> Synchronize
                    </v-btn>

                <transition name="slide-x-transition">
                    <v-btn text @click="showReport = !showReport" v-if="result">
                        Report
                        <v-icon right>{{ showReport ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                </transition>
                </v-row>

                <v-row class="mt-6" v-if="result">
                    <transition name="slide-y-transition">
                        <div v-if="showReport">
                            <v-btn class="mr-2" text icon @click="showReportDialog"><v-icon>mdi-magnify</v-icon></v-btn>
                            <v-chip class="mr-2" color="success" outlined v-if="result.success">
                                Success {{ result.success.length }}
                            </v-chip>
                            <v-chip color="error" outlined v-if="result.errors">
                                Errors {{ result.errors.length }}
                            </v-chip>
                        </div>
                    </transition>
                </v-row>
            </v-col>
        </v-row>

        <JsonDialog />
    </div>
</template>

<script>
import { EventBus } from "@/event-bus"
import JsonDialog from "@/components/JsonDialog"
import ProvidersSelectorBox from "@/components/ui-components/ProvidersSelectorBox"

export default {
    components: {
        JsonDialog,
        ProvidersSelectorBox
    },
    data() {
        return {
            loading: false,
            externalProviders: [],
            selectedProviders: [],
            showReport: false,
            result: null
        }
    },
    created() {
        this.$api.get("/providers-external")
          .then(res => {
              this.externalProviders = res.data
                .filter(p => p.role.includes('CPO'))
                .map(p => { 
                    return { 
                        ...p, 
                        text: p.role ? `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username} (${p.role})` : `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username}`, 
                        value: p.id 
                    } 
                })
          })
          .catch(this.showError)
    },
    methods: {
        synchronize(){
            this.loading = true
            this.showReport = false
            this.result = null

            const data = {
                providerIds: this.selectedProviders.map(p => p.id)
            }

            this.$api.post('/tools/sync-connector-states-to-core', data)
                .then(res => {
                    this.result = res.data
                    this.showSuccess('States updated successfully')
                })
                .catch(this.showError)
                .then(() => this.loading = false)
        },
        showReportDialog(){
            EventBus.$emit(
                "dialog-json:open",
                { title: 'Connector Status Synchronizer Report', json: this.result }
            )
        }
    }
}
</script>