<template>
    <v-container fluid>

        <AppBar :breadcrumbs="[
            { text: 'Commands', to: '/commands'},
            { text: commandName, icon: 'mdi-console-line' }
        ]"/>

        <v-card :loading="loading" outlined>

            <!-- Command Stop -->
            <v-row v-if="command">
                <v-col cols="12" md="4">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>STOP_SESSION</v-list-item-title>
                            <v-list-item-subtitle>Command</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="3">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{command.created_at | adonisDateFullFormat}}</v-list-item-title>
                            <v-list-item-subtitle>Created at</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="3">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{command.updated_at | adonisDateFullFormat}}</v-list-item-title>
                            <v-list-item-subtitle>Updated at</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="2">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{command.id}}</v-list-item-title>
                            <v-list-item-subtitle>Id.</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>

                <v-col cols="12" md="2">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-btn :to="`/session/${this.command.session.id}`" text>
                                    <v-icon left>mdi-none</v-icon>
                                    {{ command.session.id }}
                                </v-btn>
                            </v-list-item-title>
                            <v-list-item-subtitle>Session Id</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{command.response_url}}</v-list-item-title>
                            <v-list-item-subtitle>Response URL</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                
            </v-row>

            <!-- Provider -->
            <v-divider class="mb-2" />
            <h3 class="ml-4">Provider</h3>
            <v-row v-if="provider">
                <v-col cols="12" md="6">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-btn :to="`/provider/${this.provider.id}`" text>
                                    <v-icon left>mdi-earth</v-icon>
                                    {{ provider.user.company.name }}
                                </v-btn>
                            </v-list-item-title>
                            <v-list-item-subtitle>Company</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                
                <v-col cols="12" md="4">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{ `${provider.role} (${provider.country_code}-${provider.party_id})` }}</v-list-item-title>
                            <v-list-item-subtitle>Roles</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="2">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{provider.id}}</v-list-item-title>
                            <v-list-item-subtitle>Id.</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>

            <!-- Location -->
            <v-divider class="mb-2" />
            <h3 class="ml-4">Location</h3>
            <v-row v-if="location">
                <v-col cols="12" md="6">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>
                                <LocationName
                                    :location="{ id: this.location.id, name: location.name }"
                                />
                            </v-list-item-title>
                            <v-list-item-subtitle>Name</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                
                <v-col cols="12" md="4">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>
                                <ProviderName
                                    :provider="{ id: location.provider.id, country_code: location.provider.country_code, party_id: location.provider.party_id }"
                                    type="title"
                                />
                            </v-list-item-title>
                            <v-list-item-subtitle>Owner</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="2">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{location.id}}</v-list-item-title>
                            <v-list-item-subtitle>Id.</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>

        </v-card>

        <h2 class="mt-4 mb-1 ml-2">Data</h2>
        <v-expansion-panels focusable multiple v-if="command">
            <!-- Request -->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <div class="title">
                        Request: {{ command.req_success ? "Success" : "Failed" }}
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-divider />
                    <p class="px-4 pt-4">JSON Received: {{ command.req_http_received }}</p>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Response -->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <div class="title">
                        Response: {{ command.resp_success ? "Success" : "Failed" }}
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-divider />
                    <p class="px-4 pt-4">Result: {{ command.resp_result }}</p>
                    <p class="px-4">JSON Received: {{ command.resp_http_received }}</p>
                    <p class="px-4">JSON Sent: {{ command.resp_message_sent }}</p>
                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Result -->
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <div class="title">
                        Result: {{ command.res_success ? "Success" : "Failed" }}
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-divider />
                    <p class="px-4 pt-4">Result: {{ command.res_result }}</p>
                    <p class="px-4">JSON Received: {{ command.res_http_received }}</p>
                    <p class="px-4">JSON Sent: {{ command.res_message_sent }}</p>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import ProviderName from "@/components/provider/ProviderName"
import LocationName from "@/components/location/LocationName"

export default {
    name: "providers",
    components: {
        AppBar,
        ProviderName,
        LocationName
    },
    data() {
        return {
            loading: false,
            command: null,
            provider: null,
            location: null
        }
    },
    computed: {
        commandName: function(){
            return this.command ? `Stop Session ${this.command.id}` : "-"
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        load(){
            
            const id = this.$router.currentRoute.params.id
            if( !id ){ this.showError('STOP_SESSION not found'); return }

            this.loading = true
            this.$api.get(`command/STOP_SESSION/${id}`)
                .then(resp => {
                    this.command = resp.data
                    this.provider = resp.data.session.provider
                    this.location = resp.data.session.location

                })
                .catch(e => { this.showError(e) })
                .then(_ => { this.loading = false })
            
        }
    }
};
</script>
