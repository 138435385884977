import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"title":"Providers","icon":"mdi-earth"}}),_c(VRow,{staticClass:"mb-2 px-2",attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VTextField,{attrs:{"clearable":"","hide-details":"","color":"accent","append-icon":"mdi-magnify","placeholder":"Search","autofocus":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onFilter.apply(null, arguments)}},model:{value:(_vm.selectedSearch),callback:function ($$v) {_vm.selectedSearch=$$v},expression:"selectedSearch"}})],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VAutocomplete,{attrs:{"items":[
                    { text: 'Provider', value: 'provider' },
                    { text: 'Hub', value: 'hub' } ],"label":"Type","clearable":"","hide-details":"","color":"accent"},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1),_c(VBtn,{staticClass:"mt-5 mr-2",attrs:{"color":"accent","loading":_vm.tableLoading},on:{"click":_vm.onFilter}},[_c(VIcon,[_vm._v("mdi-filter")])],1),_c(VSpacer),_c(VBtn,{staticClass:"mt-5 mr-2",attrs:{"to":"/provider/create","color":"accent","outlined":""}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Create ")],1),_c(VBtn,{staticClass:"mt-5 mr-2",attrs:{"outlined":"","href":"https://wenea.atlassian.net/wiki/spaces/WIKI/pages/561774605/Providers","target":"_blank"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-open-in-new")]),_vm._v(" Confluence List")],1)],1),_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"headers":[
            { value: 'linkto', sortable: false, cellClass: 'td-linkto' },
            { text: 'Id.', value: 'id' },
            { text: 'Provider', value: 'provider' },
            { text: 'Name', value: 'name' },
            { text: 'Type', value: 'user.type' },
            { text: 'OCPI Ver.', value: 'user.ocpi_version' },
            { text: 'Roles', value: 'role' },
            { text: 'Flags', value: 'flag' },
            { text: 'Updated', value: 'updated_at' } ],"items":_vm.filteredProviders,"loading":_vm.tableLoading,"search":_vm.tableSearch,"custom-filter":_vm.dataTableDeepSearch,"disable-pagination":"","hide-default-footer":"","fixed-header":true,"dense":""},scopedSlots:_vm._u([{key:"item.linkto",fn:function(ref){
        var item = ref.item;
return [_c(VBtn,{attrs:{"plain":"","small":"","to":("/provider/" + (item.id))}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1)]}},{key:"item.provider",fn:function(ref){
        var item = ref.item;
return [_c('ProviderName',{attrs:{"provider":{ id: item.id, country_code: item.country_code , party_id: item.party_id },"extra":"no-arrow"}})]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [(item.company)?_c('span',[_vm._v(" "+_vm._s(item.company.name)+" ")]):(item.user.company)?_c('span',[_vm._v(" "+_vm._s(item.user.company.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.user.username)+" ")])]}},{key:"item.user.type",fn:function(ref){
        var item = ref.item;
return [_c(VChip,{staticClass:"text-chip text-uppercase",attrs:{"small":"","label":"","outlined":""}},[_vm._v(" "+_vm._s(item.user.type === 'provider' ? 'direct' : item.user.type)+" ")])]}},{key:"item.user.ocpi_version",fn:function(ref){
        var item = ref.item;
return [(item.user.ocpi_version)?_c(VChip,{attrs:{"color":"accent","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.user.ocpi_version)+" ")]):_vm._e()]}},{key:"item.role",fn:function(ref){
        var item = ref.item;
return _vm._l((_vm.splitRoles(item.role)),function(role,index){return _c(VChip,{key:((item.id) + "_" + role),class:("" + (index > 0 ? 'ml-1' : '')),attrs:{"color":"accent","outlined":"","small":""}},[_vm._v(" "+_vm._s(role)+" ")])})}},{key:"item.flag",fn:function(ref){
        var item = ref.item;
return [(item.flag_real_time_auth)?_c(VChip,{attrs:{"color":"accent","outlined":"","small":""}},[_vm._v(" Send RTA ")]):_vm._e(),(item.flag_evse_status)?_c(VChip,{staticClass:"ml-1",attrs:{"color":"accent","outlined":"","small":""}},[_vm._v(" Send Evse Status ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }